import { 
  UPDATE_CHOSEN_STRATEGY, 
  UPDATE_CHOSEN_ROUTE_ID,
  UPDATE_CHOSEN_SEARCH_OPTION
} from '../constants/actionTypes';

const initialState = {
  results: {},
  chosenStrategyId: -1,
  chosenRouteId: -1,
  chosenSearchOption: {},
};

export default (state = initialState, action) => {
 switch (action.type) {
  case UPDATE_CHOSEN_STRATEGY:
   return {
     ...state,
     results: action.results,
     chosenStrategyId: action.strategyId
   }
  case UPDATE_CHOSEN_ROUTE_ID:
    return {
      ...state,
      chosenRouteId: action.id
    }
  case UPDATE_CHOSEN_SEARCH_OPTION:
    return {
      ...state,
      chosenSearchOption: action.options
    }
 default:
     return state
 }
}

import { config } from '../config';
import { Utils } from '../utils/utils';
import { Okta } from '../utils/okta';

import {
  REQUEST_LOGIN,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
//  UPDATE_AUTH,
  CREATE_NEW_PROJECT,
  ADD_NEW_SAVED_ROUTE,
  REMOVE_PROJECT,
  REMOVE_SAVED_ROUTE,
  UPDATE_PROFILE,
  UPDATE_SAVED_ROUTE,
  UPDATE_FLAGGED,
  UPDATE_PROJECTS,
  UPDATE_BATCH_RESULTS,
} from '../constants/actionTypes';

export const requestLogin = () => ({
  type: REQUEST_LOGIN
});

export const loginSucceeded = (user) => ({
  type: LOGIN_SUCCEEDED,
  user
});

export const logoutSucceeded = () => ({
  type: LOGOUT_SUCCEEDED
});
/*
export const updateAuthToken = (authToken) => ({
  type: UPDATE_AUTH,
  authToken
});
*/
export const updateProfile = (user) => ({
  type: UPDATE_PROFILE,
  user
});

export const addNewProject = (project) => ({
  type: CREATE_NEW_PROJECT,
  project
});

export const addNewSavedRoute = (savedRoute) => ({
  type: ADD_NEW_SAVED_ROUTE,
  savedRoute
});

export const updateCurrentSavedRoutes = (savedRoute) => ({
  type: UPDATE_SAVED_ROUTE,
  savedRoute
});

export const removeSavedRoute = (index) => ({
  type: REMOVE_SAVED_ROUTE,
  index
});

export const removeProject = (index) => ({
  type: REMOVE_PROJECT,
  index
});

export const updateCurrentFlagged = (flagged) => ({
  type: UPDATE_FLAGGED,
  flagged
});

export const updateCurrentProjects = (projects) => ({
  type: UPDATE_PROJECTS,
  projects
});

// TODO remove this and change to update
export function addToSavedRoute(savedRoute) {
  return function (dispatch) {
    dispatch(addNewSavedRoute(savedRoute));
  };
}

export function updateSavedRoutes(savedRoutes) {
  return function (dispatch) {
    dispatch(updateCurrentSavedRoutes(savedRoutes));
  };
}

export function updateFlagged(flagged) {
  return function (dispatch) {
    dispatch(updateCurrentFlagged(flagged));
  };
}

export const updateCurrentBatchResults = (batchResults) => ({
  type: UPDATE_BATCH_RESULTS,
  batchResults
});

export function updateBatchResults(tsv) {
  return function (dispatch) {
    dispatch(updateCurrentBatchResults(tsv));
  };
}

export function updateProjects(projects) {
  return function (dispatch) {
    dispatch(updateCurrentProjects(projects));
  };
}

export function createNewProject(name, desc, user) {
  return function (dispatch) {
    // NOTE below object format is not final, might be nice to have unique id
    dispatch(addNewProject(user.projects));
  };
}

export function fetchUser(tokenGW, tokenOkta) {
    return async function (dispatch) {

	try {
	    if (tokenOkta) {
		let user = await Okta.FetchUser(tokenOkta);
		console.log("user", user);

		let synfiniProjects = await Okta.FetchProjects(tokenOkta);
		console.log("synfini projects", synfiniProjects);
		
		//let timestamp = new Date().toLocaleString();
		//timestamp = timestamp.replace(',', '');

		let projects = [];
		for(let p in synfiniProjects) {
		    projects.push({
			timestamp: synfiniProjects[p].name, //timestamp,
			uuid: synfiniProjects[p].uuid,
                        name: synfiniProjects[p].name
		    });
		}

		//sort project alphabetically
		projects.sort(function (a, b) {
		    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
		});
		console.log("projects", projects);
		
		let routes = await Okta.FetchAllSavedRoutes(tokenOkta, projects);
		//sort routes by timestamp
		routes = Utils.SortByKey(routes, 'timestamp', 'down');
		console.log('routes', routes);

		let userData = {
		    tokenGW: tokenGW,
		    tokenOkta: tokenOkta,
		    username: user.firstName,
		    name: user.firstName + " " + user.lastName,
		    email: user.email,
		    phone: "",
		    photo: "",
		    jobTitle: "",
		    qualifications: "",
		    teamName: "",
		    projects: projects,
		    savedRoutes: routes,
		    flagged: []
		};
		dispatch(loginSucceeded(userData));
	    } else {
		let user = await fetchUserGW(tokenGW);
		let userData = {
		    tokenGW: tokenGW,
		    tokenOkta: tokenOkta,
		    username: user.profile.username,
		    name: user.profile.name,
		    email: user.profile.email,
		    phone: user.profile.phone,
		    photo: user.profile.photo,
		    jobTitle: user.profile.title,
		    qualifications: 'qualification here',
		    teamName: user.profile.org,
		    projects: Utils.SortByKey(user.projects, 'timestamp', 'down'),
		    savedRoutes: Utils.SortByKey(user.savedroutes, 'timestamp', 'down'),
		    flagged: Utils.SortByKey(user.flagged, 'timestamp', 'down')
		};
		dispatch(loginSucceeded(userData));
	    }
	} catch(err) {
	    alert("Profile fetch failed: " + err.message);
	}
    }
}

export function updateUserProfile(user) {
  return function (dispatch) {
    dispatch(updateProfile(user));
  };
}

export function logout() {
    
  return function (dispatch) {
    dispatch(logoutSucceeded());
  };
}

async function fetchUserGW(tokenGW)
{/*
    console.log("usergw", username, passwd, authToken);
    let tokenGW = btoa(username + ':' + passwd);

    // check if auth was received from okta or not
    if (authToken !== undefined) {
	tokenGW = authToken;
    }
*/
    let url = config.GATEWAY_SERVER + '/db.php?path=profile&op=read&key=/';
    let response = await fetch(url, {
	"headers": {
	    "authorization": 'Basic ' + tokenGW,
	    "content-type": "application/json"
	}
    });
    if (!response.ok) {
	const message = url + " " + response.status;
	throw new Error(message);
    }
    
    let reply = await response.json();
    return reply;
}

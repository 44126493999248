import { connect } from 'react-redux';
import LoginPage from './LoginPage';
//import { fetchUser, updateAuthToken, fetchUserProfile } from '../../actions';
import { fetchUser } from '../../actions';

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
    userLogin: (tokenGW, tokenOkta) =>
	dispatch(fetchUser(tokenGW, tokenOkta)),
//    fetchUserProfile: (authToken, isOkta) => dispatch(fetchUserProfile(authToken, isOkta)),
//    updateAuthToken: authToken => dispatch(updateAuthToken(authToken)),*/
});

const LoginPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

export default LoginPageContainer;

/* eslint no-multi-str: 0 */

export
var Okta = {};

function GetSynfiniHost()
{
    var host = document.referrer;
    host = host.replace('synroute', '');
    if (!host)
	host = "https://synfini-inc.cse.sri.com/";
    console.log("synfini host:", document.referrer, host);
    return host;
}

Okta.Login = function()
{
    let synfiniHost = GetSynfiniHost();
    let synrouteHost = window.location.href;
    if (!synfiniHost || !synrouteHost)
	return;

    let url = synfiniHost + "api/auth/sso/okta/login/?next_url=" + synrouteHost;
    console.log("Okta.Login", synfiniHost, synrouteHost, url);
    
    fetch(url, {
	"body": null,
	"method": "GET"
    })
	.then(response => response.json())
	.then(reply => {
	    //console.log(reply);
	    window.location.href = reply.redirect_url;
	});

    return;
}

Okta.Logout = async function(token)
{
    let host = GetSynfiniHost();
    console.log("Okta.Logout", host);
    if (!host)
	return;

    let url = host + "api/auth/logout";
    
    let response = await fetch(url, {
	"headers": {
	    "authorization": "Bearer " + token,
	    "content-type": "application/json"
	},
	"method": "POST"
    });
    console.log("logout", response);
    return;
}

Okta.FetchUser = async function(token)
{
    let host = GetSynfiniHost();
    console.log("Okta.FetchUser", host);
    if (!host)
	return;
    
    let url = host + "api/graphql/";
    let query = {
	"query":"query {\n  currentUser{\n    firstName\n    lastName\n    email\n  }\n}\n\n\n# query {\n#   allProjects{\n#     totalCount\n#     results(page: 0){\n#       uuid\n#       name\n#       key\n#       createdAt\n#     }\n#   }\n# }"};

    query = JSON.stringify(query);

    let response = await fetch(url, {
	"headers": {
	    "authorization": "Bearer " + token,
	    "content-type": "application/json"
	},
	"body": query,
	"method": "POST"
    });
    if (!response.ok) {
	const message = "fetch user failed";
	throw new Error(message);
    }
    
    let reply = await response.json();
    return reply.data.currentUser[0];
}
    
Okta.FetchProjects = async function(token)
{
    let host = GetSynfiniHost();
    console.log("Okta.FetchProjects", host);
    if (!host)
	return;

    let url = host + "api/graphql/";
    let query = {
        "query":"query {\n  allProjects{\n    totalCount\n    results(page:1, pageSize: 50){\n      uuid\n      name\n      key\n    }\n  }\n}"
    };
    query = JSON.stringify(query);

    let response = await fetch(url, {
	"headers": {
	    "authorization": "Bearer " + token,
	    "content-type": "application/json"
	},
	"body": query,
	"method": "POST"
    });
    if (!response.ok) {
	const message = "fetch projects failed";
	throw new Error(message);
    }

    let reply =  await response.json();
    return reply.data.allProjects.results;
}

Okta.FetchSavedRoutes = async function(token, uuid)
{
    let host = GetSynfiniHost();
    console.log("Okta.FetchSavedRoutes", host);
    if (!host)
	return;

    let url = host + "api/graphql/";
    uuid = JSON.stringify(uuid);
    let query = {
	"query": 
"query {\
  allProjectRoutes(project: " + uuid + "){\
    totalCount\
    results{\
        uuid\
        key\
        data\
    }\
  }\
}"};

    query = JSON.stringify(query);

    let response = await fetch(url, {
	"headers": {
	    "authorization": "Bearer " + token,
	    "content-type": "application/json"
	},
	"body": query,
	"method": "POST"
    });
    let reply = await response.json();
    let results = reply.data.allProjectRoutes.results;
    let routes = [];
    for(let r in results) {
	let route = JSON.parse(results[r].data);
	if (route && route.timestamp) {
	    routes.push(route);
	}
    }
    return routes;
}

Okta.FetchAllSavedRoutes = async function(token, projects)
{
    let host = GetSynfiniHost();
    console.log("Okta.FetchAllSavedRoutes", host);
    if (!host)
	return;

    let t0 = performance.now();
    let queries = [];
    for(let p in projects) {
	let url = host + "api/graphql/";
	let uuid = projects[p].uuid;
	uuid = JSON.stringify(uuid);
	let query = {
	    "query": 
"query {\
  allProjectRoutes(project: " + uuid + "){\
    totalCount\
    results{\
        uuid\
        key\
        data\
    }\
  }\
}"};

	query = JSON.stringify(query);

	queries.push(
	    fetch(url, {
		"headers": {
		    "authorization": "Bearer " + token,
		    "content-type": "application/json"
		},
		"body": query,
		"method": "POST"
	    })
	);
    }

    let responses = await Promise.all(queries);
    console.log("async fetched", performance.now() - t0, responses.length);

    t0 = performance.now();
    let routes = [];
    for(let p in responses) {
	let reply = await responses[p].json();
	let results = reply.data.allProjectRoutes.results;
	for(let r in results) {
	    let route = JSON.parse(results[r].data);
	    if (route && route.timestamp) {
		route.uuid = results[r].uuid;
		route.project = projects[p].name;
		routes.push(route);
	    }
	}
    }
    console.log("routes", routes.length, performance.now() - t0);    
    return routes;
}

Okta.SaveRoute = async function(token, uuid, route)
{
    let host = GetSynfiniHost();
    console.log("Okta.SaveRoute", host);
    if (!host)
	return;
    
    let url = host + "api/graphql/";

    route = JSON.stringify(route);
    uuid = JSON.stringify(uuid);
    let query = {
	"query": 
"mutation {\
  projectRouteCreate (\
    input: {\
      project: " + uuid + ",\
      data: " + route + "\
  }\
  ){\
    uuid\
    key\
    data\
    project\
    errors{ field messages } }}"
    };    

    query = JSON.stringify(query);

    let response = await fetch(url, {
	"headers": {
	    "authorization": "Bearer " + token,
	    "content-type": "application/json"
	},
	"body": query,
	"method": "POST"
    });
    if (!response.ok) {
	console.log(response);
	const message = "save route failed failed";
	throw new Error(message);
    }

    let reply =  await response.json();

    return reply;
}

Okta.Exchange = function(saml_token)
{
    let host = GetSynfiniHost();
    console.log("Okta.Exchange", host, document.referrer);
    if (!host)
	return;

    let url = host + "api/auth/token/exchange/";
    let saml = {
	"saml_token": saml_token
    };
    console.log(saml);
    saml = JSON.stringify(saml);
    console.log(saml);    

    fetch(url, {
	"headers": {
	    "content-type": "application/json"
	},
	"body": saml,
	"method": "POST"
    })
	.then(response => response.json())
	.then(okta => {
	    console.log(okta);
	    let url = "http://localhost:3000/?token=" + okta.access;
	    console.log('url', url);	
	    window.location.href = url;
	});
    return;
}

/*
function FetchJSON(url)
{
    return fetch(url)
	.then(function(response) {
	    if (!response.ok) {
		const message = url + " " + response.status;
		console.log(message);
		return null;
	    }
	    return response.json();
	})
	.catch(function(err) {
	    console.log('err', err, url);
	    return null;
	});
}

async function OnLoad()
{
    var params = new URLSearchParams(window.location.search);
    var token = params.get('token');

    console.log(window.location);
    console.log("tok", token);

    var users = await Okta.FetchUser(token);
    console.log('users', users);

    var projects = await Okta.FetchProjects(token);
    console.log('projects', projects);

    var test2 = projects.find(p => p.name == "Test2 Project");
    console.log("test2", test2);

    var routes = await Okta.FetchSavedRoutes(token, test2.uuid);
    console.log('routes', routes);

    var imat = await FetchJSON("js/imatinib.json");
    
    imat.project = "Test2 Project";
    imat.routeDescription = "test save 1";
    imat.routeName = "Jim's Route"
    
    console.log('imat', imat);

    var saved = await Okta.SaveRoute(token, test2.uuid, imat)
    console.log('saved', saved);
}

window.addEventListener('load', OnLoad);
*/

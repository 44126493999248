import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '../TextareaAutoResize';
import styled from 'styled-components';
import Button from '../Button';
import Canvas from '../Canvas';
import { Utils } from '../../utils/utils';

const StyledRecentFlag = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  background-color: white;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
  }
`;

const StyledButtonSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
`;

const StyledFlagRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const StyledFlagName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledInputLabel = styled.div`
  text-align: left;
  //align-self: start;
  font-weight: 700;
`;

const StyledInputID = styled.div`
  margin-left: 5px;
`;

const StyledTimeStamp = styled.div`
  margin-left: 5px;
`;

const StyledErrorMsg = styled.div`
  align-self: flex-end;
  color: ${props => props.color};
  min-height: 22px;
`;

const FlaggedPanel = ({
  user,
  flag,
  name,
  id,
  index,
  onRemoveToggle,
  updateFlagged
}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [isSave, setSaveMode] = useState(false);
  const [flagDescription, setFlagDescription] = useState(flag.description);

  const handleInput = e => {
    if (e.target.name === 'flagDescription') {
      setFlagDescription(e.target.value);
    }
  };

  const handleSave = () => {
    Utils.UpdateFlagged(flag, flagDescription, user, onSaveCallback);
  };

  const toggleEdit = () => {
    setSaveMode(!isSave);
  };

  const handleRemoveFlaggedClick = () => {
    onRemoveToggle(
      'flagged',
      index,
      'Are you sure you want to remove ' +
        name.toLowerCase() +
        ' <i>' +
        id +
        '</i>?'
    );
  };

  const onSaveCallback = (user, status) => {
    if (status === 200) {
      setErrorMsg('');
      updateFlagged(user.flagged);
      toggleEdit();
    } else {
      setErrorMsg('Error update flag failed');
    }
  };

  return (
    <StyledRecentFlag>
      <StyledErrorMsg color="red">{errorMsg}</StyledErrorMsg>
      <Fragment>
        <StyledFlagRow>
          <StyledFlagName>
            <StyledInputLabel>{name}</StyledInputLabel>
            <StyledInputID>{id}</StyledInputID>
            <StyledTimeStamp>{flag.timestamp}</StyledTimeStamp>
          </StyledFlagName>
          <StyledButtonSpace>
            <Button
              text={!isSave ? 'Edit' : 'Save'}
              onClick={!isSave ? toggleEdit : handleSave}
            />
            <Button
              buttonColor="flagButtonHoverColor"
              text="Remove"
              onClick={handleRemoveFlaggedClick}
            />
          </StyledButtonSpace>
        </StyledFlagRow>
        <TextareaAutosize
          type="text"
          name="flagDescription"
          placeholder=""
          value={flagDescription}
          disabled={!isSave}
          width="100%"
          height="60px"
          align="left"
          handleInput={e => handleInput(e)}
        />
      </Fragment>
      <div style={{ width: '100%' }}>
        <Canvas
          id={0}
          results={flag}
          type="flaggedCanvas"
          onCanvasClick={undefined}
        />
      </div>
    </StyledRecentFlag>
  );
};

FlaggedPanel.defaultProps = {
  subject: 'Flagged'
};

FlaggedPanel.propTypes = {
  subject: PropTypes.string
};

export default FlaggedPanel;

/**
 * Input with adjustable width, default is 50px
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: ${props => props.theme.primaryBodyFontSize};
  font-family: ${props => props.theme.primaryFont};
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  margin: 0 0 2px 0;
  padding: 10px;
  padding-left: ${props => (props.align === 'left' ? '10px' : '0px')}
  text-align: ${props => props.align};
  border:  ${props => `1px solid ${props.theme.edgeColor}`};
  color: ${props => props.theme.primaryColor};
  ::placeholder {
    font-weight: 400 !important;
    font-style: italic;
    color: ${props => props.theme.placeholderColor};
  }
  :disabled {
    background: #dddddd;
  }
`;

const InputBox = ({
  name,
  value,
  onChange,
  isCurrency,
  placeholder,
  type,
  width,
  height,
  align,
  min,
  max,
  onKeyPress,
  onClick,    
  disabled
}) => (
  <StyledInput
    align={align}
    name={name}
    defaultValue={value}
    placeholder={placeholder}
    width={width}
    height={height}
    min={min}
    max={max}
    type={type}
    onChange={onChange}
    onKeyPress={onKeyPress}
    onClick={onClick}
    disabled={disabled}
  />
);

InputBox.defaultProps = {
  width: '50px',
  height: '28px',
  align: 'center',
  disabled: false
};

InputBox.propTypes = {
  onChange: PropTypes.func,
  isCurrency: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  height: PropTypes.string,
  align: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
};

export default InputBox;

/**
 * popup with custom styling
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';

const StyledPopupBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding: 10px;
  text-align: center;
  width: 350px;
  height: 200px;
  background-color: ${props => props.theme.primaryBgColor};
  white-space: pre-wrap;
  .controls {
    margin: 25px 0 0 0;
    button {
      margin: 0 5px;
    }
  }
`;

const StyledMainTextContainer = styled.div`
  diplay: flex;
  justify-content: center;
  align-items: center;

  .main-text {
//    width: 300px;
    text-align: left;
  }
`;

const StyledErrorMsg = styled.div`
  color: ${props => props.color};
  min-height: 22px;
`;

const PopupBox = ({
  onYesClick,
  onNoClick,
  mainText,
  yesText,
  noText,
  errorMsg
}) => {
  return (
    <StyledPopupBox>
      <StyledMainTextContainer>
        <div
          className="main-text"
          dangerouslySetInnerHTML={{ __html: mainText }}
        />
      </StyledMainTextContainer>
      <StyledErrorMsg color="red">{errorMsg}</StyledErrorMsg>
      <div className="controls flex-space-between">
        <Button text={yesText} isPrimary={true} onClick={onYesClick} />
        {noText !== '' && (
          <Button text={noText} isPrimary={true} onClick={onNoClick} />
        )}
      </div>
    </StyledPopupBox>
  );
};

PopupBox.defaultProps = {
  onYesClick: undefined,
  onNoClick: undefined,
  mainText: '',
  yesText: '',
  noText: '',
  errorMsg: ''
};

PopupBox.propTypes = {
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  mainText: PropTypes.string,
  errorMsg: PropTypes.string
};

export default PopupBox;

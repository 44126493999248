/**
 * popup with custom styling
 *
 */

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import InputBox from '../InputBox';
import Textarea from '../Textarea';
import Canvas from '../Canvas';
import PopupBox from '../PopupBox';
import { Utils } from '../../utils/utils';

//allen fix this!
let state = {inConfirmation: false};

//jah -- duplicated from SavedRoutePanel.js
const StyledPopup = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const StyledEmailPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 15px;
  width: 500px;
  min-height: 200px;
  background-color: ${props => props.theme.primaryBgColor};
`;

const StyledEmailRow = styled.div`
  display: flex;
  align-items: center;

  .email-label {
    min-width: 50px;
  }
`;

const StyledErrorMsg = styled.div`
  color: red;
  min-height: 22px;
`;

const StyledCanvasContainer = styled.div`
  width: 100%;
  max-height: 400px;
  border: 1px solid grey;
  overflow-y: auto;
`;

const FlagEmailPopup = ({
  type,
  label,
  sendTo,
  placeholder,
  details,
  subject,
  desc,
  user,
  cancelClick,
  updateFlagged
}) => {
  const [description, setDescription] = useState('');
  const [emailTo, setEmailTo] = useState(sendTo);
  const [emailFrom, setEmailFrom] = useState(user.email);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const beforeSend = () => {
      // check if desc and emailTo is set
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let tolist = emailTo ? emailTo.split(',') : '';
      if (!tolist ||
	  !tolist.every(function(email) {
	      return re.test(email)
	  })) {
            setShowError(true);
            setErrorMsg('Error, invalid email recipient address.');
	} else if (!emailFrom || !re.test(emailFrom)) {
            setShowError(true);
            setErrorMsg('Error, invalid email sender address.');
	} else if (!description) {
            setShowError(true);
            setErrorMsg('Error, description field is empty.');
	} else {
	    if (type === 'Shared') {
		onSend(user, details);
	    } else {
		Utils.SaveFlagged(details, description, user, flagCallback);
	    }
	}
  };

    const onCloseClick = () => {
        state.inConfirmation = false;
	cancelClick();
	return;
    };
    
    const flagCallback = (user, status) => {
    if (status === 200) {
      // update reducer
      if (user) updateFlagged(user.flagged);
      // send email
	onSend(user, details);
    } else {
      setShowError(true);
      setErrorMsg('Error saving flag ' + subject.toLowerCase());
    }
  };

    const onSend = (user, details) => {
	
	let _subject = 'Synroute ' + type + ' ' + subject;
	
	let body = description + '<br><br>' + desc

	body = body.replace(/\r\n|\r|\n/gi, '<br>');
	body = body.replace(/\t/gi, '&emsp;');

	Utils.SendMail(emailTo, emailFrom, _subject, body, details.canvas, user, function(status) {
	    if (status !== 200) {
		setShowError(true);
		setErrorMsg('Send mail error.');
	    } else {
		//allen fix this!
		state.inConfirmation = true;
		setShowError(true);
		setShowError(false);
	    }
	});
	return;
/*	
    // replace \n to %0D%0A
    let body = desc.replace(/\r\n|\r|\n/gi, ' %0D%0A ');
    body = body.replace(/\r\t|\r|\t/gi, '%20%20%20%20');
    //body += '%0D%0A' + link;

    let formattedDescription = description.replace(/\r\n|\r|\n/gi, ' %0D%0A ');
    formattedDescription = formattedDescription.replace(
      /\r\t|\r|\t/gi,
      '%20%20%20%20'
    );

    // close the popup
    cancelClick();

    // open email client
    window.open(
      'mailto:' +
        emailTo +
        '?subject=' +
        subject +
        ' ' +
        type +
        '&body=' +
        formattedDescription +
        '%0D%0A %0D%0A' +
        body
    );
*/
  };

  const renderCanvas = type => {

    switch (type) {
      case 'reactionDetails':
      case 'compoundDetails':
        return (
          <Canvas id={0} results={{ details: details }} type="flaggedCanvas" />
        );
      case 'routeDetails':
        return (
          <Canvas
            id={details.strategyId}
            routeId={details.routeId}
            results={details.results}
            type="routeCanvas"
          />
        );
      case 'strategyDetails':
        return (
          <Canvas
            id={details.strategyId}
            results={details.results}
            type="strategyCanvas"
          />
        );
      case 'strategyRouteDetails':
        return (
          <Canvas
            id={details.strategyId}
            routeId={details.routeId}
            results={details.results}
            type="routeCanvas"
          />
        );
      default:
        return <div></div>;
    }
  };
  return (
    <StyledEmailPopup>
          {state.inConfirmation && (
            <StyledPopup>
              <PopupBox
                onYesClick={onCloseClick}
                mainText="email successfully sent"
                yesText="Close"
      	      />
            </StyledPopup>
          )}
	  
      <Fragment>
        <StyledEmailRow>
          <div className="email-label">To:</div>
          <InputBox
            type="email"
            name="emailTo"
            placeholder={
              type === 'Shared' ? 'Email address to send to' : sendTo
            }
            width="100%"
            height="34px"
            align="left"
            disabled={type === 'Shared' ? false : true}
            onChange={
              type === 'Shared'
                ? event => setEmailTo(event.target.value)
                : undefined
            }
          />
        </StyledEmailRow>
        <StyledEmailRow>
          <div className="email-label">From:</div>
          <InputBox
            type="email"
            name="emailFrom"
            placeholder="Your email address"
            value={emailFrom}
            width="100%"
            height="34px"
            align="left"
            onChange={event => setEmailFrom(event.target.value)}
          />
        </StyledEmailRow>
      </Fragment>
      <Textarea
        type="text"
        name="description"
        placeholder={placeholder}
        width="100%"
        height="80px"
        align="left"
        onChange={event => setDescription(event.target.value)}
      />
      <StyledCanvasContainer>
        {renderCanvas(details.type)}
      </StyledCanvasContainer>
      {showError && <StyledErrorMsg>{errorMsg}</StyledErrorMsg>}
      <div className="modal-controls">
        <Button text="Cancel" className="left" onClick={cancelClick} />
        <Button text="Send" isPrimary={true} onClick={beforeSend} />
      </div>
    </StyledEmailPopup>
  );
};

FlagEmailPopup.defaultProps = {
  subject: 'Flagged',
  type: 'Flagged'
};

FlagEmailPopup.propTypes = {
  subject: PropTypes.string,
  beforeSend: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
  sendTo: PropTypes.string,
  placeholder: PropTypes.string,
  details: PropTypes.object,
  desc: PropTypes.string,
  user: PropTypes.object,
  cancelClick: PropTypes.func
};

export default FlagEmailPopup;

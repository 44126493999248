/**
 * Footer which is shown on all pages
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import SRILogo from '../../assets/images/footer-logo.png';
import { STRINGS } from '../../constants/strings';

const StyledFooter = styled.footer`
  position: relative;
  height: ${props => props.theme.footerSize};
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0;
  padding: 0;
  background-color: ${props => props.theme.tertiaryColor};
  max-width: auto;

  .content {
    margin: 0 auto;
    width: ${props => props.theme.contentSize};
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 25px 0 0 0;
    h1 {
      position: relative;
      width: 200px;
      height: 17px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      a {
        display: block;
        width: 200px;
        height: 17px;
        background-image: url(${props => props.img});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-indent: -9000px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      right: 0;
      position: relative;
      li {
        text-transform: uppercase;
        font-size: ${props => props.theme.smallBodyFontSize};
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0 25px 0 0;
        color: ${props => props.theme.primaryBgColor};
        a {
          color: ${props => props.theme.primaryBgColor};
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media print {
    display: none;
  }
`;

class Footer extends Component {
  render() {
    return (
      <StyledFooter
        img={SRILogo}
        currentLocation={this.props.location.pathname}
      >
        <div className="content">
          <h1>
            <a
              href={STRINGS.companyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {STRINGS.companyName}
            </a>
          </h1>
          <ul>
            <li>
              <a
                href={STRINGS.privacyPolicyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {STRINGS.privacyPolicy}
              </a>
            </li>
            <li>
              <a
                href={STRINGS.cookiesUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {STRINGS.cookies}
              </a>
            </li>
            <li className="copyright">{STRINGS.copyright}</li>
          </ul>
        </div>
      </StyledFooter>
    );
  }
}

export default withRouter(Footer);

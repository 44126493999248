/**
 * Styled search bar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SearchImg from '../../assets/images/search-default.png';
import UploadImg from '../../assets/images/search-upload@3x.png';
import DrawImg from '../../assets/images/search-draw@3x.png';
import { STRINGS } from '../../constants/strings';

const StyledSearch = styled.input`
  width: calc(100% - 56px);
  height: 50px;
  font-size: 24px;
  font-family: ${props => props.theme.primaryFont};
  border: none;
  color: ${props => props.theme.primaryColor};
  background-color: ${props =>
    props.disabled ? 'rgb(235, 235, 228)' : 'white'};
  ::placeholder {
    font-weight: 400;
    font-style: italic;
    color: ${props => props.theme.placeholderColor};
  }
`;

const StyledSearchButton = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.spotColor};
  img {
    width: 30px;
    height: 30px;
  }
`;

const StyledSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  border: ${props => `1px ${props.theme.edgeColor} solid`};
  background-color: ${props =>
    props.disabled ? 'rgb(235, 235, 228)' : 'white'};

  img {
    cursor: pointer;
  }

  img:nth-child(n + 2) {
    margin-right: 15px;
    width: 30px;
    height: 30px;
    background-color: ${props =>
      props.disabled ? 'rgb(235, 235, 228)' : 'white'};
  }
`;

const StyledSearchSuggestions = styled.div`
  position: absolute;
  top: 52px;
  left: -2px;
  z-index: 5;
  border: ${props => `2px ${props.theme.tertiaryColor} solid`};
  width: 100%;
  background-color: white;
  div {
    padding-left: 10px;
    cursor: pointer;
   }
`;

const Search = ({
  name,
  value,
  disabled,
  placeHolder,
  showSuggestion,
  onChange,
  suggestionList,
  onSearchClick,
  onKeyPress,
  onUploadClick,
  onSuggestClick,
  onDrawIconClick,
  onAutoCompleteHover
}) => (
  <div>
    <StyledSearchContainer disabled={disabled}>
      <StyledSearch
        type="input"
        name={name}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      {showSuggestion && (
        <StyledSearchSuggestions
          onMouseOut={(e) => onAutoCompleteHover(e.nativeEvent)}
	  >
          {suggestionList.map((suggestString, i) => (
  	    <div
              key={'suggest-' + i}
              onClick={(e) => {
		  onSuggestClick(suggestString);
		  onAutoCompleteHover(e.nativeEvent);
	      }}
       	      onMouseMove={(e) => onAutoCompleteHover(e.nativeEvent, suggestString, i)}
		  >
	      <a>
		  {suggestString}
	      </a>
            </div>

          ))}
        </StyledSearchSuggestions>
      )}
      <img src={UploadImg} onClick={onUploadClick} alt="Upload" />
      <img src={DrawImg} onClick={onDrawIconClick} alt="Draw" />
      <StyledSearchButton onClick={onSearchClick}>
        <img src={SearchImg} alt="Search" />
      </StyledSearchButton>
    </StyledSearchContainer>
    <p className="hint">{STRINGS.searchHints}</p>
  </div>
);

Search.propTypes = {
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onSuggestClick: PropTypes.func,
  onUploadClick: PropTypes.func,
  showSuggestion: PropTypes.bool,
  suggestionList: PropTypes.array,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default Search;

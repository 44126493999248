/**
 * Search options component
 * Used mainly for /strategies and /strategy page
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import SearchOption from '../../components/SearchOption';
import Button from '../../components/Button';
import {
  MAX_ROUTE_OPTIONS,
  MAX_REAGENT_OPTIONS,
  MAX_REACTION_OPTIONS
} from '../../constants/constants';
import { STRINGS } from '../../constants/strings';

const StyledSearchOption = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const StyledSearchOptionButton = styled.div`
  flex-grow: 0;
  margin-left: auto;
  padding-top: 10px;
`;

const StyledSearchErrorMsg = styled.div`
  color: ${props => props.theme.flagColor};
`;

const SearchOptionStrategy = ({
  onChange,
  onSearch,
  errorMsg,
  hideSearch,
  maxRouteValue,
  maxReactionStepsValue,
  maxReagentCostValue,
  autoSYNCompatibleValue,
  literatureDerivedReactionsValue,
  computerGeneratedReactionsValue
}) => (
  <StyledSearchOption>
    <h3>{STRINGS.searchOptionsHeader}</h3>
    <SearchOption label={STRINGS.maxRoutes}>
      <Select
        name="maxRoute"
        onChange={onChange}
        options={MAX_ROUTE_OPTIONS}
        value={maxRouteValue}
      />
    </SearchOption>
    <SearchOption label={STRINGS.maxReagantCost}>
      <Select
        name="maxReagentCost"
        onChange={onChange}
        value={maxReagentCostValue}
        options={MAX_REAGENT_OPTIONS}
      />
    </SearchOption>
    <SearchOption label={STRINGS.maxReactionSteps}>
      <Select
        name="maxReactionSteps"
        onChange={onChange}
        value={maxReactionStepsValue}
        options={MAX_REACTION_OPTIONS}
      />
    </SearchOption>
    <SearchOption label={STRINGS.autoSynCompatible}>
      <Checkbox
        name="autoSYNCompatible"
        value={autoSYNCompatibleValue}
        onChange={onChange}
      />
    </SearchOption>
    <SearchOption label={STRINGS.literatureDerivedReactions}>
      <Checkbox
        name="literatureDerivedReactions"
        value={literatureDerivedReactionsValue}
        onChange={onChange}
      />
    </SearchOption>
    <SearchOption label={STRINGS.computerGeneratedReactions}>
      <Checkbox
        name="computerGeneratedReactions"
        value={computerGeneratedReactionsValue}
        onChange={onChange}
      />
    </SearchOption>
    {errorMsg !== '' && <StyledSearchErrorMsg>{errorMsg}</StyledSearchErrorMsg>}
    {!hideSearch && (
      <StyledSearchOptionButton>
        <Button text="Search again" isPrimary={true} onClick={onSearch} />
      </StyledSearchOptionButton>
    )}
  </StyledSearchOption>
);

SearchOptionStrategy.defaultProps = {
  hideSearch: false,
  errorMsg: ''
};

SearchOptionStrategy.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  hideSearch: PropTypes.bool,
  maxRouteOptions: PropTypes.array,
  autoSYNCompatibleValue: PropTypes.bool,
  literatureDerivedReactionsValue: PropTypes.bool,
  computerGeneratedReactionsValue: PropTypes.bool
};

export default SearchOptionStrategy;

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

// Firebase
var FB = {

    version: 1,

    config: {
	apiKey: "AIzaSyBvtGNsZ5pVVhF-DYP2teFOnxRgHYYrIuw",
	authDomain: "synroute.firebaseapp.com",
	databaseURL: "https://synroute-default-rtdb.firebaseio.com",
	projectId: "synroute"
    },

    Init(callback)
    {
	if (this.init)
	    return;

	console.log("FB Init", this.version);

	this.app = initializeApp(this.config);

	this.init = 1;

	let auth = getAuth(this.app);
	auth.onAuthStateChanged(function(user) {
	    if (callback)
		callback(user);
	    else
		console.log("FB ", user ? "login" : "logout", user ? user.email :"");
	});

    },

    async Login(email, password, callback)
    {
	console.log("signing in...");
	let auth = getAuth(this.app);
	try {
	    let user = await signInWithEmailAndPassword(auth, email, password);
	    console.log("logged in", user);
	    if (callback)
		callback(user ? user.user : null);
	} catch (err) {
	    var msg = err.message;
	    if (msg) {
		msg = msg.replace("Firebase:", "");
		alert(msg);
	    }
	}
    },

    async Logout()
    {
	let auth = getAuth(this.app);
	try {
	    await auth.signOut(auth);
	    console.log("logged out");
	    return 0;
	} catch (err) {
	    var msg = err.message;
	    if (msg) {
		msg = msg.replace("Firebase:", "");
		alert(msg);
	    }
	}
    },

    async ResetPassword(email, callback)
    {
	let auth = getAuth(this.app);
	let hostname = window.location.hostname.replace(/\./g, '-');
	console.log(email, hostname);

	try {
	    var redirectURL = {
		url: "https://synroute.web.app/login-" + hostname + ".html",
		handleCodeInApp: false
	    };
	    if (hostname != 'synroute-demo-sri-com')
		redirectURL = null;
	    await sendPasswordResetEmail(auth, email, redirectURL).then(function() {
		if (callback)
		    callback();
		else
		    console.log("email sent", email);
	    });
	} catch (err) {
	    var msg = err.message;
	    if (msg) {
		msg = msg.replace("Firebase:", "");
		alert(msg);
	    }
	}
    },
};

export {FB};

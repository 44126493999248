import React, { Component, Fragment } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProjectPanel from '../../components/ProjectPanel';
import { isEmpty } from 'lodash';
import Button from '../../components/Button';
import UserEdit from './UserEdit';
import PopupBox from '../../components/PopupBox';
import { Utils } from '../../utils/utils';
import styled from 'styled-components';
import { STRINGS } from '../../constants/strings';
import DefaultAvatar from '../../assets/images/profile.jpg';
import FlaggedPanel from '../../components/FlaggedPanel';

const StyledUserPhoto = styled.div`
  display: block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 50%;
  background-image: url(${(props) => (props.img ? props.img : DefaultAvatar)});
  border-radius: 150px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
`;

export default class UserPage extends Component {
  constructor(props) {
    super(props);

    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.onSavedRouteClick = this.onSavedRouteClick.bind(this);
    this.onRemoveProjectClick = this.onRemoveProjectClick.bind(this);
    this.onRemoveSaveRouteClick = this.onRemoveSaveRouteClick.bind(this);
    this.onRemoveFlaggedClick = this.onRemoveFlaggedClick.bind(this);
    this.onRemoveToggle = this.onRemoveToggle.bind(this);

    this.state = {
      tabIndex: 0,
      userEdit: false,
      showRemovePopup: false,
      removeType: '',
      removeIndex: -1,
      removeText: '',
      uniqueNum: 0,
      uniqueProjectNum: 0,
      showError: false,
      errorMsg: ''
    };
  }

  componentDidMount() {
    console.log('component did mount user page');
  }

  toggleEditMode() {
    let userEdit = !this.state.userEdit;
    this.setState({
      userEdit: userEdit
    });
  }

  onRemoveToggle(type, index, text) {
    let showRemovePopup = !this.state.showRemovePopup;
    this.setState({
      showRemovePopup: showRemovePopup,
      removeType: showRemovePopup ? type : '',
      removeIndex: showRemovePopup ? index : -1,
      removeText: text,
      showError: false,
      errorMsg: ''
    });
  }

  onRemoveProjectClick() {
    let obj = this.props.user.projects[this.state.removeIndex];
    Utils.DeleteProject(obj, this.props.user, (user, status) => {
      if (status === 200) {
        this.props.updateProjects(user.projects);
        this.props.updateSavedRoutes(user.savedRoutes);
        this.setState({
          uniqueProjectNum: this.state.uniqueProjectNum + 1,
          showRemovePopup: false,
          removeType: '',
          removeIndex: -1,
          removeText: '',
          showError: false,
          errorMsg: ''
        });
      } else {
        this.setState({
          showError: true,
          errorMsg: 'Error, project remove failed'
        });
      }
    });
  }

  onRemoveSaveRouteClick() {
    let obj = this.props.user.savedRoutes[this.state.removeIndex];
    if (obj !== undefined) {
      Utils.DeleteSavedRoute(obj, this.props.user, (user, status) => {
        if (status !== 200) {
          this.setState({
            showError: true,
            errorMsg: 'Error, save route remove failed'
          });
        } else {
          console.log('updated saved route', user.savedRoutes);
          this.props.updateSavedRoutes(user.savedRoutes);
          this.setState({
            uniqueNum: this.state.uniqueNum + 1,
            showRemovePopup: false,
            removeType: '',
            removeIndex: -1,
            removeText: '',
            showError: false,
            errorMsg: ''
          });
        }
      });
    }
  }

  onRemoveFlaggedClick() {
    let obj = this.props.user.flagged[this.state.removeIndex];

    if (obj !== undefined) {
      Utils.DeleteFlagged(obj, this.props.user, (user, status) => {
        if (status !== 200) {
          this.setState({
            showError: true,
            errorMsg: 'Error, flag remove failed'
          });
        } else {
          this.props.updateFlagged(user.flagged);
          this.setState({
            uniqueNum: this.state.uniqueNum + 1,
            showRemovePopup: false,
            removeType: '',
            removeIndex: -1,
            removeText: '',
            showError: false,
            errorMsg: ''
          });
        }
      });
    }
  }

  onSavedRouteClick(results) {
    console.log('saved route clicked.....');

    // update results, strategyId, routeId
    this.props.savedRouteClicked(results, 0, 0);
    //update search options
    this.props.updateSearchOption(results.searchOptions);
    this.props.history.push('/route');
  }

  render() {
    const { user, updateFlagged, updateUserProfile } = this.props;
    if (isEmpty(user)) {
      return null;
    }
      
    let savedRoutes = user.savedRoutes;
    let savedRouteCanvasObj = {};

    savedRoutes.forEach((routes, i) => {
      if (savedRouteCanvasObj[routes.project] === undefined) {
        savedRouteCanvasObj[routes.project] = [{ ...routes, id: i }];
      } else {
        savedRouteCanvasObj[routes.project] = [
          ...savedRouteCanvasObj[routes.project],
          { ...routes, id: i }
        ];
      }
    });

    let removeFunc;

    switch (this.state.removeType) {
      case 'project':
        removeFunc = this.onRemoveProjectClick;
        break;
      case 'savedRoute':
        removeFunc = this.onRemoveSaveRouteClick;
        break;
      case 'flagged':
        removeFunc = this.onRemoveFlaggedClick;
        break;
      default:
        removeFunc = undefined;
    }

      return (
      <div className="user-page">
        {this.state.showRemovePopup && (
          <div className="user-popup">
            <PopupBox
              onYesClick={removeFunc}
              onNoClick={() => this.onRemoveToggle('', -1)}
              mainText={
                this.state.removeText === ''
                  ? STRINGS.removeHeader
                  : this.state.removeText
              }
              yesText="Yes"
              noText="No"
              showError={this.state.showError}
              errorMsg={this.state.errorMsg}
            />
          </div>
        )}
        <div className="user-contents">
          <div className="user-information">
            <StyledUserPhoto img={user.photo} alt={user.username} />
            {user.tokenOkta && (
              <Fragment>
                <h2>
                  {user.name === '' ? STRINGS.userDataNameError : user.name}
                </h2>
                <div className="nextSection">
                  <p>
                    {user.email === ''
                      ? STRINGS.userDataEmailError
                      : user.email}
                  </p>
                </div>
              </Fragment>
            )}
            {!user.tokenOkta && !this.state.userEdit && (
              <Fragment>
                <h2>
                  {user.name === '' ? STRINGS.userDataNameError : user.name}
                </h2>
                <h3>
                  {user.jobTitle === ''
                    ? STRINGS.userDataTitleError
                    : user.jobTitle}
                </h3>
                <h3>
                  {user.teamName === ''
                    ? STRINGS.userDataTeamError
                    : user.teamName}
                </h3>
                <div className="nextSection">
                  <p>
                    {user.email === ''
                      ? STRINGS.userDataEmailError
                      : user.email}
                  </p>
                  <p>
                    {user.phone === ''
                      ? STRINGS.userDataTelephoneError
                      : user.phone}
                  </p>
                    </div>
                  <div className="controls center">
                  <Button
                    isPrimary={true}
                    text={this.state.userEdit ? 'Submit' : 'Edit'}
                    onClick={this.toggleEditMode}
                  />
                </div>
              </Fragment>
            )}
            {this.state.userEdit && user !== undefined && (
              <UserEdit
                user={user}
                toggleEditMode={this.toggleEditMode}
                onEditCallBack={this.onEditCallBack}
                updateUserProfile={updateUserProfile}
              />
            )}
          </div>
          <div className="user-right">
              <Tabs>
	      {!user.tokenOkta && (
               <TabList>
                <Tab>{STRINGS.savedRoutes}</Tab>
                <Tab>{STRINGS.flaggedCompounds}</Tab>
                <Tab>{STRINGS.flaggedReactions}</Tab>
              </TabList>
	      )}

	      {user.tokenOkta && (
               <TabList>
                <Tab>{STRINGS.savedRoutes}</Tab>
              </TabList>
	      )}
	  
              <TabPanel>
                <div>
                  {user.projects.map((data, index) => {
                    return (
                      <ProjectPanel
                        key={
                          index +
                          '-project-uni-num-' +
                          this.state.uniqueProjectNum
                        }
                        user={user}
                        data={data}
                        savedRouteCanvasArr={
                          savedRouteCanvasObj[data.timestamp]
                        }
                        projectId={data.projectId}
                        updateProjects={this.props.updateProjects}
                        updateSavedRoutes={this.props.updateSavedRoutes}
                        onSavedRouteClick={this.onSavedRouteClick}
                        onRemoveToggle={this.onRemoveToggle}
                        uniqueNum={this.state.uniqueProjectNum}
                        onRemoveSaveRouteClick={this.onRemoveSaveRouteClick}
                        onRemoveProjectClick={(text) =>
                          this.onRemoveToggle('project', index, text)
                        }
                      />
                    );
                  })}
                </div>
              </TabPanel>
	      {!user.tokenOkta && (
		<TabPanel>
                {user.flagged.map((data, index) => {
                  if (data.details.type === 'compoundDetails') {
                    return (
                      <FlaggedPanel
                        user={user}
                        name="Flagged Compound"
                        key={index + '-flagged-uni-num-' + data.details.id}
                        index={index}
                        id={data.details.name}
                        flag={data}
                        onRemoveToggle={this.onRemoveToggle}
                        updateFlagged={updateFlagged}
                      />
                    );
                  } else {
                    return (
                      <span
                        key={index + '-flagged-uni-num-' + this.state.uniqueNum}
                      ></span>
                    );
                  }
                })}
		</TabPanel>
	      )}
   	      {!user.tokenOkta && (
		<TabPanel>
                {user.flagged.map((data, index) => {
                  if (data.details.type === 'reactionDetails') {
                    return (
                      <FlaggedPanel
                        user={user}
                        name="Flagged Reaction"
                        key={index + '-flagged-uni-num-' + data.details.id}
                        index={index}
                        id={data.details.id}
                        flag={data}
                        onRemoveToggle={this.onRemoveToggle}
                        updateFlagged={updateFlagged}
                      />
                    );
                  } else {
                    return (
                      <span
                        key={index + '-flagged-uni-num-' + this.state.uniqueNum}
                      ></span>
                    );
                  }
                })}
		</TabPanel>
	      )}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

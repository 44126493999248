  // meta is for react-select
  export function handleOptionChange(self, event, meta) {
    const target = event.target;
    
    // Currently react-select doesn't seem to return full event so check it here.
    if(target === undefined && event.label !== undefined) {
      let metaName = meta.name;
      self.setState({
        [metaName]: event
      });
    } else {
      let value = target.type === 'checkbox' ? target.checked : target.value;
      let computerLiteratureCheck = (!self.state.computerGeneratedReactions || !self.state.literatureDerivedReactions) 
      const name = target.name;

      // Check if both computerGeneratedReactions and literatureDerivedReactions are going to be unchecked
      if( computerLiteratureCheck && target.type === 'checkbox' && !target.checked && name !== 'autoSYNCompatible') {
        self.setState({
          showOptionsError: true,
          optionsErrorMsg: 'ERROR: Either "Computer Generated Reactions" or "Literature Derived Reactions" has to be checked',
        });
      } else {
        self.setState({
          [name]: value,
          showOptionsError: false,
          optionsErrorMsg: '',
        });
      }
    }    
  };
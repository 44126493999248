/**
 * Default login
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import InputBox from '../../../components/InputBox';

const LoginInput = ({
  submitText,
  submitColor,
  onSubmit,
  secondSubmitText,
  secondSubmitColor,
  onSecondSubmit,
  onKeyPress,
  handleChange,
  textColor,
  text,
  type,
  oktaCheck
}) => {
  return (
    <div className="login-input-container">
      <div className="login-input-text" style={{ color: textColor }}>
       Email Address:
      </div>
      <InputBox
        type="text"
        name="username"
        width="300px"
        height="48px"
        align="left"
        onChange={handleChange}
      />
      <div className="login-input-text" style={{ color: textColor }}>
       Password:
      </div>
      <InputBox
        type="password"
        name="password"
        width="300px"
        height="48px"
        align="left"
        autocomplete="password"
        onChange={handleChange}
        onKeyPress={onKeyPress}
      />
      <a className="resetPassword" href="#"> Reset password</a>
      {type === 'createOrReset' && (
        <InputBox
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          width="300px"
          height="48px"
          align="left"
          onChange={handleChange}
        />
      )}
      <div className="login-button-container">
        <Button
          text={submitText}
          onClick={onSubmit}
          disabled={oktaCheck}
          isPrimary={true}
        />
        {/* temp remove second button
        <Button text={secondSubmitText} color={secondSubmitColor} onClick={onSecondSubmit} />
      */}
      </div>
    </div>
  );
};

LoginInput.propTypes = {
  onSubmit: PropTypes.func,
  submitColor: PropTypes.string,
  submitText: PropTypes.string,
  secondSubmitText: PropTypes.string,
  secondSubmitColor: PropTypes.string,
  onSecondSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  text: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.string,
  oktaCheck: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default LoginInput;

/**
 * Route detail page, URL: /route
 *
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { config } from '../../config';
import Button from '../../components/Button';
import Canvas from '../../components/Canvas';
import SearchOptionStrategy from '../../components/SearchOptionStrategy';
import { MAX_ROUTE_OPTIONS } from '../../constants/constants';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import { Utils } from '../../utils/utils.js';
import { SRV } from '../../utils/RouteVisualization';
import { isEmpty, isEqual } from 'lodash';
import SaveRoutePanel from '../../components/SaveRoutePanel';
import Loading from '../../assets/images/loading.gif';
import {DetailsPanel} from '../../components/DetailsPanel';
import ReactionDetails from './ReactionDetails';
import CompoundDetails from './CompoundDetails';
import FlagEmailPopup from '../../components/FlagEmailPopup';
import SidebarClose from '../../assets/images/sidebar-close@3x.png';
import SidebarCloseHover from '../../assets/images/sidebar-close-hover@3x.png';
import SidebarOpen from '../../assets/images/sidebar-open@3x.png';
import SidebarOpenHover from '../../assets/images/sidebar-open-hover@3x.png';
import { STRINGS } from '../../constants/strings';

const StyledSidebarToggle = styled.div`
  background-image: url(${props => props.img});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 25px;
  height: 63px;
  position: absolute;
  z-index: 2;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: ${props => props.left};
  &:hover {
    background-image: url(${props => props.imgHover});
  }
  @media print {
    display: none;
  }
`;

const RouteDetails = ({ details, maxCount }) => (
  <div>
    {details !== undefined && (
      <>
        <h3>{STRINGS.routeDetails}</h3>
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="Total Steps"
          desc={details.steps}
        />
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="Longest Segment"
          desc={details.maxsegment}
        />
	{/*    
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="Solvent Changes"
          desc={details.solventChanges}
        />
         */}
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="Solvent Cost"
          desc={details.solventCost}
        />
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="Route Cost"
          desc={details.routeCost}
        />
        <DetailsPanel
          maxCount={maxCount}
          width="170px"
          label="AutoSyn Compatible"
          desc={details.autosync}
        />
      </>
    )}
  </div>
);

const Refined = ({ options, state, handleChange }) => (
  <SearchOptionStrategy
    onChange={handleChange}
    hideSearch={true}
    maxRouteValue={state.maxRoute ? state.maxRoute : options.maxRoute}
    maxRouteOptions={MAX_ROUTE_OPTIONS}
    maxReactionStepsValue={
      state.maxReactionSteps ? state.maxReactionSteps : options.maxReactionSteps
    }
    maxReagentCostValue={
      state.maxReagentCost ? state.maxReagentCost : options.maxReagentCost
    }
    autoSYNCompatibleValue={
      state.autoSYNCompatible !== undefined
        ? state.autoSYNCompatible
        : options.autoSYNCompatible
    }
    literatureDerivedReactionsValue={
      state.literatureDerivedReactions !== undefined
        ? state.literatureDerivedReactions
        : options.literatureDerivedReactions
    }
    computerGeneratedReactionsValue={
      state.computerGeneratedReactions !== undefined
        ? state.computerGeneratedReactions
        : options.computerGeneratedReactions
    }
  />
);

export default class RoutePage extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onCanvasClick = this.onCanvasClick.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.collapseClicked = this.collapseClicked.bind(this);
    this.updateMenuType = this.updateMenuType.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.toggleSavePanel = this.toggleSavePanel.bind(this);
    this.onOrderBOM = this.onOrderBOM.bind(this);
    this.togglePrintMode = this.togglePrintMode.bind(this);
    this.testPrintFalse = this.testPrintFalse.bind(this);
    this.onClosePanel = this.onClosePanel.bind(this);
    this.formatSubjectBody = this.formatSubjectBody.bind(this);
    this.onFlagClick = this.onFlagClick.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.findButton = this.findButton.bind(this);
      
    this.state = {
      menuType: 'routeDetails',
      isCollapsed: false,
      canvasType: 'routeCanvas',
      details: {},
      routeDetails: {},
      isSavePanelOpen: false,
      isSearching: false,
      inPrintMode: false,
      authToken: '',
      flagEmailShow: false,
      flagEmailSubject: '',
	isSearchAble: true,   //disable toggling search again button
      shareEmailShow: false
    };
  }

  componentDidMount() {
    const { options } = this.props;
    // add event listener to handle after print
    window.addEventListener('afterprint', this.testPrintFalse);

    this.setState({
      literatureDerivedReactions: options.literatureDerivedReactions,
      computerGeneratedReactions: options.computerGeneratedReactions,
      maxRoute: options.maxRoute,
      maxReactionSteps: options.maxReactionSteps,
      maxReagentCost: options.maxReagentCost,
      autoSYNCompatible: options.autoSYNCompatible,
      authToken: this.props.user.authToken
    });

     this.props.user.breadcrumb.Update(this.props.results, this.props.strategyId, this.props.routeId);
  }

  // Check if prop updated, mainly strategies
  componentDidUpdate(prevProps, prevState) {
    const { options, strategies } = this.props;

    if (!isEqual(prevProps.strategies, this.props.strategies)) {
      this.setState({
        data: strategies.data
      });
    } else if (strategies.errorMsg !== '' && this.state.isSearching) {
      this.setState({
        isSearching: false,
        errorMsg: strategies.errorMsg
      });
    } else if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({
        literatureDerivedReactions: options.literatureDerivedReactions,
        computerGeneratedReactions: options.computerGeneratedReactions,
        maxRoute: options.maxRoute,
        maxReactionSteps: options.maxReactionSteps,
        maxReagentCost: options.maxReagentCost,
        autoSYNCompatible: options.autoSYNCompatible
      });
    }

    let inPrintMode = this.state.inPrintMode;
    if (inPrintMode !== prevState.inPrintMode) {
      let event = new CustomEvent('printredraw');
      // Dispatch custom event for forcing redraw of canvas
      window.dispatchEvent(event);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('afterprint', this.testPrintFalse);
  }

  // close panel to go back to default panel state
  onClosePanel() {
    SRV.DetailsClosed();

    this.setState({
      menuType: 'routeDetails'
    });
  }

  testPrintFalse() {
    //NOTE: timeout is to avoid window freezing (in chrome)
    setTimeout(() => {
      //Manually wait for canvas to redraw
      this.setState({
        inPrintMode: false
      });
    }, 1000);
  }

  togglePrintMode() {
    let inPrintMode = !this.state.inPrintMode;
    this.setState(
      {
        inPrintMode: inPrintMode
      },
      () => {
        if (inPrintMode) {
          setTimeout(() => {
            //Manually wait for canvas to redraw
            window.print();
          }, 1000);
        }
      }
    );
  }

  // meta is for react-select
  handleChange(event, meta) {
    handleOptionChange(this, event, meta);
  }

    onSearchClick() {
	let searchTarget = this.props.results.routes[0].route_tree.prod_id;
	if (searchTarget.indexOf("CNEW-") === 0)
	    searchTarget = this.props.results.cpds[searchTarget].smiles;
	
	console.log("search again", searchTarget, this);
    let options = {
      maxRoute: this.state.maxRoute,
      maxReactionSteps: this.state.maxReactionSteps,
      literatureDerivedReactions: this.state.literatureDerivedReactions,
      maxReagentCost: this.state.maxReagentCost,
      autoSYNCompatible: this.state.autoSYNCompatible,
      computerGeneratedReactions: this.state.computerGeneratedReactions,
      exclude: true,
      keep: true,
      searchTarget: searchTarget,//this.props.options.searchTarget,
      smilesTarget: '',//this.props.options.smilesTarget
    };

      let url = Utils.BuildSearchURL(options);
      console.log(url);

    if (url == null) {
      alert(
        'Must selection either Literature Derived or Computer Generated reactions or both'
      );
      return;
    }
    this.setState({
      isSearching: true
    });

	let tokenGW = this.props.user.tokenGW;
	/*
      if (!tokenGW)
	  tokenGW = config.TOKEN_GW;
	*/
      this.props.onSearch(
        url,
        options,
        this.props.history,
        tokenGW
      );
  }

   findButton(subtitle)
    {
	let buttons = document.getElementsByTagName('button');
	for(let b=0; b < buttons.length; b++) {
	    if (buttons[b].title.indexOf(subtitle) !== -1) {
		return buttons[b];
	    }
	}
    }
    
  onCanvasClick(data) {
    let type;
    let details;
    let dataType = data.type;

    let flagBtn = this.findButton('Flag this');
    let shareBtn = this.findButton('Share this');      
      
    switch (dataType) {
      case 'compoundDetails':
        type = 'compoundDetails';
        details = { ...data };
	flagBtn.title = 'Flag this compound';
	shareBtn.title = 'Share this compound';
        break;
      case 'reactionDetails':
        type = 'reactionDetails';
        details = { ...data };
	flagBtn.title = 'Flag this reaction';
	shareBtn.title = 'Share this reaction';
        break;
      case 'routeDetails':
        type = 'routeDetails';
        details = { ...data };
	flagBtn.title = 'Flag this route';
	shareBtn.title = 'Share this route';
        break;
      case 'searchAgain':
        type = this.state.menuType;
        break;
      default:
        type = 'reaction';
        details = { ...data };
	flagBtn.title = 'Flag this reaction';
	shareBtn.title = 'Share this reaction';
    }

    /* check for searchAgain type
    if(type === )
    let enableSearchAgain = details.enable
    */

    // set state
    if (dataType === 'routeDetails') {
      this.setState({
        menuType: type,
        routeDetails: details
      });
    } else if (dataType === 'searchAgain') {
      this.setState({
        isSearchAble: data.enabled
      });
    } else {
      this.setState({
        menuType: type,
        details: details
      });
    }
  }

  collapseClicked() {
    let isCollapsed = !this.state.isCollapsed;

    this.setState({
      isCollapsed: isCollapsed
    });
    // canvas doesn't get resize event. Have to redraw after canvas parent is resized
    setTimeout(SRV.OnRedraw, 1);
  }

  updateMenuType(s) {
    this.setState({
      menuType: s
    });
  }

  toggleSavePanel() {
    let isSavePanelOpen = !this.state.isSavePanelOpen;

    this.setState({
      isSavePanelOpen: isSavePanelOpen
    });
  }

    onOrderBOM() {
	Utils.ShowBOM(this.state.routeDetails);
    }
    
  // format body text that goes in email also return subject
    formatSubjectBody() {
	let routeId = !isNaN(this.props.routeId) ? this.props.routeId : -1;
	let strategyId = !isNaN(this.props.strategyId) ? this.props.strategyId : -1;
    let options = this.props.options;
    let details =
      this.state.menuType === 'routeDetails'
        ? this.state.routeDetails
        : this.state.details;
    let subject = 'Route';

    let formattedDetails = '';
    if (strategyId !== -1 && routeId !== -1)
        formattedDetails = '<b>Strategy ' + (strategyId+1) + ' Route ' + (routeId+1) + '</b>\n\n';

    switch (this.state.menuType) {
      case 'routeDetails':
        subject = 'Route';
      formattedDetails +=
          'Details: ' +
          '\n' +
          '\tTotal Steps: ' +
          details.steps +
          '\n' +
          '\tLongest Segment: ' +
          details.maxsegment +
          '\n' +
          '\tSolvent Changes: ' +
          details.solventChanges +
          '\n' +
          '\tSolvent Cost: ' +
          details.solventCost +
          '\n' +
          '\tRoute Cost: ' +
          details.routeCost +
          '\tAutoSyn Compatible: ' +
          details.autosync +
          '\n';
        break;
      case 'reactionDetails':
        subject = 'Reaction';
        formattedDetails +=
          'Details: ' +
          '\n' +
          '\tID: ' +
          details.id +
          '\n' +
          '\tYield: ' +
          details.yield +
          '\n';
        break;
      case 'compoundDetails':
        subject = 'Compound';
        formattedDetails +=
          'Details: ' +
          '\n' +
          '\tID: ' +
          details.id +
          '\n' +
          '\tName: ' +
          details.name +
          '\n' +
          '\tSource: ' +
          details.CompoundType +
          '\n' +
          '\tSMILES: ' +
          details.smiles +
          '\n' +
          '\tInChi: ' +
          details.inchi +
          '\n' +
          '\tCost: ' +
          details.mol_cost +
          '\n' +
          '\tMol weight: ' +
          details.mol_weight +
          '\n' +
          '\tMCT: ' +
          details.MCT +
          '\n';
        break;
      default:
        formattedDetails = '';
    }

    let body =
      formattedDetails +
      'Search options: \n' +
      '\tMax Routes: ' +
      options.maxRoute.value +
      '\n' +
      '\tMax Cost: ' +
      options.maxReagentCost.value +
      '\n' +
      '\tMax Steps: ' +
      options.maxReactionSteps.value +
      '\n' +
      '\tAutoSyn Compatible: ' +
      options.autoSYNCompatible +
      '\n' +
      '\tLiterature Derived: ' +
      options.literatureDerivedReactions +
      '\n' +
      '\tComputer Generated: ' +
      options.computerGeneratedReactions +
      '\n';

    return [body, subject];
  }

  onShareClick() {
    let shareEmailShow = !this.state.shareEmailShow;

    let [body, subject] = this.formatSubjectBody();

    this.setState({
      shareEmailShow: shareEmailShow,
      desc: shareEmailShow ? body : '',
      flagEmailSubject: shareEmailShow ? subject : ''
    });
  }

  onFlagClick() {
    let flagEmailShow = !this.state.flagEmailShow;

    let [body, subject] = this.formatSubjectBody();
    this.setState({
      flagEmailShow: flagEmailShow,
      desc: flagEmailShow ? body : '',
      flagEmailSubject: flagEmailShow ? subject : ''
    });
  }

  renderMenu(type) {
    switch (type) {
      case 'routeDetails':
        return <RouteDetails maxCount={29} details={this.state.routeDetails} />;
      case 'refined':
        return (
          <Refined
            options={this.props.options}
            onSearchClick={this.onSearchClick}
            state={this.state}
            handleChange={this.handleChange}
          />
        );
      case 'compoundDetails':
        return (
          <CompoundDetails
            maxCount={55}
            details={this.state.details}
            onClose={this.onClosePanel}
          />
        );
      case 'reactionDetails':
        return (
          <ReactionDetails
            maxCount={20}
            details={this.state.details}
            onClose={this.onClosePanel}
            onFlagClick={this.onFlagClick}
          />
        );
      default:
        return <RouteDetails maxCount={29} details={this.state.routeDetails} />;
    }
  }

  render() {
    const {
      routeId,
      strategyId,
      results,
      options,
      onCreateNewProject,
      user,
      updateSavedRoutes,
      updateFlagged
    } = this.props;

    if (isEmpty(options) || isEmpty(results)) {
      return null;
    }

    let menu = this.renderMenu(this.state.menuType);
    let title = 'Route Details'; //unused
    let isDisabled = false;
/*
    switch (this.state.menuType) {
      case 'routeDetails':
        title = 'Route Details';
        isDisabled = false;
        break;
      case 'refined':
        // set disable state when in refined;
        isDisabled = true;
        title = 'Route Details';
        break;
      case 'compoundDetails':
        title = 'Compound Details';
        isDisabled = false;
        break;
      case 'reactionDetails':
        title = 'Reaction Details';
        isDisabled = false;
        break;
      default:
        title = 'Route Details';
        isDisabled = false;
    }
      console.log('menuType', this.state.menuType, title);
*/
    return (
      <div className="route-page">
        {this.state.isSearching && (
          <div className="search-notification-container">
            <img src={Loading} alt={STRINGS.searching} />
            <h3 className="search-notification">{STRINGS.searching}</h3>
          </div>
        )}
        {this.state.shareEmailShow && (
          <div className="search-notification-container">
            <FlagEmailPopup
              type="Shared"
              label={STRINGS.shareEmailHeader}
              subject={this.state.flagEmailSubject}
              cancelClick={this.onShareClick}
              desc={this.state.desc}
              user={user}
              placeholder={
                'Please describe any additional information about this ' +
                this.state.flagEmailSubject.toLowerCase()
              }
              details={
                this.state.menuType === 'routeDetails'
                  ? this.state.routeDetails
                  : this.state.details
              }
            />
          </div>
        )}
        {this.state.flagEmailShow && (
          <div className="search-notification-container">
            <FlagEmailPopup
              type="Flagged"
              label={STRINGS.flagEmailHeader}
              sendTo={config.emailFlag}
              subject={this.state.flagEmailSubject}
              cancelClick={this.onFlagClick}
              desc={this.state.desc}
              updateFlagged={updateFlagged}
              user={user}
              placeholder={
                'Please describe what is wrong with this ' +
                this.state.flagEmailSubject.toLowerCase()
              }
              details={
                this.state.menuType === 'routeDetails'
                  ? this.state.routeDetails
                  : this.state.details
              }
            />
          </div>
        )}
        {this.state.isSavePanelOpen && (
          <SaveRoutePanel
            results={results}
            routeId={routeId}
            strategyId={strategyId}
            options={options}
            user={user}
            authToken={this.state.authToken}
            onCloseClick={this.toggleSavePanel}
            onCreateNewProjectClick={onCreateNewProject}
            updateSavedRoutes={updateSavedRoutes}
            projects={user.projects}
          />
        )}
        <div className="route-search-button-container">
          <div className="route-header flex-row-space-between">
            <div className="route-header-left">
              <h3>{title}</h3>
              <p>
                Strategy {strategyId+1} Route {routeId+1}
              </p>
            </div>
            <div className="route-header-right">
              <Button
                text="Search again"
                isPrimary={true}
                isDisabled={!this.state.isSearchAble}
                onClick={this.onSearchClick}
              />
              <Button
                type="save"
                isDisabled={isDisabled}
                onClick={this.toggleSavePanel}
              />
              <Button
                type="share"
                isDisabled={isDisabled}
                onClick={this.onShareClick}
              />
              <Button
                type="print"
                isDisabled={isDisabled}
                onClick={this.togglePrintMode}
              />
              <Button
                type="order"
                isDisabled={isDisabled}
                onClick={this.onOrderBOM}
              />
              {/*this.state.menuType !== 'refined' && 
                <Button text='REFINE ROUTE' color='darkBlue' onClick={() => this.updateMenuType('refined')}/>
                */}
          <Button
                type="flag"
                isDisabled={isDisabled}
                onClick={this.onFlagClick}
              />
            </div>
          </div>
        </div>
        <div
          className={
            this.state.inPrintMode ? 'route-contents-print' : 'route-contents'
          }
        >
          {!this.state.isCollapsed && (
            <div className="route-left">
              <StyledSidebarToggle
                img={SidebarClose}
                imgHover={SidebarCloseHover}
                left="295px"
                onClick={this.collapseClicked}
              />
              <div className="route-jsme">
                <h3>{STRINGS.targetCompound}</h3>
              </div>
              {<Canvas id={0} results={results} type="targetCanvas" />}
              <div className="route-search-options">{menu}</div>
            </div>
          )}
          {this.state.isCollapsed && (
            <div className="route-left-collapsed">
              <StyledSidebarToggle
                img={SidebarOpen}
                imgHover={SidebarOpenHover}
                left="5px"
                onClick={this.collapseClicked}
              />
            </div>
          )}

            <div className="route-right">
	    {
	    /* JAH -- disable search errors from propagating to route page. May not be correct?
            this.props.strategies.errorMsg !== '' && (
              <div className="route-options-error">
                {this.props.strategies.errorMsg}
              </div>
            )
	    */
	    }
            <Canvas
              strategyId={strategyId}
              routeId={routeId}
              results={results}
              onCanvasClick={this.onCanvasClick}
              type="refineCanvas"
            />
            </div>

	    <div className="popup" id="BOM-popup">
	      <div className="buttons">
               <button onClick={Utils.CloseBOM}>CLOSE </button>
               <button onClick={Utils.CopyBOM}>COPY </button>
               <button onClick={Utils.SaveBOM}>SAVE </button>
    	      </div>
	      <div className="scroller" id="BOM-div">
     	        <table id="BOM-table"></table>
	      </div>
            </div>
	    <div className="popup" id="Procedure-popup">
	      <div className="popup-title"> {"Procedure from:"}</div>
	      <div className="scroller">
     	        <div id="Procedure-div"></div>
	      </div>
	      <div className="buttons">
	        <button onClick={Utils.CloseProcedure}>CLOSE </button>
	        <button onClick={Utils.CopyProcedure}>COPY </button>
    	      </div>
            </div>
        </div>	    
      </div>
    );
  }
}

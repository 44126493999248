/* eslint eqeqeq: 0 */
/* eslint strict: 0 */

import { config } from '../config';
import { SRV } from './RouteVisualization';
import { Okta } from './okta';
import { STRINGS } from '../constants/strings';

export var Utils = {};

Utils.BuildSearchURL = function(options)
{
    //console.log("build", options);

    if (!options.computerGeneratedReactions &&
	!options.literatureDerivedReactions) {
	console.log("one or both of computer or literature must be selected")
	return null;
    }
    
    let exclude = '';
    if (options.exclude) {
	for (let x in SRV.avoidList) {
	    console.log('avoid', x);
	    if (SRV.avoidList[x]) exclude += x + ' ';
	}
	exclude = encodeURIComponent(exclude);
	console.log('exclude: ', exclude);
    }

    let keep = '';
    if (options.keep) {
	for (let x in SRV.keepList) {
	    console.log('keep', x);
	    if (SRV.keepList[x]) keep += x + ' ';
	}
	keep = encodeURIComponent(keep);
	console.log('keep: ', keep);
    }
    SRV.keepList = {};
    SRV.avoidList = {};

    let goalname;

    if (!options.tsvFile) {

	let searchTarget = options.searchTarget;
	let autocomplete = Utils.autocomplete;
	for(let n = 0; n < autocomplete.cpd_htmlnames.length; n++) {
	    if (searchTarget == autocomplete.cpd_htmlnames[n]) {
		searchTarget = autocomplete.cpd_idnames[n];
		break;
	    }
	}
	console.log("search target", searchTarget, options.smilesTarget);
	goalname = encodeURIComponent(searchTarget);
	/*
	if (options.smilesTarget)
	    goalname = encodeURIComponent(options.smilesTarget);
	else
	    goalname = encodeURIComponent(options.searchTarget);
	*/
    } else {
	goalname = '""'
    }

    let url =
	config.GATEWAY_SERVER + '/search.php' +
	'?nbroutes=' + options.maxRoute.value +
	'&maxlength=' + options.maxReactionSteps.value +
	'&goalname=' + goalname +
	'&maxcostreagent=' + options.maxReagentCost.value +
	'&autosyn=' + options.autoSYNCompatible +
	'&literaturerxns=' + (options.literatureDerivedReactions ? 'true' : 'false') +
	'&computerrxns=' + (options.computerGeneratedReactions ? 'true' : 'false') +
	'&excluderxnscpds=' + exclude +
	'&keeprxnscpds=' + keep;

  return url;
};

Utils.LoadJSON = function(url, tokenGW, callback)
{
    if (!tokenGW) { tokenGW = config.DEBUG_AUTH; }

	
    var req = new XMLHttpRequest();
    req.open('GET', url);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);
	
	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		//console.log(req.response);
		//callback(null, 404);
		try {
		    var json = JSON.parse(req.responseText);
		    callback(json, req.status);
		} catch(err) {
		    alert('JSON error' + err);
		}
	    } else {
		callback(req.responseText, req.status);
	    }
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback("Network error", 503);
    };    

    req.send(null);
};

Utils.PostFile = function(url, tokenGW, name, file, callback) {

    //if (!tokenGW) tokenGW = config.DEBUG_AUTH;

    console.log('post', file, new Date().toLocaleString());

    var req = new XMLHttpRequest();
    req.open('POST', url);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		console.log('resp', file, new Date().toLocaleString());
		console.log(req.responseText);
		try {
		    var json = JSON.parse(req.responseText);
		    callback(json, req.status);
		} catch(err) {
		    alert(err);
		    callback(null, 500);
		}
	    } else {
		callback(req.responseText, req.status);
	    }
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback("Network error", 503);
    };    

    var form = new FormData();
    form.append(name, file);

    req.send(form);	
};

Utils.ExtractSavedRoute = function (results, strategyId, routeId)
{
    let savedRoute = {};

    let route = results.strategyRouteIndex['S' + strategyId + 'R' + routeId];
    if (!route) {
	console.log('Invalid routeId', strategyId, routeId);
	return null;
    }

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');

    savedRoute.status = results.status;
    savedRoute.diversified = results.diversified;
    savedRoute.database = results.database;
    savedRoute.nb_rxns_producing_target = results.nb_rxns_producing_target;
    savedRoute.nb_new_rxns_producing_target = results.nb_new_rxns_producing_target;
    savedRoute.timestamp = timestamp;
    savedRoute.routes = [route];
    savedRoute.cpds = {};
    savedRoute.rxns = {};

    for (let s in route.route_segments) {
	let segment = route.route_segments[s];
	for (let r = 0; r < segment.length; r++) {
	    let rxn = segment[r];
	    for (let b = 0; b < rxn.branchIds.length; b++) {
		let cpd = rxn.branchIds[b];
		if (!results.cpds[cpd]) {
		    console.log('branch error', rxn.branchIds[b]);
		    return null;
		}
		savedRoute.cpds[cpd] = results.cpds[cpd];
	    }

	    for (let f = 0; f < rxn.feedstockIds.length; f++) {
		let cpd = rxn.feedstockIds[f];
		if (!results.cpds[cpd]) {
		    console.log('feedsock error!', rxn.feedstockIds[f]);
		    return null;
		}
		savedRoute.cpds[cpd] = results.cpds[cpd];
	    }

	    let rxr = rxn.rxn_id;
	    if (!results.rxns[rxr]) {
		console.log('rxr!', rxn.rxn_id);
		return null;
	    }
	    savedRoute.rxns[rxr] = results.rxns[rxr];

	    for(let v in results.rxns[rxr].variations) {
		let variation = results.rxns[rxr].variations[v];
		for(let c in variation.conditions) {
		    let condition = variation.conditions[c];
		    for(let cat in condition.catalysts) {
			let cpd = condition.catalysts[cat];
			if (!results.cpds[cpd]) {
			    console.log('catalysts not fould', cpd, results);
			} else {
			    savedRoute.cpds[cpd] = results.cpds[cpd];
			}
		    }
		    for(let reagent in condition.reagents) {
			let cpd = condition.reagents[reagent];
			if (!results.cpds[cpd]) {
			    console.log('reagent not found', cpd, results);
			} else {
			    savedRoute.cpds[cpd] = results.cpds[cpd];
			}
		    }
		    for(let solvent in condition.solvents) {
			let cpd = condition.solvents[solvent];
			if (!results.cpds[cpd]) {
			    console.log('solvent not foud', cpd, results);
			} else {
			    savedRoute.cpds[cpd] = results.cpds[cpd];
			}
		    }
		}
	    }
	    
	    let cpd = rxn.prod_id;
	    if (!results.cpds[cpd]) {
		console.log('prod error!', rxn.prod_id);
		return null;
	    }
	    savedRoute.cpds[cpd] = results.cpds[cpd];
	}
    }

    return savedRoute;
};
/*
function CopyUser(user)
{ 
    let savedRoutes = user.savedRoutes;
    user.savedRoutes = [];

    let flagged = user.flagged;
    user.flagged = [];
    
    user = JSON.parse(JSON.stringify(user));

    for(let r in savedRoutes) {
	console.log(r, savedRoutes[r]);
	let trees = Utils.DrawTreeSave(savedRoutes[r]);
	savedRoutes[r] = JSON.parse(JSON.stringify(savedRoutes[r]));
	Utils.DrawTreeRestore(savedRoutes[r], trees);
    }
    user.savedRoutes = savedRoutes;

    for(let f in flagged) {
	let canvas = flagged[f].details.canvas;
	flagged[f].details.canvas = null;
	let trees = Utils.DrawTreeSave(flagged[f].details.results);
	flagged[f] = JSON.parse(JSON.stringify(flagged[f]));
	Utils.DrawTreeRestore(flagged[f].details.results, trees);
	flagged[f].details.canvas = canvas;
    }
    user.flagged = flagged;

    return user;
}
*/

Utils.SaveRoute = async function(results, routeId, strategyId, options, routeObj, user, callback)
{
    console.log('SaveRoute', routeId, strategyId, options, routeObj, user);

    let project = user.projects.find(function(p) {
	return p.name == routeObj.project.label;
    });

    if (!project) {
	console.log("Can't locate project", routeObj.project.label);
	callback(null, 401);
	return;
    }
    
    let savedRoute = Utils.ExtractSavedRoute(results, strategyId, routeId);
    if (!savedRoute) {
	callback(null, 401);
	return;
    }
    savedRoute.project = project.timestamp;
    savedRoute.routeName = routeObj.routeName;
    savedRoute.routeDescription = routeObj.routeDescription;
    savedRoute.searchOptions = options;

    //save/delete SmilesDrawer draw tree from savedRoute
    let drawTrees = Utils.DrawTreeSave(savedRoute); 
    let _savedRoute = JSON.stringify(savedRoute);
    Utils.DrawTreeRestore(savedRoute, drawTrees);     //restore SmilesDraw drawTrees

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');
    let key = encodeURIComponent(timestamp);

    if (user.tokenOkta) {
	try {
	    let reply = await Okta.SaveRoute(user.tokenOkta, project.uuid, _savedRoute);
	    console.log('saved', reply);
	    if (reply.errors) {
		if (callback)
		    callback(user, 503, reply.errors[0].message);
		return;
	    }

	    //fetch all saved routes just to make sure we're sync'd
	    let routes = await Okta.FetchAllSavedRoutes(user.tokenOkta, user.projects);
	    user.savedRoutes = Utils.SortByKey(routes, 'timestamp', 'down');
	    console.log('retrieved routes', user.savedRoutes);
	    if (callback) {
		callback(user, 200, null);
	    }	    
	} catch(err) {
	    console.log("Saved Route failed", err);
	}
    } else {
	let url = config.GATEWAY_SERVER + '/db.php?path=savedroutes&op=create&key=' + key;
	let tokenGW = user.tokenGW;

	if (!tokenGW) tokenGW = config.DEBUG_AUTH;

	let req = new XMLHttpRequest();
	req.open('POST', url, true);
	req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

	req.overrideMimeType('application/json');
	req.onload = function() {
	    if (req.readyState == 4) {
		if (req.status == 200) {
		    let savedroutes = JSON.parse(req.responseText);
 		    user.savedRoutes = Utils.SortByKey(savedroutes, 'timestamp', 'down');
		}
		if (callback)
		    callback(user, req.status);
	    }
	};

	req.onerror = function () {
	    console.log("Network error", req);
	    callback(user, 503);
	};    
	req.send(_savedRoute);
    }
    

  return 0;
};

Utils.DeleteSavedRoute = function(route, user, callback)
{
    console.log('delete saved route', route, user);

    let tokenGW = user.tokenGW;
    if (!tokenGW)
	tokenGW = config.DEBUG_AUTH;

    let key = encodeURIComponent(route.timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=savedroutes&op=delete&key=' + key;
    let req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
//		user = CopyUser(user);
		let savedroutes = JSON.parse(req.responseText);
		user.savedRoutes = Utils.SortByKey(savedroutes, 'timestamp', 'down')
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    

    req.send(null);
};

Utils.UpdateSavedRoute = function(route, update, user, callback)
{
    let oldroute = {
	name: route.routeName,
	description: route.routeDescription
    };
    
    route.routeName = update.routeName;
    route.routeDescription = update.routeDescription;

    let key = encodeURIComponent(route.timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=savedroutes&op=update&key=' + key;
    let tokenGW = user.tokenGW;
    if (!tokenGW) tokenGW = config.DEBUG_AUTH;
    
    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		let savedroutes = JSON.parse(req.responseText);
 		user.savedRoutes = Utils.SortByKey(savedroutes, 'timestamp', 'down');
		console.log('updated', user);
	    } else {
		route.routeName = oldroute.name;
		route.routeDescription = oldroute.description;
		console.log('restore', user);		
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    

    //save/delete SmilesDrawer draw tree from savedRoute
    let drawTrees = Utils.DrawTreeSave(route);

    req.send(JSON.stringify(route));

    //restore SmilesDraw drawTrees
    Utils.DrawTreeRestore(route, drawTrees);

    return;
};


Utils.CreateProject = function(name, desc, user, callback)
{
    console.log('create project', name, desc, user);

    let pname1 = name.toLowerCase();
    for (let p in user.projects) {
	let pname2 = user.projects[p].name.toLowerCase();
	if (pname1 == pname2) {
	    console.log('duplicate project', name, desc);
	    if (callback)
		callback(user, -1, 'Project "' + name + '" already exists');
	    return -1;
	}
    }

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');
    let key = encodeURIComponent(timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=projects&op=create&key=' + key;
    let tokenGW = user.tokenGW;
    if (!tokenGW) tokenGW = config.DEBUG_AUTH;

    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    let projects = JSON.parse(req.responseText);
	    user.projects = Utils.SortByKey(projects, 'timestamp', 'down');
	    if (callback)
		callback(user, req.status, "project create database error");
	}
    };

    req.onerror = function () {
	callback(user, 503, "project create network Error");
    };    

    
    let project = {
	name: name,
	desc: desc,
	timestamp: timestamp
    };
    req.send(JSON.stringify(project));

    return 0;
};

Utils.UpdateProject = function(project, details, user, callback)
{
    console.log('update project', project, details);
    project = JSON.parse(JSON.stringify(project));
    project.name = details.projectName;
    project.desc = details.projectDesc;

    let key = encodeURIComponent(project.timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=projects&op=update&key=' + key;
    let tokenGW = user.tokenGW;
    if (!tokenGW) tokenGW = config.DEBUG_AUTH;

    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    console.log(req.responseText);
	    let projects = JSON.parse(req.responseText);
	    user.projects = Utils.SortByKey(projects, 'timestamp', 'down');
	    if (callback)
		callback(user, req.status, "project update database error");
	}
    };

    req.onerror = function () {
	callback(user, 503, "project create network Error");
    };    

    req.send(JSON.stringify(project));

    return 0;
};

Utils.DeleteRoutes = function(routes, user, callback)
{
    console.log("DeleteRoutes", routes);

    if (routes.length < 1) {
	callback(user, 200)
	return;
    }
    
    let tokenGW = user.tokenGW;
    if (!tokenGW)
	tokenGW = config.DEBUG_AUTH;

    let url = config.GATEWAY_SERVER + '/db.php?path=savedroutes&op=delete';
    let req = new XMLHttpRequest();
    req.open('PUT', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		let savedroutes = JSON.parse(req.responseText);
		user.savedroutes = Utils.SortByKey(savedroutes, 'timestamp', 'down')
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    

    req.send(JSON.stringify(routes));
}

Utils.DeleteProject = function(project, user, callback) {
    console.log('delete project', project);

    let routes = [];
    for(let s in user.savedRoutes) {
	if (user.savedRoutes[s].project == project.timestamp) {
	    routes.push(user.savedRoutes[s].timestamp);
	}
    }

    Utils.DeleteRoutes(routes, user, function(user, status) {
	if (status != 200) {
	    callback(user, status)
	    return;
	}
	console.log('delete routes done', status);

	let tokenGW = user.tokenGW;
	if (!tokenGW)
	    tokenGW = config.DEBUG_AUTH;

	let key = encodeURIComponent(project.timestamp);
	let url = config.GATEWAY_SERVER + '/db.php?path=projects&op=delete&key=' + key;
	let req = new XMLHttpRequest();
	req.open('GET', url, true);
	req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

	req.overrideMimeType('application/json');
	req.onload = function() {
	    if (req.readyState == 4) {
		console.log(req.status, req.responseText);
		if (req.status == 200) {
		    let projects = JSON.parse(req.responseText);
		    user.projects = Utils.SortByKey(projects, 'timestamp', 'down')
		}
		if (callback)
		    callback(user, req.status);
	    }
	};

	req.onerror = function () {
	    console.log("Network error", req);
	    callback(user, 503);
	};    

	req.send(null);
    });
};

Utils.autocomplete = {
    cpd_idnames: [],
    cpd_names: [],
    cpd_htmlnames: []
};

Utils.AutoComplete = function(tokenGW, requestId, searchStr, callback) {

    if (!tokenGW) {
		tokenGW = config.TOKEN_GW;
	}
    
  let url = config.GATEWAY_SERVER + '/autocomplete.php?prefix=' + searchStr;
    Utils.LoadJSON(url, tokenGW, function(data, status) {
	if (status == 200 && data.status == 'no-error') {
	    console.log(data);
	  let names = [];
	  for (let n in data.cpd_names) {
              let name = data.cpd_names[n];
	      if (name.indexOf('&') != -1) {
		  name = Utils.HtmlDecode(name);
	      }
              names.push(name);
	  }
	  data.cpd_htmlnames = names;
	  Utils.autocomplete = data;
	  callback(requestId, searchStr, names, status);
    } else {
      callback(requestId, searchStr, [], 404);
    }
  });
};


Utils.flaggedCompound = function (results, cpd_id)
{
    let flagged = {};

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');

    flagged.status = results.status;
    flagged.diversified = results.diversified;
    flagged.database = results.database;
    flagged.nb_rxns_producing_target = results.nb_rxns_producing_target;
    flagged.nb_new_rxns_producing_target = results.nb_new_rxns_producing_target;
    flagged.timestamp = timestamp;
    flagged.routes = [];
    flagged.cpds = {};
    flagged.rxns = {};

    flagged.cpds[cpd_id] = results.cpds[cpd_id];

    return flagged;
};

Utils.flaggedReaction = function (results, rxn_id)
{
    let flagged = {};

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');

    flagged.status = results.status;
    flagged.diversified = results.diversified;
    flagged.database = results.database;
    flagged.nb_rxns_producing_target = results.nb_rxns_producing_target;
    flagged.nb_new_rxns_producing_target = results.nb_new_rxns_producing_target;
    flagged.timestamp = timestamp;
    flagged.routes = [];
    flagged.cpds = {};
    flagged.rxns = {};

    let rxr = results.rxns[rxn_id];
    flagged.rxns[rxn_id] = rxr;

    for(let r = 0; r < rxr.reactants.length; r++) {
	let rxc = rxr.reactants[r];
	flagged.cpds[rxc] = results.cpds[rxc];
    }
    for(let p = 0; p < rxr.products.length; p++) {
	let rxc = rxr.products[p];
	flagged.cpds[rxc] = results.cpds[rxc];
    }

    return flagged;
};


Utils.SaveFlagged = function(details, description, user, callback)
{
    let flagResults = {};

    switch(details.type) {
    case "compoundDetails":
	flagResults = Utils.flaggedCompound(details.results, details.id);
	break;

    case "reactionDetails":
	flagResults = Utils.flaggedReaction(details.results, details.id);
	break;

    case "routeDetails":
	console.log('routeDetail', details.strategyId, details.routeId);
	callback(user, 200);
	return;

    case "strategyRouteDetails":
	console.log('strategyRouteDetail', details.strategyId, details.routeId);
	callback(user, 200);
	return;

    case "strategyDetails":
	console.log('strategyDetail', details.strategyId);
	callback(user, 200);
	return;

    default:
	console.log('unknown flagged type', details.type);
	callback(user, 500);
	return;
    }

    let timestamp = new Date().toLocaleString();
    timestamp = timestamp.replace(',', '');
    let key = encodeURIComponent(timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=flagged&op=create&key=' + key;
    let tokenGW = user.tokenGW;
    if (!tokenGW)
	tokenGW = config.DEBUG_AUTH;

    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		let flagged = JSON.parse(req.responseText);
		user.flagged = Utils.SortByKey(flagged, 'timestamp', 'down');
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    

    // don't save dynamic data to database
    let drawTrees = Utils.DrawTreeSave(flagResults);
    let results = details.results;
    details.results = flagResults;
    let canvas = details.canvas;
    details.canvas = null;
    
    let flagged = {
	details: details,
	description: description,
	timestamp: timestamp
    };
    req.send(JSON.stringify(flagged));

    //restore dynamic data
    Utils.DrawTreeRestore(flagResults, drawTrees);
    details.results = results;
    details.canvas = canvas;
    
    return 0;
};

Utils.FlaggedIndex = function(user, flagged)
{
    for(let idx = 0; idx < user.flagged.length; idx++) {
	if (user.flagged[idx].timestamp == flagged.timestamp)
	    return idx;
    }

    console.log("Invalid flagged", flagged);
    return -1;
};

Utils.DeleteFlagged = function(flagged, user, callback)
{
    console.log('remove flagged', flagged, user);

    let tokenGW = user.tokenGW;
    if (!tokenGW)
	tokenGW = config.DEBUG_AUTH;

    let key = encodeURIComponent(flagged.timestamp);
    let url = config.GATEWAY_SERVER + '/db.php?path=flagged&op=delete&key=' + key;
    let req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		let flagged = JSON.parse(req.responseText);
		user.flagged = Utils.SortByKey(flagged, 'timestamp', 'down')
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    

    req.send(null);
};

Utils.UpdateFlagged = function(flagged, description, user, callback)
{
    console.log('update flagged', flagged, description, user);

    let idx = Utils.FlaggedIndex(user, flagged);
    if (idx == -1) {
	callback(user, -1);
	return;
    }
    let oldDescription = flagged.description;
    flagged.description = description;

    let url = config.GATEWAY_SERVER + '/flagged.php?op=update&id=' + idx;
    let tokenGW = user.tokenGW;
    if (!tokenGW) tokenGW = config.DEBUG_AUTH;
    
    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status == 200) {
		let flagged = JSON.parse(req.responseText);
		user.flagged = Utils.SortByKey(flagged, 'timestamp', 'down')
	    } else {
		user.flagged[idx].description = oldDescription
	    }
	    if (callback)
		callback(user, req.status);
	}
    };

    req.onerror = function () {
	console.log("Network error", req);
	callback(user, 503);
    };    


    //save/delete SmilesDrawer draw tree from flagged
    let drawTrees = Utils.DrawTreeSave(flagged.details.results);

    let json = JSON.stringify(flagged.details.results);
    req.send(json);

    //restore SmilesDraw drawTrees
    Utils.DrawTreeRestore(flagged.details.results, drawTrees);

    return;
};

Utils.UpdateUserProfile = async function(details, user, callback)
{
    let profile = {
	name: details.name,
	phone: details.phone,
	title: details.jobTitle,
	org: details.teamName,
	email: details.email,
	username: user.username,
	photo: user.photo
    }

    let url = config.GATEWAY_SERVER + '/db.php?path=profile&op=update&key=/';
    let tokenGW = user.tokenGW;
    if (!tokenGW) tokenGW = config.DEBUG_AUTH;

    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);
    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (req.status != 200) {
		callback(user, req.status);
		return;
	    }
	    profile = JSON.parse(req.responseText);
	    user.name = profile.name;
	    user.phone = profile.phone;
	    user.jobTitle = profile.title;
	    user.teamName = profile.org;
	    user.email = profile.email;
	    user.username = profile.username;
	    user.photo = profile.photo;
	    callback(user, req.status);
	}
    };

    req.onerror = function () {
	callback(user, -1);
    };    

    req.send(JSON.stringify(profile));
    return;
};

Utils.DrawTreeSave = function(route)
{
    // save smiles draw trees
    let drawTrees = {};
    for (let cpd in route.cpds) {
	if (route.cpds[cpd].drawTree) {
	    drawTrees[cpd] = route.cpds[cpd].drawTree;
	    route.cpds[cpd].drawTree = null;
	}
    }

    return drawTrees;
};

Utils.DrawTreeRestore = function(route, drawTrees)
{
    //restore SmilesDraw drawTrees
    for (let cpd in drawTrees) {
	route.cpds[cpd].drawTree = drawTrees[cpd];
    }

};


let htmlDecodeMap = {}

Utils.HtmlDecode = function(html)
{
    if (htmlDecodeMap[html]) {
	return htmlDecodeMap[html];
    }
    
    let converter= document.getElementById('htmlConverter');
    if (!converter) {
        converter = document.createElement('div');
	converter.id = 'htmlConverter';
	converter.style.display = 'none';
	document.body.appendChild(converter);
    }
    converter.innerHTML = html;
    let text = converter.innerText;
    htmlDecodeMap[html] = text;
    return text;
};

Utils.HtmlEncode = function(text)
{/* convert &#xxx; to &html;
    var html = text.replace(/[\u00A0-\u2666]/g, function(c) {
	return '&#'+c.charCodeAt(0)+';';
    });
 */
    let html = Object.keys(htmlDecodeMap).find(key => htmlDecodeMap[key] == text);
    if (html)
	return html;
    else
	return text;
};

Utils.SendMail = function(to, from, subject, body, canvas, user, callback)
{
    //console.log("SendMail", 'to', to, 'from', from, 'sub', subject, 'body', body, canvas, user, callback);

    let nullCanvas = SRV.nullCanvas;
    nullCanvas.width = canvas.width;
    nullCanvas.height = canvas.height;
    let ctx = nullCanvas.getContext('2d');

    //create a rectangle with the desired color
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0,0,nullCanvas.width,nullCanvas.height);

    //draw the original canvas onto the destination canvas
    ctx.drawImage(canvas, 0, 0);

    let image = nullCanvas.toDataURL('image/jpeg');

    let mail = {
	to: to,
	from: from,
	subject: subject,
	message: body,
	image: image
    };
    
    let tokenGW = user.tokenGW;//'amltOnN5bnJvdXRl';
    let url = config.GATEWAY_SERVER + '/mail.php';
    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Authorization', 'Basic ' + tokenGW);

	addSynFiniAuthorization(req);

    req.overrideMimeType('application/json');
    req.onload = function() {
	if (req.readyState == 4) {
	    if (callback)
		callback(req.status);
	}
    };

    req.onerror = function () {
	console.log("SendMail: Network error", req);
	callback(503);
    };    
   
    req.send(JSON.stringify(mail));
};

Utils.SortByKey = function(object, key, dir)
{
    if (!object)
	return [];

    let index = [];
    for(let o in object) 
	index.push(object[o]);

    dir = (dir == 'up') ? -1 : 1;

    // hack for timestamp compares since mon, day, hour, sec etc have be or two digits
    if (key == 'timestamp') {
	for(let o in object)
 	    object[o].key = Date.parse(object[o].timestamp);
    } else {
	for(let o in object)
 	    object[o].key = object[o][key].toLowerCase();
    }
    
    index.sort(function(a,b) {
	return (a.key < b.key) ? dir : (a.key > b.key) ? -dir : 0;
    });

    //console.log("sort", key, index.length, index);
    return index;
}

Utils.ShowBOM = function(routeDetails)
{
    let segments = routeDetails.canvas.segments;
    let results = routeDetails.results;
    console.log(segments);

    let bom = [];
    let rid = 1;
    for (let s in segments) {
	let segment = segments[s];
	for (let n = 0; n < segment.length; n++) {
	    let node = segment[n];
	    if (node.disabled) {
		console.log("skip", s, n, node.name);
		continue;
	    }
//	    console.log(node.type, node.name, node.purchase);
	    switch(node.type) {
	    case 'rxc':
		if (node.purchase) {
		    let cpd = node.id;
		    if (!cpd) {
			console.log('feedsock error!', node);
			break;
		    }
		    bom.push({
			type: 'feedstock',
			variationId: 0,
			reactionId: rid,
			cpd: cpd
		    });
		}
		break;
	    case 'rxr':
		console.log('rxr', node);
		let rxr = node.rxr;
		if (!rxr) {
		    console.log('rxr error!', node);
		    break;
		}
		for(let v in rxr.variations) {
		    let variation = rxr.variations[v];
		    let vid = parseInt(v) + 1;
		    for(let c in variation.conditions) {
			let condition = variation.conditions[c];
			for(let cat in condition.catalysts) {
			    let cpd = condition.catalysts[cat];
			    if (!cpd) {
				console.log('catalysts not fould', cpd);
			    } else {
				bom.push({
				    type: 'catalyst',
				    reactionId: rid,
				    variationId: vid,				
				    cpd: cpd
				});
			    }
			}
			for(let reagent in condition.reagents) {
			    let cpd = condition.reagents[reagent];
			    if (!cpd) {
				console.log('reagent not found', cpd);
			    } else {
				bom.push({
				    type: 'reagent',
				    reactionId: rid,
				    variationId: vid,				
				    cpd: cpd
				});
			    }
			}
			for(let solvent in condition.solvents) {
			    let cpd = condition.solvents[solvent];
			    if (!cpd) {
				console.log('solvent not foud', cpd);
			    } else {
				bom.push({
				    type: 'solvent',
				    reactionId: rid,
				    variationId: vid,				
				    cpd: cpd
				});
			    }
			}
		    }
		}
		rid++;
		break;
	    default:
	    }
	}
    }
    
    let popup = document.getElementById('BOM-popup');
    let table = document.getElementById('BOM-table');
    let html =
	    '<thead>' +
	    '<tr>' +
	    '<th> Compound </th>' +
	    '<th> eMolecules Id </th>' +
	    '<th> Supplier </th>' +
	    '<th> Tier </th>' +
	    '<th> Amount </th>' +
	    '<th> Units </th>' +
	    '<th> Price </th>' +
	    '<th> Type </th>' +
	    '<th> Reaction </th>' +
	    '<th> Variation </th>' +
	    '</tr>' +
	    '</thead>' +
	'<tbody>';
    for(let b in bom) {
	let rxc = results.cpds[bom[b].cpd];
	//let rxc = bom[b].cpd;
	html +=
	    '<tr>' +
	    '<td>' + rxc.name + '</td>' +
	    '<td>' + (rxc.em_versionId ? rxc.em_versionId : 'n/a') + '</td>' +
	    '<td>' + (rxc.em_supplierName ? rxc.em_supplierName : 'n/a') + '</td>' +
	    '<td>' + (rxc.em_tierNum ? rxc.em_tierNum : 'n/a') + '</td>' +
	    '<td>' + (rxc.em_amount ? rxc.em_amount : 'n/a') + '</td>' +
	    '<td>' + (rxc.em_units ? rxc.em_units : 'n/a') + '</td>' +
	    '<td>' + (rxc.em_price ? '$' + FormatPrice(rxc.em_price) : 'n/a') + '</td>' +
	    '<td>' + bom[b].type + '</td>' +
	    '<td>' + bom[b].reactionId + '</td>' +
	    '<td>' + (bom[b].variationId ? bom[b].variationId : ' ') + '</td>' +	    
	    '</tr>';
    }
    html += '</tbody>';

    table.innerHTML = html;
    
    let div = document.getElementById("BOM-div");
    div.setAttribute("contenteditable", true);
    
    popup.style.display = 'block';

    if (!popup.isDragable)
	InitDragable(popup);
}

function FormatPrice(cost)
{
    return cost;
}

Utils.CopyBOM = function()
{    
    let bom = document.getElementById('BOM-div');
    bom.setAttribute("onfocus", "document.execCommand('selectAll',false,null)"); 
    bom.focus();
    document.execCommand("copy");
    bom.blur();    
    window.getSelection().removeAllRanges();
    alert("BOM copied to clipboard");
}

Utils.SaveBOM = function()
{    
    let bom = document.getElementById('BOM-table');
    let cvs = "";
    for(let r = 0; r < bom.rows.length; r++) {
	let tr = bom.rows[r];
	for(let c = 0; c < tr.cells.length; c++) {
	    cvs += c ? "\t" + tr.cells[c].innerText : tr.cells[c].innerText;
	}
	cvs += String.fromCharCode(13);
    }
    console.log(cvs);
    var blob = new Blob( [ cvs ], {
	type: 'application/octet-stream'
    });
    
    var url = URL.createObjectURL( blob );
    var link = document.createElement( 'a' );
    link.setAttribute( 'href', url );
    link.setAttribute( 'download', "bom.tsv" );

    var event = document.createEvent( 'MouseEvents' );
    event.initMouseEvent( 'click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    link.dispatchEvent( event );
};

Utils.CloseBOM = function()
{
    let popup = document.getElementById('BOM-popup');
    if (popup)
	popup.style.display = 'none';
}

// Make the BOM draggable
function InitDragable(dialog)
{
    var xoff, yoff;

    dialog.isDragable = true;
    dialog.style.left = "25px";
    dialog.style.top = "75px";
    dialog.addEventListener('mousedown', dragDialogDown);

    function dragDialogDown(e)
    {
	if (!e.target.classList.contains("popup") &&
	    !e.target.classList.contains("popup-title"))
	    return;

	// get the mouse cursor position at startup:
	if (e.type == 'touchstart') {
	    e = e.changedTouches[0];
	}
	xoff = e.clientX - dialog.offsetLeft;
	yoff = e.clientY - dialog.offsetTop;

	dialog.style.width = dialog.clientWidth + "px";
	dialog.style.height = dialog.clientHeight + "px";	
	document.addEventListener('mouseup', dragDialogUp);
	document.addEventListener('mousemove', dragDialogMove);
    }

    function dragDialogMove(e)
    {
	if (e.buttons != 1)
	    return dragDialogUp(e);
	
	dialog.style.left = (e.clientX - xoff) + "px";
	dialog.style.top = (e.clientY - yoff) + "px";
    }

    function dragDialogUp(e)
    {
	dialog.style.width = null;
	dialog.style.height = null;
	document.removeEventListener('mouseup', dragDialogUp);
	document.removeEventListener('mousemove', dragDialogMove);
    }
}


Utils.ShowSMARTS = function(smarts, callback)
{
    let popup = document.getElementById('SMARTS-popup');
    let table = document.getElementById('SMARTS-table');
    let html =
	'<thead>' +
	'<tr>' +
	'<th> Input Id </th>' +
	'<th> Smiles </th>' +
	'<th> Costs </th>' +
	'<th> Feedstocks </th>' +
	'<th> Intermediates </th>' +	
	'<th> RXNS </th>' +
	'<th> new RXNS </th>' +
	'<th> routes </th>' +
	'</thead>' +
	'<tbody>';

    for(let s in smarts) {
	html +=
	    '<tr>' +
	    '<td>' + smarts[s].inputId + '</td>' +
	    '<td>' + smarts[s].smiles + '</td>' +
	    '<td>' + smarts[s].costs + '</td>' +
	    '<td>' + smarts[s].feedstocks + '</td>' +
	    '<td>' + smarts[s].icpds + '</td>' +	    
	    '<td>' + smarts[s].rxns + '</td>' +
	    '<td>' + smarts[s].newrxns + '</td>' +
	    '<td>' + smarts[s].nroutes + '</td>' +
	    '</tr>';
    }

    html += '</tbody>';
    table.innerHTML = html;
/*    
    for(var r = 1; r < table.rows.length; r++) {
	table.rows[r].addEventListener("mouseover", mouseOver);
	//table.rows[r].addEventListener("mouseout", mouseOut);
	//table.rows[r].addEventListener("mousemove", mouseMove);	
	table.rows[r].addEventListener("click", mouseClick);
    }   
    table.addEventListener('mouseout', mouseOutTable);
    

    var tooltip = null;
    var smart = null;
    tooltip = document.createElement('div');
    tooltip.id = 'smartsTip';
    tooltip.classList.add("hidden");
    //tooltip.classList.add("arrow_box_left");
    tooltip.classList.add("tooltip");
    document.body.appendChild(tooltip);

    function mouseOutTable(evt)
    {
	var bbox = table.getBoundingClientRect();
	console.log("out tip", smart);
	if (evt.pageX < bbox.left ||
	    evt.pageY < bbox.top ||
	    evt.pageX > bbox.right ||
	    evt.pageY > bbox.bottom) {
	    if (tooltip)
		tooltip.classList.add('hidden');
	    if (smart && smart.preview)
		smart.preview.classList.add('hide');
	}
    }

    function mouseOver(evt)
    {
	if (smart && smart.preview)
	    smart.preview.classList.add('hide');

	smart = smarts[this.rowIndex-1];
	var results = smart.results;	
	if (!results)
	    return;
	
	tooltip.classList.remove('hidden');

	if (!smart.preview) {
	    smart.preview = document.createElement('div');
	    smart.preview.classList.add("smartsPreview");
	    tooltip.appendChild(smart.preview);
	    for(var sid in results.strategyCount) {
		var div = document.createElement('div');
		var id = "Strategy " + (parseInt(sid)+1);
		var desc = SRV.GetStrategyDesc(results, sid);
		div.innerHTML = `<div> <b>${id}</b> ${desc} </div> <canvas></canvas>`;
		smart.preview.appendChild(div);
	    }
	}
	smart.preview.classList.remove('hide');
	var canvases = smart.preview.querySelectorAll('canvas');
	for(var s = 0; s < canvases.length; s++) {
	    SRV.CanvasMount(canvases[s], s, 0, 'strategyCanvas', results, null);
	}

	//var bbox = this.getBoundingClientRect();
	var x = evt.pageX + 30;
	var y = evt.pageY - 150; //tooltip.clientHeight/2);
	tooltip.style.left = x + 'px';	
	tooltip.style.top = y + 'px';
    }

    function mouseMove(evt)
    {return;
	var x = evt.pageX + 30;
	var y = evt.pageY - 150; //tooltip.clientHeight/2);
	tooltip.style.left = x + 'px';	
	tooltip.style.top = y + 'px';
    }
    
    function mouseOut()
    {return;
	console.log("row out");
	tooltip.classList.add('hidden');
	//var smart = smarts[this.rowIndex-1];
	if (smart.preview)
	    smart.preview.classList.add('hide');
    }

    function mouseClick()
    {
	//var smart = smarts[this.rowIndex-1];
	console.log('click', smart);
	if (!smart)
	    return;
	var results = smart.results;
	if (!results)
	    return;
	results.smartsId = smart;
	tooltip.classList.add('hidden');
	if (smart.preview)
	    smart.preview.classList.add('hide');
	if (callback)
	    callback(results);
    }
*/    
    popup.style.display = 'block';

    if (!popup.isDragable)
	InitDragable(popup);

    
}

function ParseSmartsTSV(tsv)
{
    var smarts=[];
    for(var r = 1; r < tsv.length; r++) {
	var row = tsv[r].split('\t');
	var smart;
	if (!row[0])
	    continue;
	if (!row[5] || !row[40]) {
	    smart = {
		inputId: row[1],
		smiles: row[0],
		icpds: "",
		rxns: "",
		newrxns: "",
		feedstocks: "",
		costs: "",
		nroutes: 0,
		results: null
	    }
	}
	else {
	    var results = row[40];
	    results = results.replace(/\\/g, '');
	    results = results.replace(/\//g, '');
	    results = JSON.parse(results);

	    var min_icpds=row[5+1], max_icpds=row[5+1];
	    var min_rxns=row[5+2], max_rxns=row[5+2];
	    var min_newrxns=row[5+3], max_newrxns=row[5+3];
	    var min_feedstocks=row[5+4], max_feedstocks=row[5+4];
	    var min_cost=row[5+5], max_cost=row[5+5];
	    for(var rr = 1; rr < 5; rr++) {
		var cc = 5 + rr * 7;
		if (!row[cc]) {
		    console.log('skip', r, rr, cc);
		    continue;
		}
		min_icpds = parseInt(Math.min(min_icpds, row[cc+1]));
		max_icpds = parseInt(Math.max(max_icpds, row[cc+1]));
		min_rxns = parseInt(Math.min(min_rxns, row[cc+2]));
		max_rxns = parseInt(Math.max(max_rxns, row[cc+2]));
		min_newrxns = parseInt(Math.min(min_newrxns, row[cc+3]));
		max_newrxns = parseInt(Math.max(max_newrxns, row[cc+3]));
		min_feedstocks = parseInt(Math.min(min_feedstocks, row[cc+4]));
		max_feedstocks = parseInt(Math.max(max_feedstocks, row[cc+4]));
		min_cost = Math.min(min_cost, row[cc+5]);
		max_cost = Math.max(max_cost, row[cc+5]);
	    }
	    smart = {
		inputId: row[1],
		smiles: row[0],
		icpds: min_icpds + "-" + max_icpds,
		rxns: min_rxns + "-" + max_rxns,
		newrxns: min_newrxns + "-" + max_newrxns,	    
		feedstocks: min_feedstocks + "-" + max_feedstocks,	    
		costs: "$" + min_cost + "-$" + max_cost,	    
		nroutes: results ? results.routes.length : 0,
		results: results,
		counts: SRV.GetStrategyRouteCounts(results), 
	    }
	}
	    
	if (!smart.inputId)
	    smart.inputId = "CHEMBL" + parseInt(Math.random() * 50000);
	
	smarts.push(smart);
    }
    console.log(smarts);

//    SaveJSON(results, "batch.json");
    return smarts;
}

Utils.OnSmarts = async function(tsv, callback)
{
    var smarts = ParseSmartsTSV(tsv);
    if (!smarts)
	return;
    Utils.ShowSMARTS(smarts, callback);

    return;
}

Utils.CloseSMARTS = function()
{
    console.log("CloseSmarts");
}

/*
async function FetchFile(url)
{
    let response = await fetch(url);
    if (!response.ok) {
	const message = url + " " + response.status;
	console.log(message);
	return null;
	//throw new Error(message);
    }
    let text = await response.text();

    return text;
}
*/

Utils.ShowProcedure = function(title, comment)
{
    let popup = document.getElementById('Procedure-popup');
    let div = popup.querySelector('.popup-title');
    div.innerHTML = title;
    div = popup.querySelector('#Procedure-div');
    div.innerText = comment;
    popup.style.display = 'block';

    if (!popup.isDragable)
	InitDragable(popup);

}

Utils.CloseProcedure = function()
{
    let popup = document.getElementById('Procedure-popup');
    if (popup)
	popup.style.display = 'none';
}

Utils.CopyProcedure = function()
{
    let div = document.getElementById('Procedure-div');
    var range = document.createRange();
    range.selectNode(div);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges();// to deselect
    alert("procedure copied to clipboard");
}

Utils.LoadScript = function(scriptFile, onLoad)
{
    const script = document.createElement("script");
    script.src = scriptFile;
    script.async = true;
    if (onLoad)
	script.onload = onLoad;

    document.body.appendChild(script);
}
                                  
function addSynFiniAuthorization(req) {
    let synfiniOktaToken = sessionStorage.getItem(STRINGS.synfiniOktaStorageToken);
    if (synfiniOktaToken) {
        req.setRequestHeader('X-Synfini-Authorization', 'Bearer ' + synfiniOktaToken);
    }
}
                                  

import {
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
//  UPDATE_AUTH,
  CREATE_NEW_PROJECT,
  ADD_NEW_SAVED_ROUTE,
  REMOVE_PROJECT,
  REMOVE_SAVED_ROUTE,
  UPDATE_PROFILE,
  UPDATE_SAVED_ROUTE,
  UPDATE_FLAGGED,
  UPDATE_PROJECTS,
  UPDATE_BATCH_RESULTS
} from '../constants/actionTypes';

const initialState = {
  tokenGW: '',
  tokenOkta: '',
  username: '',
  name: '',
  jobTitle: '',
  qualifications: '',
  teamName: '',
  email: '',
  phone: '',
  projects: [],
  savedRoutes: [],
  flagged: []
};

export default (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_BATCH_RESULTS:
      return {
        ...state,
        batchResults: action.batchResults
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        tokenGW: action.user.tokenGW,
        tokenOkta: action.user.tokenOkta,
        username: action.user.username,
        photo: action.user.photo,
        name: action.user.name,
        jobTitle: action.user.jobTitle,
        qualifications: action.user.qualifications,
        teamName: action.user.teamName,
        projects: action.user.projects,
        savedRoutes: action.user.savedRoutes,
        flagged: action.user.flagged,
        email: action.user.email,
        phone: action.user.phone
      };
    case LOGOUT_SUCCEEDED:
      return initialState;
    case UPDATE_PROFILE:
      return {
        ...state,
        name: action.user.name,
        jobTitle: action.user.jobTitle,
        teamName: action.user.teamName,
        email: action.user.email,
        phone: action.user.phone
      };
    case CREATE_NEW_PROJECT:
      return {
        ...state,
        projects: [...action.project]
      };
    case ADD_NEW_SAVED_ROUTE:
      return {
        ...state,
        savedRoutes: [...action.savedRoute]
      };
    case UPDATE_SAVED_ROUTE:
      return {
        ...state,
        savedRoutes: [...action.savedRoute]
      };
    case UPDATE_FLAGGED:
      return {
        ...state,
        flagged: [...action.flagged]
      };
    case UPDATE_PROJECTS:
      return {
        ...state,
        projects: [...action.projects]
      };
    case REMOVE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((item, index) => index !== action.index)
      };
    case REMOVE_SAVED_ROUTE:
      return {
        ...state,
        savedRoutes: state.savedRoutes.filter(
          (item, index) => index !== action.index
        )
      };
    default:
      return state;
  }
};

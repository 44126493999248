/**
 * Button component. Usage example below:
 *
 * Usage examples:
 * <Button type="flag" isDisabled={isDisabled} onClick={function} />
 * <Button text="Search again" isPrimary={true} onClick={function} />
 *
 * `type`: Type allows for presets to surface for regularly used button. Those are detailed below:
 * - `normal`: Default. User will need to set `text` to added text to the button
 * - `flag`: Automatically sets the flag icon and
 * - `save`:
 * - `share`:
 * - `print`:
 * - `close`:
 * - `undo`:
 *
 * Regarding color, by default all button follow the structure below:
 * - Default color is `primaryButtonColor`
 * - Hover color is `primaryButtonHoverColor`
 *
 * But type=flag will default to the following:
 * - Default color is `primaryButtonColor`
 * - Hover color is `flagButtonHoverColor`
 *
 * `buttonColor`: This is the override color for hover. There is no override for default button color.
 *
 * `text`: This sets the button text, overrides any presets. Presets exists for non-normal type.
 *
 * `hintText`: Sets the hover hint text, overrides any presets. Presets exist for non-normal type.
 *
 * `width`: Sets the width of button, overrides native auto behavior.
 *
 * `height`: Sets the height of button, overrides native auto behavior.
 *
 * `isDisabled`: Default = false. Sets the button disabled state.
 *
 * `isIconOnly`: Default = false. If true, removes the text and forces into square icon button.
 *
 * `isPrimary`: Default = false. Establishes if button is primary, doing so will draw box around button.
 *
 * `iconImg`: Optional. Sets the icon image.
 *
 * `iconImghover`: Optional. Sets the icon image that appears on hover. If non exist, will use `iconImg`.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STRINGS } from '../../constants/strings';

// Flag icon
import FlagIcon from '../../assets/images/icon-flag-default@3x.png';
import FlagDisabledIcon from '../../assets/images/icon-flag-disabled@3x.png';
import FlagHoverIcon from '../../assets/images/icon-flag-hover@3x.png';

// Save icon
import SaveIcon from '../../assets/images/icon-save-default@3x.png';
import SaveDisabledIcon from '../../assets/images/icon-save-disabled@3x.png';
import SaveHoverIcon from '../../assets/images/icon-save-hover@3x.png';

// Order icon
import OrderIcon from '../../assets/images/icons8-checkout-24.png';
import OrderDisabledIcon from '../../assets/images/icons8-checkout-24.png';
import OrderHoverIcon from '../../assets/images/icons8-checkout-24.png';

// Share icon
import ShareIcon from '../../assets/images/icon-share-default@3x.png';
import ShareDisabledIcon from '../../assets/images/icon-share-disabled@3x.png';
import ShareHoverIcon from '../../assets/images/icon-share-hover@3x.png';

// Print icon
import PrintIcon from '../../assets/images/icon-print-default@3x.png';
import PrintDisabledIcon from '../../assets/images/icon-print-disabled@3x.png';
import PrintHoverIcon from '../../assets/images/icon-print-hover@3x.png';

// Close icon
import CloseIcon from '../../assets/images/icon-x-default@3x.png';
import CloseDisabledIcon from '../../assets/images/icon-x-disabled@3x.png';
import CloseHoverIcon from '../../assets/images/icon-x-hover@3x.png';

// Undo icon
import UndoIcon from '../../assets/images/icon-undo-default@3x.png';
import UndoDisabledIcon from '../../assets/images/icon-undo-disabled@3x.png';
import UndoHoverIcon from '../../assets/images/icon-undo-hover@3x.png';

const StyledButton = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px ${props => (props.isIconOnly ? '8px' : '16px')};
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  font-weight: 400 !important;
  white-space: nowrap;
//  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isPrimary ? props.theme.primaryButtonColor : 'transparent'};
  color: ${props => props.theme.primaryButtonColor};
  font-size: ${props => props.theme.primaryButtonFontSize} !important;
  .icon {
    display: ${props => (props.img ? 'inline-block' : 'none')};
    ${props => (props.img ? 'background-image: url(' + props.img + ');' : '')};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    ${props => (props.isIconOnly ? '' : 'margin-right: 5px;')};
    width: ${props => props.iconSize} !important;
    height: ${props => props.iconSize} !important;
  }
  &:hover {
    cursor: pointer;
    color: ${props =>
      (props.buttonColor === 'flagButtonHoverColor' &&
        props.theme.flagButtonHoverColor) ||
      (props.buttonColor === 'primaryButtonHoverColor' &&
        props.theme.primaryButtonHoverColor) ||
      props.buttonColor};
    border-color: ${props =>
      (props.buttonColor === 'flagButtonHoverColor' &&
        props.theme.flagButtonHoverColor) ||
      (props.buttonColor === 'primaryButtonHoverColor' &&
        props.theme.primaryButtonHoverColor) ||
      props.buttonColor};
    ${props => (props.isPrimary ? '' : 'border-color: transparent;')};
    .icon {
      ${props =>
        props.imgHover ? 'background-image: url(' + props.imgHover + ');' : ''};
    }
  }
  &:disabled {
    color: ${props => props.theme.disabledButtonColor};
    border-color: ${props => props.theme.transparentButtonColor};
    ${props => (props.isIconOnly ? 'border: 0;' : '')};
    .icon {
      background-image: url(${props => props.imgDisabled});
    }
    :hover {
      color: ${props => props.theme.disabledButtonColor};
      cursor: not-allowed !important;
      .icon {
        ${props =>
          props.imgDisabled
            ? 'background-image: url(' + props.imgDisabled + ');'
            : ''};
      }
    }
  }
`;
const Button = ({
  type,
  text,
  hintText,
  width,
  height,
  iconSize,
  buttonColor,
  isDisabled,
  isIconOnly,
  isPrimary,
  iconImg,
  iconImgDisabled,
  iconImgHover,
  onClick,
  className
}) => {
  switch (type) {
    case 'print':
      if (!text) {
        text = STRINGS.printButton;
      }
      if (hintText === '') {
        hintText = STRINGS.printButtonHint;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = PrintIcon;
      iconImgDisabled = PrintDisabledIcon;
      iconImgHover = PrintHoverIcon;
      break;
    case 'flag':
      if (!text) {
        text = STRINGS.flagButton;
      }
      if (hintText === '') {
        hintText = STRINGS.flagButtonHint;
      }
      buttonColor = 'flagButtonHoverColor';
      iconImg = FlagIcon;
      iconImgDisabled = FlagDisabledIcon;
      iconImgHover = FlagHoverIcon;
      break;
    case 'save':
      if (!text) {
        text = STRINGS.saveButton;
      }
      if (hintText === '') {
        hintText = STRINGS.saveButtonHint;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = SaveIcon;
      iconImgDisabled = SaveDisabledIcon;
      iconImgHover = SaveHoverIcon;
      break;
    case 'order':
      if (!text) {
        text = STRINGS.orderButton;
      }
      if (hintText === '') {
        hintText = STRINGS.orderButtonHint;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = OrderIcon;
      iconImgDisabled = OrderDisabledIcon;
      iconImgHover = OrderHoverIcon;
      break;
    case 'share':
      if (!text) {
        text = STRINGS.shareButton;
      }
      if (hintText === '') {
        hintText = STRINGS.shareButtonHint;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = ShareIcon;
      iconImgDisabled = ShareDisabledIcon;
      iconImgHover = ShareHoverIcon;
      break;
    case 'close':
      if (!text) {
        text = STRINGS.closeButton;
      }
      if (hintText === '') {
        hintText = STRINGS.closeButtonHInt;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = CloseIcon;
      iconImgDisabled = CloseDisabledIcon;
      iconImgHover = CloseHoverIcon;
      break;
    case 'undo':
      if (!text) {
        text = STRINGS.undoButton;
      }
      if (hintText === '') {
        hintText = STRINGS.undoButtonHint;
      }
      buttonColor = 'primaryButtonHoverColor';
      iconImg = UndoIcon;
      iconImgDisabled = UndoDisabledIcon;
      iconImgHover = UndoHoverIcon;
      break;
    default:
      // normal
      if (hintText === '') {
        hintText = text;
      }
  }
  if (isIconOnly) {
    text = '';
  }
  if (iconImgHover === '') {
    iconImgHover = iconImg;
  }
  return (
    <StyledButton
      type={type}
      width={width}
      height={height}
      buttonColor={buttonColor}
      disabled={isDisabled}
      isIconOnly={isIconOnly}
      isPrimary={isPrimary}
      onClick={onClick}
      title={hintText}
      img={iconImg}
      iconSize={iconSize}
      imgHover={iconImgHover}
      imgDisabled={iconImgDisabled}
      className={className}
    >
      <span className="icon" />
      {text}
    </StyledButton>
  );
};

Button.defaultProps = {
  type: 'normal',
  text: '',
  hintText: '',
  width: 'auto',
  height: 'auto',
  buttonColor: 'primaryButtonHoverColor',
  isIconOnly: false,
  isPrimary: false,
  isDisabled: false,
  iconImg: null,
  iconImgDisabled: null,
  iconImgHover: null,
  className: '',
  iconSize: '12px'
};

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  hintText: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  buttonColor: PropTypes.string,
  isIconOnly: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isDisabled: PropTypes.bool,
  iconImg: PropTypes.string,
  iconImgDisabled: PropTypes.string,
  iconImgHover: PropTypes.string,
  className: PropTypes.string,
  iconSize: PropTypes.string
};

export default Button;

/* eslint eqeqeq: 0 */
/* eslint strict: 0 */
/* eslint no-unused-vars: 0 */


"use strict";
import { Utils } from './utils';

//var alert = console.log;


require('./smiles-drawer-sri.js');
var SmilesDrawer = window.SmilesDrawer;

//SynRoute Visualiztion
export
var SRV = {
    version: 3,
    smilesDrawer: null,
    scaleDevice: 1,
    nullCanvas: null,
    avoidList: {},
    keepList: {},
    avoidKeepMinLength: 110,
    avoidKeepHeight: 25,
    font: "normal normal 400 12px sans-serif",
    fontBold: "normal normal 700 13px sans-serif",
    canvasCount: 0,
    iconPriceTagGreen: "assets/price-tag-green.svg",
    iconPriceTagDarkGreen: "assets/price-tag-darkgreen.svg",
    iconPriceTagGray: "assets/price-tag-gray.svg",
    debugPurchasable: false,
};

//fix this
const ARROW_RIGHT = 1;
const ARROW_RIGHT_DOWN = 2;
const ARROW_RIGHT_TAIL = 3;
const ARROW_LEFT  = 4;
const ARROW_LEFT_DOWN = 5;
const ARROW_LEFT_TAIL  = 6;

SRV.Init = function()
{
    let options = {
        fontSizeLarge: 10,
        fontSizeSmall: 6,
        bondThickness: 2,
        bondLength: 20,
        shortBondLength: 0.75,
        bondSpacing: 4,
	padding: 0,
	compactDrawing: false,
    };
    
    SRV.smilesDrawer = new SmilesDrawer.Drawer(options);
    SRV.nullCanvas = document.createElement('canvas');

    //document.body.appendChild(SRV.nullCanvas);
};


//returns search error msg, null if no errors.
SRV.GetSearchErrors = function (results)
{
    if (results.status == 'no-error')
	return null;
    else
	return results.msg;
};

//return [] of route counts in each strategy
SRV.GetStrategyRouteCounts = function (results) 
{
    let strategy = {};
    let routeIndex = {};
    let sid = 0;

    for(let r = 0; r < results.routes.length; r++) {
	let rxn = results.routes[r].route_tree['rxn_id'];
	if (!strategy[rxn]) {
	    strategy[rxn] = {sid: sid++,
			     rid: 0};
	} else {
	    strategy[rxn].rid++;
	}

	results.routes[r].isPartialRoute = SRV.IsPartialRoute(results.routes[r]);

	routeIndex['S'+ strategy[rxn].sid + 'R' + strategy[rxn].rid] = results.routes[r];
    }
    results.strategyRouteIndex = routeIndex;

    let strategyCount = [];
    for(let s in strategy) {
	strategyCount[strategy[s].sid] = strategy[s].rid + 1;
    }
    results.strategyCount = strategyCount;
    
    return strategyCount;
};

function rxnFixName(rxn)
{
    console.log('fix', rxn);
    let dashes = rxn.split('-');

    if (dashes.length == 3)
	return dashes[1];
    else
	return rxn;
}

SRV.GetStrategyDesc = function(results,  strategyId)
{
    let route = results.strategyRouteIndex['S' + strategyId + 'R0'];
    let rxn = route.route_tree['rxn_id'];
    let name = '';//rxnFixName(rxn);
    let isPartial = route.isPartialRoute;
    let sid = 'S' + strategyId + 'R';
    let mincost, maxcost;
    let minsteps, maxsteps;
    let minsegments, maxsegments;

    mincost = maxcost = route.cost;
    minsteps = maxsteps = route.nbrxns;
    minsegments = maxsegments = route.route_segments.length;
    for(let s in results.strategyRouteIndex) {
	if (s.indexOf(sid) == 0) {
	    route = results.strategyRouteIndex[s];
	    mincost = Math.min(mincost, route.cost);
	    maxcost = Math.max(maxcost, route.cost);
	    minsteps = Math.min(minsteps, route.nbrxns);
	    maxsteps = Math.max(maxsteps, route.nbrxns);
	    minsegments = Math.min(minsegments, route.route_segments.length);
	    maxsegments = Math.max(maxsegments, route.route_segments.length);
	    if (!route.isPartialRoute)
		isPartial = route.isPartialRoute;
	}
    }

    let costs = '';

    if (mincost) {
	mincost = mincost.toFixed(2);
	maxcost = maxcost.toFixed(2);
	if (mincost != maxcost) {
	    costs = '$' + mincost + ' - $' + maxcost + '/mmol';
	} else {
	    costs = '$' + mincost + '/mmol';
	}
    }
    
    let steps;
    if (minsteps != maxsteps) 
	steps = minsteps + ' - ' + maxsteps + ' steps';
    else
	steps = minsteps + (minsteps == 1 ? ' step' : ' steps');

    let segments;
    if (minsegments != maxsegments)
	segments = minsegments + ' - ' + maxsegments + ' segments';
    else
	segments = minsegments + (minsegments == 1 ? ' segment' : ' segments');

    let desc = [
	name + 
	(!isPartial ? '\xa0\xa0' + costs : '') +
	'\xa0\xa0' + steps +
	'\xa0\xa0' + segments
    ];
    if (isPartial)
	desc.push('\xa0\xa0[Partial Route]');

    return desc;
};

SRV.GetRouteDesc = function(results,  strategyId, routeId)
{
    let route = results.strategyRouteIndex['S' + strategyId + 'R' + routeId];
    let rxn = route.route_tree['rxn_id'];
    let name = rxnFixName(rxn);

    let steps = route.nbrxns;
    let cost = '';
    if (route.cost)  {
	cost = '$' + route.cost.toFixed(2) + '/mmol';
    }
    let segments = route.route_segments.length;
    

    let desc = [
	name +
	(!route.isPartialRoute ? '\xa0\xa0' + cost : '') +
	'\xa0\xa0' + steps + ' steps' + 
        '\xa0\xa0' + segments + ' segments'
    ]

    if (route.isPartialRoute)
	desc.push('\xa0\xa0[Partial Route]');

    console.log("GetRouteDesc", desc, name, rxn, route);
    return desc;
};

SRV.DrawTarget = function(results, canvas, resize)
{
//    console.log("SRV.DrawTarget", canvas.id, canvas.width, canvas.height, resize);
    
    if (!results.routes[0]) {
	alert("Can't locate target!");
	return;
    }

    let rxc = results.routes[0].route_tree.prod_id;

    SRV.DrawRXC(rxc, results, canvas, resize, 1);
};

SRV.DrawFlaggedInit = function(flagged, canvas)
{
    if (!flagged || !flagged.details) {
	console.log('SRV.DrawFlagged invalid flagged', flagged);
	return;
    }
    
    let details = flagged.details;
    details.canvas = canvas; //save canvas for sending email
    
    switch(details.type) {
    case 'compoundDetails': 
	canvas.draw = function(resize) {
	    SRV.DrawRXC(details.id, details.results, canvas, resize);
	}
	break;
    case 'reactionDetails':
	SRV.DrawRXRInit(details.id, details.results, canvas);
	canvas.draw = function(resize) {
	    SRV.DrawRoute(canvas, resize);
	};
	break;
    default:
	console.log('invalid flagged', flagged);
	return;
    }
};

SRV.DrawRXR = function(canvas, resize)
{
    console.log('draw rxr', canvas, resize);
    SRV.DrawStrategy(canvas, resize);
};

SRV.DrawRXRInit = function(rxn_id, results, canvas)
{
    let rxr = results.rxns[rxn_id];

    if (!rxr) {
	alert("Can't locate reaction: " + rxn_id);
	return;
    }

    let route;

    let ctx = canvas.getContext('2d');
    let nodes = [];

    let needplus = 0;
    for(let r = 0; r < rxr.reactants.length; r++) {
	let rxc = rxr.reactants[r];
	
	if (needplus) {
	    nodes.push( {
		type: 'plus',
		name: 'plus',
		length: 50,
		height: 50
	    });
	    needplus = 0;
	}

	let cpd = results.cpds[rxc];
	if (!cpd) {
	    console.log('Invalid CPDS entry rxc');
	    continue;
	} 

	if (!cpd.drawTree && !cpd.smilesError) {
	    SRV.DrawTreeInit(cpd, canvas);
	}

	//let name = results.cpds[rxn.branchIds[b]].name;
	let name = results.cpds[rxc].name;
	name = SRV.HtmlDecode(name);
	nodes.push( {
	    type: 'rxc',
	    cpd: cpd,
	    id: rxc,
	    name: name,
	    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
	    length: cpd.length,
	    height: cpd.height
	});
	needplus = 1;
    }

    let name = rxnFixName(rxn_id);
    nodes.push( {
	type: 'rxr',
	rxr: rxr,
	id: rxn_id,
	rxrnew: rxr.new,
	name: rxr.new ? rxr.className : name
    });

    needplus = 0;
    for(let p = 0; p < rxr.products.length; p++) {
	let rxc = rxr.products[p];

	if (needplus) {
	    nodes.push( {
		type: 'plus',
		name: 'plus',
		length: 50,
		height: 50
	    });
	    needplus = 0;
	}

	let cpd = results.cpds[rxc];
	if (!cpd) {
	    console.log('smiles error!', rxc);
	    return;
	}

	if (!cpd.drawTree && !cpd.smilesError) {
	    SRV.DrawTreeInit(cpd, canvas);
	}
	
	let name = results.cpds[rxc].name;
	name = SRV.HtmlDecode(name);
	nodes.push( {
	    type: 'rxc',
	    cpd: cpd,
	    id: rxc,
	    name: name,
	    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
	    length: cpd.length,
	    height: cpd.height
	});
	needplus = 1;
    }

    for(let n in nodes) {
	let node = nodes[n];
	node.results = results;
	SRV.NodeInit(node);
    }
    
    canvas.segments = [nodes];

    return nodes;
};



SRV.DrawRXC = function(rxcId, results, canvas, resize, hackWidth)
{
    let ctx = canvas.getContext('2d');

    let cpd = results.cpds[rxcId];
    if (!cpd) {
	console.log('smiles error2!', rxcId);
	return;
    }
    
    if (SRV.smilesDrawer == null)
	SRV.Init();

    if (!cpd.drawTree && !cpd.smilesError) {
	SRV.DrawTreeInit(cpd, canvas);
    }
    
    let s = 1;
    if (canvas.type == 'targetCanvas') {
	s =  canvas.clientWidth / (cpd.length + 20);
	if (s > 1)
	    s = 1;
    }
    let height = cpd.height * s + 50;
    
    if (resize) {
	//fix this!
	canvas.style.width = hackWidth ? '300px' : '100%';
	canvas.style.height = height + 'px';
	canvas.width = canvas.clientWidth * SRV.scaleDevice;
	canvas.height = canvas.clientHeight * SRV.scaleDevice;
	ctx.setTransform(1,0,0,1,0,0);
	ctx.scale(SRV.scaleDevice, SRV.scaleDevice);
    }

    ctx.save();
    ctx.setTransform(1,0,0,1,0,0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.restore();
    ctx.scale(s, s);
    let xoff = (canvas.clientWidth - cpd.length)/2;
    let yoff = (canvas.clientHeight - cpd.height)/2;

    xoff = Math.max(xoff, 10);
    yoff = Math.max(yoff, 10);
    
    xoff = parseInt(xoff) + .5; // anti-aliasing
    yoff = parseInt(yoff) + .5;

    ctx.save();
    ctx.translate(xoff, yoff);
    let tree = cpd.drawTree;
    if (tree) {
	tree.canvasWrapper.redraw(canvas);
    } else {
	SRV.DrawNoStructure(canvas, cpd);
    }
	
/*
    ctx.strokeStyle = '#ff0000';
    ctx.strokeRect(0,0, cpd.length, cpd.height);
*/
    ctx.restore();
};

SRV.DrawNoStructure = function(canvas, cpd)
{
    let ctx = canvas.getContext('2d');
    let l = cpd.length;
    let h = cpd.height;
    
    ctx.save();

    ctx.beginPath();
    ctx.strokeStyle = '#000';
    ctx.lineWidth = 2;
    ctx.moveTo(15, 0);
    ctx.lineTo(0, 0);
    ctx.lineTo(0, h);
    ctx.lineTo(15, h);
    ctx.moveTo(l-15, 0);
    ctx.lineTo(l, 0);
    ctx.lineTo(l, h);
    ctx.lineTo(l-15, h);
    ctx.fillStyle = '#000';
    ctx.textBaseline = "top"; 	
    ctx.fillText('NO STRUCTURE', 15, h/2-5);
    ctx.stroke();

    ctx.restore();
};

SRV.DrawTreeInit = function(cpd, canvas)
{
    if (typeof cpd.smiles != "string" || cpd.smiles.length <= 0) {
	console.log('Invalid smiles', cpd.name, cpd.smiles, cpd);
	cpd.smilesError = true;
	cpd.length = 125;
	cpd.height = 30;
	return;
    }

    if (!cpd.smiles || cpd.smiles == "VIRTUAL-TARGET") {
	console.log("SmilesDrawer invalid smiles", cpd.smiles);
	cpd.smilesError = true;
	cpd.length = 125;
	cpd.height = 30;
	return;
    }
    
    SmilesDrawer.parse(cpd.smiles, function(tree) {
	let nullcanvas = SRV.nullCanvas;
	SRV.smilesDrawer.draw(tree, nullcanvas, 'light', false);

	cpd.drawTree = tree;
	tree.xoff = 0;
	tree.yoff = 0;
	tree.scale = 1;

	tree.minX = -tree.canvasWrapper.offsetX;
	tree.minY = -tree.canvasWrapper.offsetY;
	tree.maxX = tree.minX + tree.canvasWrapper.drawingWidth;
	tree.maxY = tree.minY + tree.canvasWrapper.drawingHeight;

	cpd.minX = tree.minX;
	cpd.minY = tree.minY;
	cpd.maxX = tree.maxX;
	cpd.maxY = tree.maxY;
	cpd.length = Math.round(cpd.maxX - cpd.minX);
	cpd.height = Math.round(cpd.maxY - cpd.minY);
	if (cpd.minX == cpd.maxX) {
	    console.log('fix undrawable smiles', cpd.smiles.length);
	    cpd.maxX = cpd.minX + cpd.smiles.length * 6;
	    cpd.length = cpd.maxX - cpd.minX;
	}
	cpd.smilesError = false;
    }, function (err) {
	console.log("SmilesDrawer error", cpd.smiles, err);
	cpd.smilesError = true;
	cpd.length = 125;
	cpd.height = 30;
	});
};


SRV.LayoutSegments = function(canvas, segments)
{
    let xpad = 12;
    let ypad = 20 + SRV.avoidKeepHeight;
    let xmax = canvas.parentNode.clientWidth - xpad;
    let xmin = xpad;
    let ymin = 12;
    let rowNodes = [];

    //layout rows
    for(let s = 0; s < segments.length; s++) {
	let xoff = xpad;
	let dir = 1;
	let nodes = [];
	let prevnode = null;
	
	for(let n = 0; n < segments[s].length; n++) {
	    let node = segments[s][n];
	    if (node.type == 'segment') {
		node.xoff = xoff;
		node.dir = dir;
		rowNodes.push([node]);
		n++;
		node = segments[s][n];
	    }
	    if (nodes.length > 0 && dir > 0 && (xoff + node.nodeLength > xmax)) {
		//xoff -= xpad;
		let dx = xmax - xoff;
		if (nodes.length > 1)
		    dx /= nodes.length;
		
		for(let n = 0; n < nodes.length; n++) {
		    nodes[n].xoff += dx * n * dir;
		}
		rowNodes.push(nodes);
		
		nodes = [];
		xoff = prevnode.xoff + prevnode.nodeLength;

		if (prevnode.type == 'rxr')
		    prevnode.arrowType = ARROW_RIGHT_DOWN;

		dir *= -1;
	    } else if (nodes.length > 0 && dir < 0 && (xoff - node.nodeLength) < xmin) {
		xoff += xpad;
		let dx = xoff - xmin;
		if (nodes.length > 1)
		    dx /= (nodes.length-1);

		for(let n = 0; n < nodes.length; n++) {
		    nodes[n].xoff += dx * n * dir;
		}
		rowNodes.push(nodes);
		
		nodes = [];
		xoff = prevnode.xoff;

		if (prevnode.type == 'rxr')
		    prevnode.arrowType = ARROW_LEFT_DOWN;

		dir *= -1;
	    }
	    xoff = Math.round(xoff);
	    node.xoff = dir > 0 ? xoff : xoff-node.nodeLength;
	    node.dir = dir;

	    if (node.type == 'rxr') {
		if (nodes.length == 0) 
		    node.arrowType = (dir > 0) ? ARROW_RIGHT_TAIL : ARROW_LEFT_TAIL;
		else 
		    node.arrowType = (dir > 0) ? ARROW_RIGHT : ARROW_LEFT;
	    }
	    node.dir = dir;
	    nodes.push(node);
	    xoff += (dir > 0) ? node.nodeLength + xpad : -node.nodeLength - xpad;
	    prevnode = node;
	}
	if (nodes.length > 0) {
	    let dx;
	    if (dir > 0) {
		dx = xmax - xoff;
	    } else {
		xoff += xpad;
		dx = xoff - xmin;
	    }
	    dx /= nodes.length;
	    dx = Math.min(dx, 30);
	    
	    for(let n = 0; n < nodes.length; n++) {
		nodes[n].xoff += dx * n * dir;
		nodes[n].dir = dir;
	    }
	    rowNodes.push(nodes);	    
	}
    }

    //compute xgap between nodes
    for(let r in rowNodes) {
	for(let n in rowNodes[r]) {
	    let nodes = rowNodes[r];
	    if (nodes[n].dir > 0) {
		if (n > 0) 
		    nodes[n].xgap = nodes[n].xoff - (nodes[n-1].xoff + nodes[n-1].nodeLength);
		else
		    nodes[n].xgap = nodes[n].xoff - xmin;
	    } else {
		if (n > 0) 
		    nodes[n].xgap = nodes[n-1].xoff - (nodes[n].xoff + nodes[n].nodeLength);
		else
		    nodes[n].xgap = xmax - (nodes[n].xoff + nodes[n].nodeLength);
	    }
	}
    }

    let ctx = SRV.nullCanvas.getContext('2d');
    let yoff = ymin;
    let height = 0;
    for(let r in rowNodes) {
	let rowheight = 0;
	for(let n in rowNodes[r]) {
	    let node = rowNodes[r][n];

	    switch(node.type) {
	    case 'rxc':
		node.textbox = FormatText(node.name, node.nodeLength + node.xgap, 2);
		/*
		  start here
		console.log(node.textbox);
		if (canvas.route.isPartial) {
		    node.textbox.push( 
			{
			    str: '[No Supplier]',
			    x: xoff,
			    y: lastline.y + lastline.h + 5,
			    l: ilength,
			    h: 15
			});
		    node.nodeHeight += 15;
		}
		*/
		if (node.textbox.length > 0) {		    
		    let lastline = node.textbox[node.textbox.length-1];
		    node.nodeHeight = node.height + lastline.y + lastline.h;

		    if (node.iname) {
			let maxlength = 0;
			for(let t in node.textbox) {
			    maxlength = Math.max(maxlength, node.textbox[t].l);
			}
			let ilength = ctx.measureText(node.iname).width;
			let xoff = (maxlength - ilength)/2;
			node.textbox.push(
			    {
				str: node.iname,
				x: xoff,
				y: lastline.y + lastline.h + 5,
				l: ilength,
				h: 15
			    });
			    node.nodeHeight += 15;
		    }
		} else {
		    node.nodeHeight = node.height;
		}
		break;
	    default:
	    }
	    rowheight = Math.max(rowheight, node.nodeHeight);
	}
	if (rowNodes[r][0].type != 'segment')
	    rowheight += SRV.avoidKeepHeight;

	for(let n in rowNodes[r]) {
	    let node = rowNodes[r][n];
	    node.yoff = yoff + (rowheight - node.nodeHeight)/2;
	    node.rowheight = rowheight;
	    
	    let dx = node.nodeLength - node.length;
	    node.xoff += dx/2;
	}

	height = yoff + rowheight;

	if (rowNodes[r][0].type != 'segment')
	    yoff += rowheight + ypad;
	else
	    yoff += rowheight *2;
    }

    return height;
};

SRV.NodeInit = function(node)
{
    let ctx = SRV.nullCanvas.getContext('2d');
    ctx.font = SRV.font;

    if (!node.name) {
	console.log("no node name", node);
	node.name = '';
    }
    
    if (node.type == 'rxr' && node.name && node.name.length > 80)
	node.name = node.name.substr(0,80) + '...';

    let bbox = ctx.measureText(node.name);
    let nameLength = Math.round(bbox.width);
    let nameHeight = 11;
    if (bbox.actualBoundingBoxAscent &&
	bbox.actualBoundingBoxDescent) {	
	nameHeight = Math.round(bbox.actualBoundingBoxAscent +
				bbox.actualBoundingBoxDescent + .5);
    }

    switch(node.type) {
    case 'segment':
	node.height = nameHeight;
	node.nodeHeight = node.height;
	node.length = nameLength;
	node.nodeLength = node.length;
	break;
    case 'plus':
	node.nodeLength = node.length;
	node.nodeHeight = node.height;
	break;
    case 'rxr':
	node.height = nameHeight;
	node.nodeHeight = node.height;
	node.length = nameLength + 35;

	node.above = [];
	node.below = [];

	//SMARTS have no variations so just add the name
	if (!node.rxr.variations || node.rxr.variations.length == 0) {
	    if (node.rxrnew) {
		node.above.push(node.name);
	    }
	}

	if (node.rxr.variations && node.rxr.variations.length > 0) {
	    let var0 = node.rxr.variations[0];
	    let conditions = var0.conditions[0];
	    let maxrows = conditions.reagents.length + conditions.catalysts.length;
	    if (maxrows > 5)
		maxrows = 4;
	    for(let r = 0; r < conditions.reagents.length; r++) {
		if (node.above.length >= maxrows)
		    break;
		let rxc = conditions.reagents[r];
		if (!node.results.cpds[rxc]) {
		    console.log('reagent missing', rxc);
		    continue;
		}
		let name = node.results.cpds[rxc].name;
		name = TrimText(name, node.length);
		if (node.rxrnew)
		    node.below.push(name);
		else
		    node.above.push(name);
	    }
	    for(let c = 0; c < conditions.catalysts.length; c++) {
		if (node.above.length >= maxrows)
		    break;
		let rxc = conditions.catalysts[c];
		if (!node.results.cpds[rxc]) {
		    console.log('catalysts missing', rxc);
		    continue;
		}
		let name = node.results.cpds[rxc].name;
		name = TrimText(name, node.length);
		if (node.rxrnew)
		    node.below.push(name);
		else
		    node.above.push(name);
	    }
	    if (conditions.reagents.length + conditions.catalysts.length > 5) {
		if (node.rxrnew)
		    node.below.push('[MORE]');
		else
		    node.above.push('[MORE]');
	    }

	    if (node.rxrnew) {
		node.above.push(node.name);
	    }

	    maxrows = conditions.solvents.length;
	    if (maxrows > 3)
		maxrows = 2;
	    for(let s = 0; s < conditions.solvents.length; s++) {
		if (s >= maxrows)
		    break;
		let rxc = conditions.solvents[s];
		if (!node.results.cpds[rxc]) {
		    console.log('solvent missing', rxc);
		    continue;
		}
		let name = node.results.cpds[rxc].name;
		name = TrimText(name, node.length);
		node.below.push(name);
	    }
	    if (conditions.solvents.length > 3)
		node.below.push('[MORE]');

	    let textYield = '';
	    let textTimes = [''];
	    let textTemps = [''];

	    if (var0.yields && var0.yields.length > 0) {
		textYield = (var0.yields[0][1] * 100).toFixed(0) + '%';
	    }

	    for(let c in var0.conditions) {
		textTimes[c] = '';
		let times = var0.conditions[c].times;
		if (times && times.length > 0) {
		    switch(times.length) {
		    case 1: 
			textTimes[c] = FormatTime(times[0]) + ' h'; 
			break;
		    case 2: 
			textTimes[c] = FormatTime(times[0]) + " - " + FormatTime(times[1]) + ' h';
			break;
		    case 3:
			textTimes[c] = FormatTime(times[1]) + ' h';
			break;
		    default:
			console.log("Invalid times", times);
		    }
		}

		textTemps[c] = '';
		let temps = var0.conditions[c].temps;
		if (temps && temps.length > 0) {
		    if (node.rxrnew) {
			for(var t in temps)
			    temps[t] = Math.round(temps[t]);
		    }
		    let degC = SRV.HtmlDecode('&nbsp;&#x2103');
		    switch(temps.length) {
		    case 1: 
			textTemps[c] = temps[0] + degC;
			break;
		    case 2: 
			textTemps[c] = temps[0] + " - " + temps[1] + degC;
			break;
		    case 3:
			textTemps[c] = temps[1] + degC;
			break;
		    default:
			console.log('Invalid temps', temps);
		    }
		}
	    }

	    for(let c = 0; c < var0.conditions.length; c++) {
		let text = '';
		let comma = '';
		if (var0.conditions.length > 1)		
		    text += (c+1) + '. ';
		if (textTemps[c])  {
		    text += textTemps[c];
		    comma = ', ';
		}
		if (textTimes[c]) {
		    text += comma + textTimes[c];
		    comma = ', ';
		}
		if (textYield && c == var0.conditions.length-1) {
		    text += comma + textYield;
		}
		let length = ctx.measureText(text).width;
		node.length = Math.max(node.length, length);
		if (text.length > 0)
		    node.below.push(text);
	    }
	}
	//node.conditions = conditions;
	let maxrows = Math.max(node.above.length, node.below.length);
	node.nodeHeight = (maxrows * 15 + 10)*2;
	node.nodeLength = node.length;
	if (node.nodeLength < SRV.avoidKeepMinLength)
	    node.nodeLength = SRV.avoidKeepMinLength;
	break;
	
    case 'rxc':
	node.nodeLength = node.length;
	if (node.nodeLength < SRV.avoidKeepMinLength)
	    node.nodeLength = SRV.avoidKeepMinLength;
	break;
    default:
	console.log('NodeInit invalid node', node);
    }
};

function TrimText(text, maxlength)
{
    let ctx = SRV.nullCanvas.getContext('2d');
    ctx.font = SRV.font;
    let l = ctx.measureText(text).width;
    if (l < maxlength)
	return text;

    let nchars = parseInt(text.length * maxlength/l) - 3;
    let chop = text.substring(0, nchars) + '...';
    //console.log(l, maxlength, maxlength/l, nchars, text, chop);
    return chop;
}

function FormatText(text, minlength, maxlines)
{
    let ctx = SRV.nullCanvas.getContext('2d');
    ctx.font = SRV.font;

    let words = text.split(/-|\(|\)| /);
    let textbox = [];
    let seglen = 0;
    let p1=0, p2=0;
    let l, h;
    let yoff = 0;
    for(let w in words) {
	let bbox = ctx.measureText(words[w]);
	l = Math.round(bbox.width);
	h = 11;
	if (bbox.actualBoundingBoxAscent &&
	    bbox.actualBoundingBoxDescent) {
	    h = Math.round(bbox.actualBoundingBoxAscent +bbox.actualBoundingBoxDescent + .5);
	}

	if (seglen + l > minlength && p1 != p2) {
	    textbox.push(
		{
		    str: text.substring(p1,p2),
		    x: 0,
		    y: yoff,
		    l: seglen,
		    h: h
		}
	    );
	    seglen = 0;
	    p1 = p2;
	    yoff += h + 5;
	}
	if (w < words.length-1)
	    seglen += l+5;
	else
	    seglen += l;
	p2 += words[w].length + 1;
    }

    textbox.push(
	{
	    str: text.substring(p1,p2),
	    x: 0,
	    y: yoff,
	    l: seglen,
	    h: h
	}
    );
    yoff += h + 5;
    if (textbox.length > maxlines) {
	textbox.length = maxlines;
	textbox[maxlines-1].str += "...";
	textbox[maxlines-1].l = Math.round(ctx.measureText(textbox[maxlines-1].str).width);
    }
    
    return textbox;
};

SRV.DrawSegments = function(canvas, segments)
{
    let ctx = canvas.getContext('2d');
    //let skyblue = '#46c6e9';

    ctx.font = SRV.font;
    ctx.fillStyle = '#000000';
    ctx.textBaseline = "top"; 	

    for(let s in segments) {
	for(let n in segments[s]) {
	    let node = segments[s][n];
	    let xoff = node.xoff;
	    let yoff = node.yoff;
	    let maxlength = 0;

	    let alpha = node.disabled ? .2 : 1;
	    ctx.globalAlpha = alpha;
	    if (node.purchaseBtn) {
		node.purchaseBtn.style.opacity = alpha;
	    }
		
	    xoff += 15;
	    
	    xoff = parseInt(xoff) + .5; // anti-aliasing
	    yoff = parseInt(yoff) + .5;
	    ctx.save();
	    ctx.translate(xoff, yoff);

	    switch(node.type) {
	    case 'segment':
		ctx.font = SRV.fontBold;
		ctx.fillText(node.name, 0, 0);
		ctx.font = SRV.font;
		break;
		
	    case 'rxc':
		if (node.cpd && !node.inode) {
		    let tree = node.cpd.drawTree;
		    if (tree) {
			tree.canvasWrapper.redraw(canvas);
		    } else {
			SRV.DrawNoStructure(canvas, node.cpd);
		    }
		}
		let textheight = 0;
		for(let t in node.textbox) {
		    maxlength = Math.max(maxlength, node.textbox[t].l);
		    textheight += node.textbox[t].h;
		}
		let dx = (node.length - maxlength) / 2;
		let dy = node.height + 5;
		
		if (node.inode) {
		    dy = node.nodeHeight/2 - textheight/2;
		}
		let ybottom = dy;
		let x0 = 0, y0 = 0;
		ctx.fillStyle = node.cdpnew ? 'blue' : '#000';
		for(let t in node.textbox) {
		    let text = node.textbox[t];
		    ctx.fillText(text.str, text.x+dx, text.y+dy);
		    if (t == 0) {
			x0 = text.x + dx;
			y0 = text.y + dy;
		    }
		    ybottom = text.y + dy;
		}
		if (node.purchasable == false) {
		    let text = "[No Supplier]"
		    ctx.fillStyle = '#F00';
		    ctx.font = SRV.fontBold;
		    dx = (node.length/2) - ctx.measureText(text).width/2;
		    dy = ybottom + 18;
		    ctx.fillText(text, dx, dy);
		    ctx.font = SRV.font;
		}		    
		ctx.fillStyle = '#000';

		if (node.purchaseBtn) {
		    node.purchaseBtn.style.left = xoff + x0 - 25 + "px";
		    node.purchaseBtn.style.top = yoff + y0 + "px";
		}
		break;
	    case 'rxr': {
		ctx.fillStyle = node.rxrnew ? 'blue' : '#000';
		ctx.strokeStyle = node.rxrnew ? 'blue' : '#000';

		let yoff = node.nodeHeight/2 - 15;
		if (node.above) {
		    for(let c = node.above.length-1; c >= 0; c--) {
			ctx.fillText(node.above[c], 5, yoff);
			yoff -= 15;
		    }
		}

		yoff = node.nodeHeight/2;
		
		let length = node.nodeLength-5;
		switch(node.arrowType) {
		case ARROW_RIGHT:
		    draw_arrow(ctx, 0, yoff, length, yoff, 10, 10, 0, 1);
		    break;
		case ARROW_RIGHT_DOWN:
		    draw_line(ctx, 0, yoff, length, yoff);
		    draw_arrow(ctx, length, yoff, length, yoff+30, 10, 10, 0, 1);
		    break;
		case ARROW_RIGHT_TAIL:
		    draw_line(ctx, 0, yoff, 0, yoff-30);
		    draw_arrow(ctx, 0, yoff, length, yoff, 10, 10, 0, 1);
		    break;
		case ARROW_LEFT:
		    draw_arrow(ctx, length, yoff, 0, yoff, 10, 10, 0, 1);
		    break;
		case ARROW_LEFT_DOWN:
		    draw_line(ctx, 0, yoff, length, yoff);
		    draw_arrow(ctx, 0, yoff, 0, yoff+30, 10, 10, 0, 1);
		    break;
		case ARROW_LEFT_TAIL:
		    draw_line(ctx, length, yoff, length, yoff-30);
		    draw_arrow(ctx, length, yoff, 0, yoff, 10, 10, 0, 1);
		    break;
		default:
		    console.log('unknow arrow', node.arrowType);
		}
		yoff += 10;

		if (node.below) {
		    for(let c = 0; c < node.below.length; c++) {
			ctx.fillText(node.below[c], 5, yoff);
			yoff += 15;
		    }
		}
		
		//ctx.fillText(node.name, 5, yoff);
		ctx.fillStyle = '#000';
		ctx.strokeStyle = '#000';
		break;
	    }
	    case 'plus': {
		let x = node.length/2;
		let y = node.height/2;
		draw_line(ctx, x-10, y, x+10, y);
		draw_line(ctx, x, y-10, x, y+10);
		break;
	    }
	    default:
		console.log("unknown prim", node);
	    }

	    if (node.frame) {
		if (node.purchaseBtn) {
		    maxlength += 40;
		} 
	    
		let dh = (node.nodeHeight - node.rowheight)/2;
		let w = Math.max(node.nodeLength, maxlength);
		let h = node.rowheight + SRV.avoidKeepHeight;
		let dx = xoff;
		let dy = yoff + dh;
		if (w > node.length)
		    dx -= (w - node.length)/2;

		dx -= 8;
		dy -= 8;
		w += 8;
		h += 8;

		node.frame.left = dx;
		node.frame.top = dy;
		node.frame.right = dx + w;
		node.frame.bottom = dy + h;

		node.frame.style.left = dx + 'px';
		node.frame.style.top = dy + 'px';
		node.frame.style.width = w + 'px';
		node.frame.style.height = h + 'px';
	    }
	    ctx.restore();
	}
    }
    ctx.globalAlpha = 1;
    
    return;
};

SRV.DrawStrategyInit = function(results, canvas, strategyId)
{
    let route = results.strategyRouteIndex['S' + strategyId + 'R0'];
    if (!route) {
	alert("Can't locate strategy: " + strategyId);
	return;
    }

    let stub = route.route[route.route.length-1];

    let ctx = canvas.getContext('2d');
    let nodes = [];

    let needplus = 0;
    for(let p = 0; p < stub.left_primaries.length; p++) {
	
	if (needplus) {
	    nodes.push( {
		type: 'plus',
		name: 'plus',
		length: 50,
		height: 50
	    });
	    needplus = 0;
	}

	let rxc = stub.left_primaries[p];
	let cpd = results.cpds[rxc];
	if (!cpd) {
	    console.log('Invalid CPDS entry rxc');
	    continue;
	} 

	if (!cpd.drawTree && !cpd.smilesError) {
	    SRV.DrawTreeInit(cpd, canvas);
	}

	//let name = results.cpds[rxn.branchIds[b]].name;
	let name = cpd.name;
	if (!name)
	    name = rxc;
	name = SRV.HtmlDecode(name);

	nodes.push( {
	    type: 'rxc',
	    cpd: cpd,
	    id: rxc,
	    name: name,
	    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
	    length: cpd.length,
	    height: cpd.height
	});
	needplus = 1;
    }


    let rxr = results.rxns[stub.rxn_id];
    if (!rxr) {
	console.log('rxr!', stub.rxn_id);
	return;
    }
    let name = rxnFixName(stub.rxn_id);
    nodes.push( {
	type: 'rxr',
	rxr: rxr,
	id: stub.rxn_id,
	rxrnew: rxr.new,
	name: rxr.new ? rxr.className : name
    });

    needplus = 0;
    for(let p = 0; p < stub.right_primaries.length; p++) {
	let rxc = stub.right_primaries[p];

	if (needplus) {
	    nodes.push( {
		type: 'plus',
		name: 'plus',
		length: 50,
		height: 50
	    });
	    needplus = 0;
	}

	let cpd = results.cpds[rxc];
	if (!cpd) {
	    console.log('smiles error3!', rxc);
	    return;
	}

	if (!cpd.drawTree && !cpd.smilesError) {
	    SRV.DrawTreeInit(cpd, canvas);
	}
	
	let name = cpd.name;
	if (!name)
	    name = rxc;
	name = SRV.HtmlDecode(name);
	nodes.push( {
	    type: 'rxc',
	    cpd: cpd,
	    id: rxc,
	    name: name,
	    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
	    length: cpd.length,
	    height: cpd.height
	});
	needplus = 1;
    }

    for(let n in nodes) {
	let node = nodes[n];
	node.results = results;
	SRV.NodeInit(node);
    }
    
    canvas.results = results;
    canvas.segments = [nodes];

    return nodes;
};

SRV.DrawStrategy = function(canvas, resize)
{
    if (!canvas.segments)
	return;

    let segments = canvas.segments;
    let height = SRV.LayoutSegments(canvas, segments);

    let ctx = canvas.getContext('2d');
    SRV.scaleDevice = DevicePixelRatio(ctx);

    if (resize) {
	canvas.style.width = '100%';
	canvas.style.height = height + 'px';
	canvas.width = canvas.clientWidth * SRV.scaleDevice;
	canvas.height = canvas.clientHeight * SRV.scaleDevice;
    }

    ctx.setTransform(1,0,0,1,0,0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.scale(SRV.scaleDevice, SRV.scaleDevice);

    SRV.DrawSegments(canvas, segments);
};

SRV.DrawRouteInit = function(results, canvas, strategyId, routeId)
{  
    let route = results.strategyRouteIndex['S' + strategyId + 'R' + routeId];
    if (!route) {
	alert("Can't locate strategy: " + strategyId);
	return;
    }

    let segments = [];
    let branchIds = [];
    
    for(let s in route.route_segments) {
	let segment = route.route_segments[s];
	let nodes = [];
	
	segments.push(nodes);
	if (route.route_segments.length > 1) {
	    let name = 'Segment ' + segments.length;
	    nodes.push( {
		type: 'segment',
		name: name,
	    });
	}	
	let needplus = 0;

	for(let r = 0; r < segment.length; r++) {
	    let rxn = segment[r];

	    for(let b = 0; b < rxn.branchIds.length; b++) {
		if (needplus) {
		    nodes.push( {
			type: 'plus',
			name: 'plus',
			length: 50,
			height: 50
		    });
		    needplus = 0;
		}

		
		let rxc = rxn.branchIds[b];
		let cpd = results.cpds[rxc];
		if (!cpd) {
		    console.log('smiles error', rxc);
		    return;
		}
		
		if (!cpd.drawTree && !cpd.smilesError) {
		    SRV.DrawTreeInit(cpd, canvas);
		}
		
		let name = cpd.name;
		if (!name) 
		    name = rxc;
		name = SRV.HtmlDecode(name);
		//name = rxc;

		if (!branchIds.find(id => id == rxc)) {
		    console.log("branch not found!", b, rxc, name);
		}
		if (route.feedstocks.find(feed => feed.cid == rxc)) {
		    console.log("branch is a feedstock!", b, rxc, name);
		}
		//console.log("branch found", b, rxc, name);
		
		nodes.push( {
		    type: 'rxc',
		    cpd: cpd,
		    id: rxc,
		    name: name,
		    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
		    length: cpd.length,
		    height: cpd.height,
		    rxctype: 'product'
		});
		needplus = 1;
	    }

	    for(let f = 0; f < rxn.feedstockIds.length; f++) {
		if (needplus) {
		    nodes.push( {
			type: 'plus',
			name: 'plus',
			length: 50,
			height: 50
		    });
		    needplus = 0;
		}

		let rxc = rxn.feedstockIds[f];
		let cpd = results.cpds[rxc];
		if (!cpd) {
		    console.log('smiles error4!', rxc, f, rxn.feedstockIds[f]);
		    return;
		}

		if (!cpd.drawTree && !cpd.smilesError) {
		    SRV.DrawTreeInit(cpd, canvas);
		}
		
		let name = cpd.name;
		if (!name) {
		    name = rxc;
		}
		name = SRV.HtmlDecode(name);
		//name = rxc;
		
		let feedstock = route.feedstocks.find(e => e.cid == rxc);
		if (!feedstock)
		    console.log("can't locate feedstock", rxc);

		if (branchIds.find(id => id == rxc)) {
		    console.log("feedstock is a product!", f, rxc, name);
		}
		
		nodes.push( {
		    type: 'rxc',
		    cpd: cpd,
		    id: rxc,
		    name: name,
		    cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
		    purchasable: feedstock ? feedstock.purchasable : true,
		    length: cpd.length,
		    height: cpd.height,
		    rxctype: "feedstock"
		});
		needplus = 1;
	    }

	    let rxr = results.rxns[rxn.rxn_id];
	    if (!rxr) {
		console.log('invalid rxr!', rxn.rxn_id);
		return;
	    }

	    let name = rxnFixName(rxn.rxn_id);
	    nodes.push( {
		type: 'rxr',
		rxr: rxr,
		id: rxn.rxn_id,
		rxrnew: rxr.new,
		name: rxr.new ? rxr.className : name,
	    });
	    
	    let rxc = rxn.prod_id;
	    let cpd = results.cpds[rxc];
	    if (!cpd) {
		console.log('smiles error5!', rxn.prod_id);
		return;
	    }

	    if (!cpd.drawTree && !cpd.smilesError) {
		SRV.DrawTreeInit(cpd, canvas);
	    }
	    
	    name = cpd.name;
	    if (!name) 
		name = rxc;
	    name = SRV.HtmlDecode(name);
	    //name = rxc;

	    if (branchIds.find(id => id == rxc)) {
		//console.log("duplicate product!", s, rxc, name);
	    } else {
		//console.log("product new", s, rxc, name);
		branchIds.push(rxc);
	    }
	    
	    if (route.feedstocks.find(feed => feed.cid == rxc)) {
		console.log("produce is a feedstock!", s, rxc, name);
	    }
	    
	    nodes.push( {
		type: 'rxc',
		cpd: cpd,
		id: rxc,
		name: name,
		cdpnew: (rxc.indexOf('CNEW') == 0) ? true : false,
		length: cpd.length,
		height: cpd.height,
		rxctype: 'product'
	    });
	}
    }

    route.branchIds = branchIds;    
    
    canvas.results = results;
    canvas.route = route;
    canvas.segments = segments;

    SRV.InitIntermediates(segments);
    SRV.AddPurchasableSegments(canvas);
    for(let s in segments) {
	for(let n in segments[s]) {
	    let node = segments[s][n];
	    node.results = results;
	    SRV.NodeInit(node);
	}
    }

};

SRV.InitIntermediates = function(segments)
{
    let Inodes = [];
    
    for(let s in segments) {
	for(let n in segments[s]) {
	    let node = segments[s][n];
	    if (node.type != 'rxc') {
		continue;
	    }
	    
	    if (n == segments[s].length-1) {
		Inodes[s] = node;
	    } else {
		let i = Inodes.findIndex(function(inode) {
		    return inode.id == node.id;
		});
		if (i != -1) {
		    node.iname = 'I-' + (i+1);
		    node.inode = Inodes[i];
		    Inodes[i].iname = 'I-' + (i+1);
		}
	    }
	}
    }
    
    //console.log('intermediates', Inodes, segments);    
};

function FormatTime(timeInHours)
{
    if (parseInt(timeInHours) == timeInHours)
	return timeInHours;
    else
	return timeInHours.toFixed(1);
    
    /*
    let hours = parseInt(timeInHours);
    let min = parseInt(60 * (timeInHours - hours)).toString();

    if (min.length == 1)
	min = '0' + min;
    
    return hours + ':' + min;
    */
}

SRV.AddPurchasableSegments = function(canvas)
{
    for(let s in canvas.segments) {
	let segment = canvas.segments[s];
	for(var n in segment) {
	    let node = segment[n];
	    if (node.type != "rxc")
		continue;

	    if (node.cpd.em_catalogId || SRV.debugPurchasable) {
		let btn = document.createElement('IMG');
		btn.classList.add('purchaseBtn');
		btn.node = node;
		if (node.rxctype == 'feedstock') {
		    node.purchase = true;
		    btn.src = SRV.iconPriceTagDarkGreen;
		} else {
		    btn.src = SRV.iconPriceTagGray;
		    node.purchase = false;
		    btn.classList.add('purchasable');
		    btn.onclick = OnPurchase;
		}
		canvas.parentNode.appendChild(btn);
		canvas.parentNode.style.position = 'relative';
		node.purchaseBtn = btn;
	    }
	}
    }

    function OnPurchase(evt)
    {
	let btn = evt.target;
	//console.log("purchase", btn.node.name);

	let purchase = btn.node.purchase ? false : true;

	for(let s in canvas.segments) {
	    let segment = canvas.segments[s];
	    for(let n in segment) {
		let node = segment[n];
		node.disabled = false;
		if (node.id == btn.node.id) {
		    //console.log('toggle', node.name);
		    if (purchase)  {
			node.purchaseBtn.src = SRV.iconPriceTagGreen;
			node.purchase = true;
		    } else {
			node.purchaseBtn.src = SRV.iconPriceTagGray;
			node.purchase = false;
		    }
		}
	    }
	}

	for(let s = canvas.segments.length-1; s >= 0; s--) {
	    let segment = canvas.segments[s];
	    for(let n = segment.length-1; n > 0; n--) {
		let node = segment[n];
		if (node.purchase &&
		    node.rxctype &&
		    node.rxctype == 'product') {
		    DisableProduct(canvas.segments, s-1, node.name);
		    if (segment[n-1].type == 'rxr')
			DisableFragment(canvas.segments, s, n-1);
		}
	    }
	}


	canvas.draw(false);
    }

    function DisableFragment(segments, s, n)
    {
	//console.log("disable fragment", s, n);
	
	let segment = segments[s];
	for(; n > 0; n--) {
	    let node = segment[n];
	    node.disabled = true;
	    if (node.rxctype &&
		node.rxctype == 'product') {
		DisableProduct(segments, s-1, node.name);
	    }
	}
    }

    function DisableProduct(segments, s, name)
    {
	//console.log("disable product", s, name);
	for( ; s >= 0; s--) {
	    let segment = segments[s];
	    for(let n = segment.length-1; n > 0; n--) {
		let node = segment[n];
		if (node.name == name) {
		    node.disable = true;
		    if (segment[n-1].type == 'rxr') {
			DisableFragment(canvas.segments, s, n);
		    }
		}
	    }
	}
    }
    
}

SRV.AddKeepAvoid = function(canvas)
{
    let results = canvas.results;
    let targetId = results.routes[0].route_tree.prod_id;

    for(let s in canvas.segments) {
	let prev = null;
	for(let n = 0; n < canvas.segments[s].length; n++) {
	    let segment = canvas.segments[s];
	    let node = segment[n];
	    node.prev = prev;
	    node.next = (n < segment.length) ? segment[n+1] : null;
	    prev = node;
	    
	    switch(node.type) {
	    case 'rxc':
	    case 'rxr':
		let div = document.createElement('DIV');
		let html = "<div class='footer'>";
		let isnew = node.type == 'rxc' ? node.cdpnew : node.rxrnew;
		if (node.id != targetId && isnew == false)
		    html += "<button class='btnAvoid'>Avoid</button><button class='btnKeep'>Keep</button>";

		html += "</div>";
		div.innerHTML = html;

		div.classList.add('node');
		div.classList.add('hide');
		div.onclick = function(event) {
		    if (canvas.onClickReact) {

			let oldFrame = canvas.detailFrame;
			if (canvas.detailFrame) {
			    canvas.detailFrame.classList.remove('detailed');
			    canvas.detailFrame = null;
			    canvas.onClickReact(canvas.routeDetails);
			    if (oldFrame == node.frame)
				return;
			}
			
			switch(node.type) {
			case 'rxc': {
			    if (!node.details)
				node.details = SRV.CompoundDetails(node, results);
			    node.frame.classList.add('detailed');
			    canvas.detailFrame = node.frame;
			    canvas.onClickReact(node.details);
			    break;
			}
			case 'rxr': {
			    if (!node.details)
				node.details = SRV.ReactionDetails(node, results);
			    node.frame.classList.add('detailed');
			    canvas.detailFrame = node.frame;
			    canvas.onClickReact(node.details);
			    break;
			}
			default:
			    console.log('Invalid keep/avoid type', node);
			}

			if (oldFrame && oldFrame != canvas.detailFrame) {
			    if (oldFrame.classList.contains('keep') == false &&
				oldFrame.classList.contains('avoid') == false && 
				oldFrame.classList.contains('detailed') == false) 
				oldFrame.classList.add('hide');
			}
			    
			return StopEvent(event);
		    }			
		};

		div.onmouseleave = function() {
		    if (div.classList.contains('keep') == false &&
			div.classList.contains('avoid') == false && 
			div.classList.contains('detailed') == false) 
			div.classList.add('hide');
		    /*
		    let tooltip = document.getElementById('toolTip');
		    if (tooltip)
			tooltip.classList.add('hidden');
		    */
		};

		let keep = div.getElementsByClassName('btnKeep')[0];;
		node.keep = keep;
		if (keep) {
		    keep.onclick = function(event) {
			let frame = node.frame;
			if (frame.classList.contains('keep')) {
			    frame.classList.remove('keep');
			    if (SRV.keepList[node.id])
				delete SRV.keepList[node.id];
			    if (node.type == 'rxr') {
				for(let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
				    if (neighbor != node && neighbor.type == 'rxr')
					break;
				    if (neighbor.type == 'rxc') {
					neighbor.frame.classList.remove('keep');
					if (neighbor.frame != canvas.detailFrame)
					    neighbor.frame.classList.add('hide');
					if (SRV.keepList[neighbor.id])
					    delete SRV.keepList[neighbor.id];
					if (neighbor.keep)
					    neighbor.keep.classList.remove('hide');
					if (neighbor.avoid)
					    neighbor.avoid.classList.remove('hide');
				    }
				}
			    } 
			} else {
			    frame.classList.add('keep');
			    SRV.keepList[node.id] = true;
			    frame.classList.remove('avoid');
			    if (SRV.avoidList[node.id])
				delete SRV.avoidList[node.id];
			    if (node.type == 'rxr') {
				for(let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
				    if (neighbor != node && neighbor.type == 'rxr')
					break;
				    if (neighbor.type == 'rxc') {
					neighbor.frame.classList.add('keep');
					neighbor.frame.classList.remove('hide');
					SRV.keepList[neighbor.id] = true;
					neighbor.frame.classList.remove('avoid');
					if (SRV.avoidList[neighbor.id])
					    delete SRV.avoidList[neighbor.id];
					if (neighbor.keep)
					    neighbor.keep.classList.add('hide');
					if (neighbor.avoid)
					    neighbor.avoid.classList.add('hide');
				    }
				}
			    } 
			}
			console.log('keep/avoid', SRV.avoidList, SRV.keepList);

			/* disable toggling search again button
			let details = {
			    type: 'searchAgain',
			    enabled: (Object.keys(SRV.avoidList).length > 0 || Object.keys(SRV.keepList).length > 0) ? true :  false
			};
			canvas.onClickReact(details);
			*/
			return StopEvent(event);
		    };
		}
		let avoid = div.getElementsByClassName('btnAvoid')[0];
		node.avoid = avoid;
		if (avoid) {
		    avoid.onclick = function(event) {
			let frame = node.frame;
			if (frame.classList.contains('avoid')) {
			    frame.classList.remove('avoid');
			    if (SRV.avoidList[node.id])
				delete SRV.avoidList[node.id];
			    /*
			    if (node.type == 'rxr') {
				for(let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
				    if (neighbor != node && neighbor.type == 'rxr')
					break;
				    if (neighbor.type == 'rxc') {
					neighbor.frame.classList.add('hide');
					neighbor.frame.classList.remove('keep');
					if (SRV.keepList[neighbor.id])
					    delete SRV.keepList[neighbor.id];
					if (neighbor.keep)
					    neighbor.keep.classList.add('hide');
					if (neighbor.avoid)
					    neighbor.avoid.classList.add('hide');
				    }
				}
			    }
			    */
			} else {
			    frame.classList.add('avoid');
			    SRV.avoidList[node.id] = true;
			    if (node.type == 'rxr' && frame.classList.contains('keep')) {
				for(let neighbor = node.next; neighbor; neighbor = neighbor.prev) {
				    if (neighbor != node && neighbor.type == 'rxr')
					break;
				    if (neighbor.type == 'rxc') {
					if (neighbor.frame != canvas.detailFrame)
					    neighbor.frame.classList.add('hide');
					neighbor.frame.classList.remove('keep');
					if (SRV.keepList[neighbor.id])
					    delete SRV.keepList[neighbor.id];
					if (neighbor.keep)
					    neighbor.keep.classList.remove('hide');
					if (neighbor.avoid)
					    neighbor.avoid.classList.remove('hide');
				    }
				}
			    }
			    frame.classList.remove('keep');
			    if (SRV.keepList[node.id])
				delete SRV.keepList[node.id];
			}
			console.log('keep/avoid', SRV.avoidList, SRV.keepList);

			/* disable toggling search again button
			let details = {
			    type: 'searchAgain',
			    enabled: (Object.keys(SRV.avoidList).length > 0 || Object.keys(SRV.keepList).length > 0) ? true :  false
			};
			canvas.onClickReact(details);
			*/
			return StopEvent(event);
		    };
		}
		canvas.parentNode.appendChild(div);
		canvas.parentNode.style.position = 'relative';
		node.frame = div;
		break;
	    default:
	    }
	}
    }
};

SRV.ReactionDetails = function(node, results)
{
    let details = {
	type: 'reactionDetails',
	id: node.name,
	idLabel: node.rxrnew ? 'Transformation' : 'Id',
	source: node.rxrnew ? 'Computer Generated' : 'Literature',
	variation: [],
	results: results
    };
    let rxr = node.rxr;

    for(let v = 0; v < rxr.variations.length; v++) {
	let variation = {
	    yield: 'n/a',
	    yieldLabel: node.rxrnew ? 'Yield (estimated)' : 'Yield',
	    yieldId: 'n/a',	    
	    source: node.rxrnew ? 'Computer Generated' : 'Literature',
	    stages: []
	};

	let refs = [];
	if (!rxr.variations[v].references)
	    rxr.variations[v].references = [];
	if (rxr.variations[v].refScopuses.length > 0)
	    rxr.variations[v].references = rxr.variations[v].refScopuses;

	for(let r in rxr.variations[v].references) {
	    if (rxr.variations[v].references[r] != "") {
		var patent = rxr.variations[v].references[r];
		var base = patent.split('_')[0]
		refs.push({
		    href: patent,
		    url: "https://patents.google.com/patent/" + base + "/en=" + patent
		});
	    }
	}
	
	if (rxr.variations[v].comment) {
	    variation.comment =	rxr.variations[v].comment;
	    if (refs.length > 0)
		variation.title = "Procedure from: <a href='" + refs[0].url + "'>" + refs[0].href + "</a>";
	    else
		variation.title = "Procedure from: ";
	}
	
	if (rxr.variations[v].refDois) {
	    for(let r in rxr.variations[v].refDois) {
		if (rxr.variations[v].refDois[r] != "") {
		    refs.push({
			href: "DOI: " + rxr.variations[v].refDois[r],
			url: "https://doi.org/" + rxr.variations[v].refDois[r]
		    });
		}
	    }
	}
	variation.refs = refs;

	let product = (node.next && node.next.type == 'rxc') ? node.next.id : null;
	let yields = rxr.variations[v].yields;
	for(let y = 0; y < yields.length; y++) {
	    if (yields[y][0] == product) {
		variation.yield = (yields[y][1] * 100).toFixed(0) + '%';
	    }
	}

	let yieldIds = rxr.variations[v].yieldIds;
	for(let y = 0; y < yieldIds.length; y++) {
	    if (yieldIds[y][0] == rxr.products[0]) {
		variation.yieldId = yieldIds[0][1];
	    }
	}
	    
	for(let c = 0; c < rxr.variations[v].conditions.length; c++) {
	    let stage = {
		times: 'n/a',
		temps: 'n/a',
		solvents: 'n/a',
		reagents: 'n/a',
		catalysts: 'n/a',
		ph: 'n/a'
	    }

	    let times = rxr.variations[v].conditions[c].times;
	    if (times && times.length > 0) {
		switch(times.length) {
		case 1: 
		    stage.times = FormatTime(times[0]) + ' h'; 
		    break;
		case 2: 
		    stage.times = FormatTime(times[0]) + " - " + FormatTime(times[1]) + ' h';
		    break;
		case 3:
		    stage.times = FormatTime(times[0]) + " - " + FormatTime(times[2]) + ' h';
		    break;
		default:
		    console.log("Invalid time", times);
		}
	    }

	    let temps = rxr.variations[v].conditions[c].temps;
	    if (temps && temps.length > 0) {
		let degC = SRV.HtmlDecode('&nbsp;&#x2103');
		switch(temps.length) {
		case 1: 
		    stage.temps = temps[0] + degC;
		    break;
		case 2: 
		    stage.temps = temps[0] + " - " + temps[1] + degC;
		    break;
		case 3:
		    stage.temps = temps[0] + " - " + temps[2] + degC;
		    break;
		default:
		    console.log("Invalid temps", temps);
		}
	    }

	    let solvents = rxr.variations[v].conditions[c].solvents;
	    if (solvents && solvents.length > 0) {
		stage.solvents = [];
		for(let x = 0; x < solvents.length; x++) {
		    let cpd = node.results.cpds[solvents[x]];
		    if (!cpd || !cpd.name) {
			console.log('invalid solvent', x, solvents[x]);
			stage.solvents.push(solvents[x]);
		    } else {
			stage.solvents.push(cpd.name);
		    }
		}
	    }
	    
	    let reagents = rxr.variations[v].conditions[c].reagents;
	    if (reagents && reagents.length > 0) {
		stage.reagents = [];
		for(let x = 0; x < reagents.length; x++) {
		    let cpd = node.results.cpds[reagents[x]];
		    if (!cpd || !cpd.name) {
			console.log('invalid reagent', x, reagents[x]);
			stage.reagents.push(reagents[x]);
		    } else {
			stage.reagents.push(cpd.name);
		    }
		}
	    }
	    
	    let catalysts = rxr.variations[v].conditions[c].catalysts;
	    if (catalysts && catalysts.length > 0) {
		stage.catalysts = [];
		for(let x = 0; x < catalysts.length; x++) {
		    let cpd = node.results.cpds[catalysts[x]];
		    if (!cpd || !cpd.name) {
			console.log('invalid catalyst', x, catalysts[x]);
			stage.catalysts.push(catalysts[x]);
		    } else {
			stage.catalysts.push(cpd.name);
		    }
		}
	    }
	    
	    variation.stages.push(stage);
	}
	details.variation.push(variation);
    }

    return details;
};

SRV.CompoundDetails = function(node, results)
{
    let details = {
	type: 'compoundDetails',
	mol_cost: 'n/a',
	mol_weight: 'n/a',
	smiles: 'n/a',
	inchi: 'n/a',
	id: node.id,
	name: 'n/a',
	CompoundType: 'Literature',
	MCT: 'n/a',	
	results: results,
	bom: []
    };

    if (node.cpd.name)
	details.name = SRV.HtmlDecode(node.cpd.name);

    if (node.cpd.mol_cost)
	details.mol_cost = '$' + node.cpd.mol_cost.toFixed(2) + '/mmol';

    if (node.cpd.mono_weight)
	details.mol_weight = node.cpd.mono_weight.toFixed(2) + ' g/mmol';

    if (node.cpd.smiles)
	details.smiles = node.cpd.smiles;

    if (node.cpd.inchi) {
	let inchi = node.cpd.inchi;
	let x = inchi.indexOf('InChI=');
	if (x != -1)
	    inchi = inchi.substring(x+6);
	details.inchi = inchi;
    }

    if (node.cdpnew) {
	details.CompoundType = 'Computer Generated';
    }

    if (node.cpd.em_versionId) {
	details.bom[0] = {
	    label: 'eMolecules ID',
	    desc: node.cpd.em_versionId
	};
	details.bom[1] = {
	    label: 'Supplier',
	    desc: node.cpd.em_supplierName ? node.cpd.em_supplierName : "n/a"
	};
	details.bom[2] = {
	    label: 'Tier',
	    desc: node.cpd.em_tierNum ? node.cpd.em_tierNum : "n/a"
	};
	details.bom[3] = {
	    label: 'Price',
	    desc:
	     (node.cpd.em_price &&
	      node.cpd.em_amount &&
	      node.cpd.em_units) ?
		('$' + node.cpd.em_price + ' / ' + node.cpd.em_amount + ' ' + node.cpd.em_units) : 'n/a'
	};

    }

    return details;
};

SRV.IsPartialRoute = function(route)
{
    for(let f in route.feedstocks) {
	let rxc = route.feedstocks[f];
	if (rxc.purchasable == false)
	    return true;
    };

    return false;
}

SRV.RouteDetails = function(results, canvas, strategyId, routeId)
{
    let route = results.strategyRouteIndex['S' + strategyId + 'R' + routeId];
    if (!route) {
	alert("Can't locate route " + strategyId + " route " + routeId);
	return;
    }

    let details = {
	type: 'routeDetails',
	steps: route.nbrxns,
	maxsegment: route.depth,
	solventChanges: route.cost_solvent_exchanges.toFixed(0),
	solventCost: route.cost_solvents ? '$' + route.cost_solvents.toFixed(2) + '/mmol' : 'n/a',
	routeCost: (!route.isPartialRoute && route.cost) ? '$' + route.cost.toFixed(2) + '/mmol' : 'n/a',
	autosync: 'n/a',
	strategyId: strategyId,
	routeId: routeId,
	results: results,
	canvas: canvas
    };

    canvas.routeDetails = details;

    if (canvas.onClickReact)
	canvas.onClickReact(details);
};

SRV.DrawRoute = function(canvas, resize)
{
    if (!canvas.segments)
	return;

    let segments = canvas.segments;
    let height = SRV.LayoutSegments(canvas, segments);
    
    let ctx = canvas.getContext('2d');
    SRV.scaleDevice = DevicePixelRatio(ctx);

    if (resize) {
	canvas.style.width = '100%';
	canvas.style.height = height + 'px';
	canvas.width = canvas.clientWidth * SRV.scaleDevice;
	canvas.height = canvas.clientHeight * SRV.scaleDevice;
    }

    ctx.setTransform(1,0,0,1,0,0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.scale(SRV.scaleDevice, SRV.scaleDevice);

    SRV.DrawSegments(canvas, segments);
};

SRV.DrawSavedRoute = function(results, canvas, resize)
{
    //console.log("SRV.DrawSavedRoute", canvas.id, canvas.width, canvas.height, resize);
    
    let ctx = canvas.getContext('2d');
    SRV.scaleDevice = DevicePixelRatio(ctx);

    if (!results.routes[0]) {
	alert("Can't locate target!");
	return;
    }

    let rxc = results.routes[0].route_tree.prod_id;
    let cpd = results.cpds[rxc];

    if (!cpd) {
	console.log('smiles error6!', rxc);
	return;
    }
	
    if (!cpd.drawTree && !cpd.smilesError) {
	SRV.DrawTreeInit(cpd, canvas);
    }
    
    let xoff = 0;
    let yoff = 0;
    let xpad = 20;
    let ypad = 20;
    let scale = 1;
    
    ctx.save();
    ctx.setTransform(1,0,0,1,0,0);
//    ctx.fillStyle = "#00F";
//    ctx.fillRect(0, 0, canvas.width, canvas.height);    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    if (canvas.type.indexOf('recentCanvas') == 0) {
	//canvas.style.width = '100%';
	//canvas.style.height = '100%';
	//canvas.width = canvas.clientWidth * SRV.scaleDevice;
	//canvas.height = canvas.clientHeight * SRV.scaleDevice;
	let pad = 20;
	let sx = canvas.width / (cpd.length + pad);
	let sy = canvas.height / (cpd.height + pad);
	scale = Math.min(1, Math.min(sx, sy));
	xoff = (canvas.width - (cpd.length+pad)*scale)/2 + pad/2 * scale;
	yoff = (canvas.height - (cpd.height+pad)*scale)/2 + pad/2 * scale;
/*
	console.log('scale', sx, sy, scale,
		    'off', xoff, yoff,
		    'clientWidth', canvas.clientWidth,
		    'canvasWidth', canvas.width,
		    'cpdLength', cpd.length,
		    SRV.scaleDevice);
*/
    } else {
	canvas.style.width = '100%';
	canvas.style.height = cpd.height+ypad + 'px';
	canvas.width = canvas.clientWidth * SRV.scaleDevice;
	canvas.height = canvas.clientHeight * SRV.scaleDevice;
	ctx.scale(SRV.scaleDevice, SRV.scaleDevice);
	xoff = (canvas.clientWidth - cpd.length)/2;
	yoff = (canvas.clientHeight - cpd.height)/2;
    }

    xoff = parseInt(xoff) + .5; // anti-aliasing
    yoff = parseInt(yoff) + .5;

    ctx.translate(xoff, yoff);
    ctx.scale(scale, scale);
    let tree = cpd.drawTree;
    if (tree) {
	tree.canvasWrapper.redraw(canvas);
     } else {
	SRV.DrawNoStructure(canvas, cpd);
    }
    
/*
    ctx.strokeStyle = '#ff0000';
    ctx.strokeRect(0,0, cpd.length, cpd.height);
*/
    ctx.restore();

    canvas.onclick = function(event) {
	if (canvas.onClickReact)
	    canvas.onClickReact(results);
    }

};

SRV.CanvasMount = function(canvas, strategyId, routeId, type, results, onClickReact)
{
    //console.log('SRV.CanvasMount', SRV.canvasCount+1, strategyId, routeId, type);

    if (SRV.smilesDrawer == null)
	SRV.Init();

    canvas.uid = ++SRV.canvasCount;
    canvas.type = type;
    canvas.results = results;
    canvas.strategyId = strategyId;
    canvas.routeId = routeId;
    canvas.classList.add(type);
    canvas.onClickReact = onClickReact;

    switch(type) {
    case 'strategyCanvas':
	SRV.DrawStrategyInit(results, canvas, strategyId);
	canvas.draw = function(resize) {
	    SRV.DrawStrategy(canvas, resize);
	};
	break;
    case 'targetCanvas':
	canvas.draw = function(resize) {
	    SRV.DrawTarget(results, canvas, resize);
	};
	break;
    case 'routeCanvas':
	SRV.DrawRouteInit(results, canvas, strategyId, routeId);
	canvas.draw = function(resize) {
	  SRV.DrawRoute(canvas, resize);
	};
	break;
    case 'refineCanvas':
	SRV.DrawRouteInit(results, canvas, strategyId, routeId);
	SRV.AddKeepAvoid(canvas);
	SRV.RouteDetails(results, canvas, strategyId, routeId);
	canvas.draw = function(resize) {
	  SRV.DrawRoute(canvas, resize);
	};
	break;
    case 'recentCanvas':
    case 'savedCanvas':
	SRV.GetStrategyRouteCounts(results); 
	canvas.draw = function(resize) {
	    SRV.DrawSavedRoute(results, canvas, resize);
	};
	break;
    case 'flaggedCanvas':
	SRV.DrawFlaggedInit(results, canvas);
	break;
    default:
	console.log('Invalid canvas type', type);
	return;
    }

    canvas.draw(true);
    canvas.drawn = true;

    function ToggleFrame(node, enable)
    {
	let frame = node.frame;
	if (enable) {
	    frame.classList.remove('hide');
	} else {
	    if (frame.classList.contains('hide') == false &&
		frame.classList.contains('keep') == false &&
		frame.classList.contains('avoid') == false &&
		frame.classList.contains('detailed') == false) { 
		frame.classList.add('hide');
	    }
	}
    }
    
    function OnMouseMove(evt)
    {
	let segments = canvas.segments;
	if (!segments)
	    return;

	let focusNode = null;

	//turn off all frames, find focus
	for(let s = 0; s < segments.length; s++) {
	    let nodes = segments[s];
	    let btn = nodes[0].purchaseBtn;
	    let hide = (btn && btn.checked) ? true : false;
	    
	    for(let n = 0; n < nodes.length; n++) {
		let node = nodes[n];
		if (node.frame) {
		    let x = evt.offsetX;
		    let y = evt.offsetY;
		    let frame = node.frame;

		    if (x >= frame.left &&
			y >= frame.top &&
			x <= frame.right &&
			y <= frame.bottom &&
			!hide) {
			focusNode = node;
		    }
		    ToggleFrame(node, false);
		}
	    }
	}

	if (focusNode) {
	    if (focusNode.iname) {
		for(let s = 0; s < segments.length; s++) {
		    let nodes = segments[s];
		    for(let n = 0; n < nodes.length; n++) {
			let inode = nodes[n];
			if (inode.iname == focusNode.iname)
			    ToggleFrame(inode, true);
		    }
		}
	    } else {
		ToggleFrame(focusNode, true);
	    }
	}
    }
    canvas.addEventListener('mousemove', OnMouseMove, false);

    return;
/*
    let ctx = canvas.getContext('2d');
    let dragStart = null;
    let dragged = false;
    let rxTip = null;
    let j=0;
    
    function OnMouseDown(evt)
    {
	var px = EventCoords(evt, SRV.scaleDevice);
	dragStart = ctx.transformedPoint(px.x, px.y);
	dragged = false;
	return StopEvent(evt);
    }
    

    function OnMouseMove(evt)
    {
	let px = EventCoords(evt, SRV.scaleDevice);
	let pt = ctx.transformedPoint(px.x, px.y);
	let tip = null;

	if (evt.buttons == 0) {
	    for(let R=0; R < SRV.route.length; R++) {
		let route = SRV.route[R];
		for(let r=0; r < route.length; r++) {
		    let rx = route[r];
		    let x1 = rx.xoff;
		    let y1 = rx.yoff;
		    let x2 = x1 + (rx.maxX - rx.minX);
		    let y2 = y1 + (rx.maxY - rx.minY);
		    if (pt.x > x1 &&
			pt.y > y1 &&
			pt.x < x2 &&
			pt.y < y2) {
			tip = rx;
			break;
		    }
		}
	    }
	} else {
	    dragged = true;
	    if (dragStart){
		ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
		Draw(SRV.route);
	    }
	}

	let tipHtml = '';
	if (tip && tip.id == 'RXC') {
	    tipHtml = '<b>RXC:</b><br>' + tip.smiles + "<br>...<br><br>";
	} else if (tip && tip.id == 'RXR') {
	    tipHtml = '<b>RXR:</b><br>' + tip.label + "<br>...<br><br>";
	} else {
	    tip = null;
	}
	
	if (rxTip != tip) {
	    let bg = '#ccc';
	    let highlight = '#000';
	    let s = SRV.scaleCanvas;
	    if (rxTip) {
		Draw(SRV.route);//drawBox(ctx, rxTip, bg);
	    }
	    rxTip = tip;
	    let tooltip = document.getElementById('toolTip');
	    if (rxTip) {
		drawBox(ctx, rxTip, highlight);
		tooltip.innerHTML = tipHtml;
		tooltip.style.display = 'inline';
	    } else {
		let tip = document.getElementById('toolTip');
		tip.style.display = 'none';
	    }
	}

	if (rxTip) {
	    let tooltip = document.getElementById('toolTip');	    
	    tooltip.style.left = px.x + 20 + 'px';
	    tooltip.style.top = px.y - 20 + 'px';
	}
	return StopEvent(evt);
    }


    function OnMouseUp(evt)
    {
	dragStart = null;

	return StopEvent(evt);
    }


    function OnMouseScroll(evt)
    {
	var delta = -evt.deltaY;

	if (delta > 0)
	    delta = 1;
	else if (delta < 0)
	    delta = -1;
	else
	    return;
	
//	var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
    
	if (delta) {
	    let px = EventCoords(evt, SRV.scaleDevice);
	    let scaleFactor = 1.02;
	    let factor = Math.pow(scaleFactor, delta);
	    let scale = SRV.scaleCanvas * factor;
px.x = 0;
px.y = 0;
	    let pt = ctx.transformedPoint(px.x, px.y);

	    SRV.scaleCanvas = scale;

	    ctx.setTransform(1,0,0,1,0,0);
	    ctx.scale(scale, scale);
	    ctx.translate(-pt.x, -pt.y);
	    ctx.translate(px.x/scale, px.y/scale);
	    Draw(SRV.route);
	}
    

	return StopEvent(evt);
    }
*/
    /*
    function OnMouseScroll(evt) { console.log('scroll'); }
    function OnMouseDown(evt) { console.log('down', canvas); }
    function OnMouseUp(evt) { console.log('up'); }
    function OnMouseMove(evt) { console.log('move'); }

    canvas.addEventListener('DOMMouseScroll', OnMouseScroll, false);
    canvas.addEventListener('mousewheel', OnMouseScroll,false);
    canvas.addEventListener('mousedown', OnMouseDown, false);
    canvas.addEventListener('mousemove', OnMouseMove, false);
    canvas.addEventListener('mouseup', OnMouseUp, false);
    */
/*
    function OnMouseDown(evt)
    {
	console.log('OnMouseDown', this, this.onClickReact);
	if (this.onClickReact) {
	    if (this.onClickReact) {
		let arg = {
		    type: "compound",
		    description: "76 reactions produced this compound",
		    purchaseOptions: [
			"Apollo Scientific $1.40/g",
			"Chem-Imprex $3.20/g",
			"Combi-Blocks $2.80/g",
			"TCI America $0.77/g"
		    ]
		};
		this.onClickReact(arg);
	    }
	}
	    
	return StopEvent(evt);
    }
*/    
//    canvas.addEventListener('mousedown', OnMouseDown, false);
};

SRV.CanvasUnmount = function(canvas)
{
    //console.log('SRV.CanvasUnmount', canvas.uid);

    canvas.onclick = null;
    canvas.redraw = null;

    for(let s in canvas.segments) {
	for(let n in canvas.segments[s]) {
	    let frame = canvas.segments[s][n].frame;
	    if (frame) {
		frame.remove();
		//console.log(s, n, frame);
	    }
	}
    }
};

SRV.DetailsClosed = function()
{
    let frames = document.getElementsByClassName('detailed');
    if (frames && frames[0])  {
	let frame = frames[0];
	frame.classList.remove('detailed');
	if (frame.classList.contains('keep') == false &&
	    frame.classList.contains('avoid') == false)
	    frame.classList.add('hide');
    }
};

function draw_arrow(ctx, xtail, ytail, xhead, yhead, headLength, headWidth, fromHead, toHead)
{
    var s = 1, x0=0, y0=0;
    xhead = (xhead-x0)*s;
    yhead = (yhead-y0)*s;
    xtail = (xtail-x0)*s;
    ytail = (ytail-y0)*s;
    headLength *= s;
    headWidth *= s;

    var dx = xhead - xtail;
    var dy = yhead - ytail;
    var theta = Math.atan2(dy, dx);
    var len = Math.sqrt(dx*dx + dy*dy);
    ctx.save();
    ctx.translate(xhead, yhead);
    ctx.rotate(theta);

    var xbase = -headLength;
    var ybase = 0;
    xhead = 0;
    yhead = 0;
    if (fromHead)
	xtail = -len + headLength;
    else
	xtail = -len;
    ytail = 0;
    xbase = -headLength;
    ybase = 0;
    var x1 = xbase;
    var y1 = headWidth/2;
    var x2 = xhead;
    var y2 = yhead;
    var x3 = xbase;
    var y3 = -headWidth/2;

//    ctx.strokeStyle = '#000';
    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.moveTo(xtail, ytail);
    ctx.lineTo(xbase, ybase);
    ctx.stroke();

    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x3, y3);
    ctx.fill();

    ctx.restore();
};

function draw_line(ctx, x1, y1, x2,y2)
{
//    ctx.strokeStyle = '#000';
    ctx.lineWidth = 3;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.lineWidth = 1;
}

function DevicePixelRatio(ctx)
{
    var devicePixelRatio = window.devicePixelRatio || 1;
    var backingStoreRatio = ctx.webkitBackingStorePixelRatio ||
	ctx.mozBackingStorePixelRatio ||
	ctx.msBackingStorePixelRatio ||
	ctx.oBackingStorePixelRatio ||
	ctx.backingStorePixelRatio || 1;
    var ratio = devicePixelRatio / backingStoreRatio;

    return ratio;
};

function EventCoords(evt, scaleDevice)
{
    var bounds = evt.target.getBoundingClientRect();
    var x = evt.clientX - bounds.left;
    var y = evt.clientY - bounds.top;

    x *= scaleDevice;
    y *= scaleDevice;

    return {x: x, y: y};
};

function StopEvent(evt)
{ 
    if (evt.preventDefault != undefined)
        evt.preventDefault();

    if (evt.stopPropagation != undefined)
	evt.stopPropagation();

    return false;
};

// Adds ctx.getTransform() - returns an SVGMatrix
// Adds ctx.transformedPoint(x,y) - returns an SVGPoint
function trackTransforms(ctx){
    var svg = document.createElementNS("http://www.w3.org/2000/svg",'svg');
    var xform = svg.createSVGMatrix();
    ctx.getTransform = function(){ return xform; };

    var savedTransforms = [];
    var save = ctx.save;
    ctx.save = function(){
        savedTransforms.push(xform.translate(0,0));
        return save.call(ctx);
    };
    
    var restore = ctx.restore;
    ctx.restore = function(){
        xform = savedTransforms.pop();
        return restore.call(ctx);
    };

    var scale = ctx.scale;
    ctx.scale = function(sx,sy){
        xform = xform.scaleNonUniform(sx,sy);
        return scale.call(ctx,sx,sy);
    };
    
    var rotate = ctx.rotate;
    ctx.rotate = function(radians){
        xform = xform.rotate(radians*180/Math.PI);
        return rotate.call(ctx,radians);
    };
    
    var translate = ctx.translate;
    ctx.translate = function(dx,dy){
        xform = xform.translate(dx,dy);
        return translate.call(ctx,dx,dy);
    };
    
    var transform = ctx.transform;
    ctx.transform = function(a,b,c,d,e,f){
        var m2 = svg.createSVGMatrix();
        m2.a=a; m2.b=b; m2.c=c; m2.d=d; m2.e=e; m2.f=f;
        xform = xform.multiply(m2);
        return transform.call(ctx,a,b,c,d,e,f);
    };
    
    var setTransform = ctx.setTransform;
    ctx.setTransform = function(a,b,c,d,e,f){
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx,a,b,c,d,e,f);
    };
    
    var pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x,y){
        pt.x=x; pt.y=y;
        return pt.matrixTransform(xform.inverse());
    }
};

SRV.resizeTimer = 0;

SRV.OnRedraw = function(event)
{
    let t0 = performance.now();
    let ndrawn = 0;
    let nskipped = 0;

    let minredraw = (event) ? true : false;
    
    let canvases = document.getElementsByTagName('canvas');
    for(let c = 0; c < canvases.length; c++) {
	let canvas = canvases[c];
	if (!canvas.draw)
	    continue;
	let ctx = canvas.getContext('2d');
	SRV.scaleDevice = DevicePixelRatio(ctx);
	let width = canvas.clientWidth * SRV.scaleDevice;
	let height = canvas.clientHeight * SRV.scaleDevice; 
	
	let resize = true;//canvas.width != width || canvas.height != height;
	let rect = canvas.getBoundingClientRect();
/*
	console.log(c, canvas.type,
		    canvas.uid,
		    'top', canvas.offsetTop,
		    'scroll', window.scrollY,
		    'winh', window.innerHeight,
		    'height', height,
		   rect);
*/
	if ( (!minredraw || rect.top < window.innerHeight) && (rect.bottom > 0)) {
	    if (resize || !canvas.drawn) {
		ndrawn++;
		canvas.draw(resize);
		canvas.drawn = true;
	    }
	} else {
	    //console.log('skipped', c, canvas.type, canvas.uid);
	    nskipped++;
	    canvas.drawn = false;
	} 
    }
    let t1 = performance.now();
    //console.log('scroll', minredraw, ndrawn, nskipped, parseInt(t1-t0));

    if (minredraw) {
	if (SRV.resizeTimer)
	    clearTimeout(SRV.resizeTimer);
	SRV.resizeTimer = setTimeout(function() {
	    //console.log('final redraw');
	    SRV.OnRedraw(null);
	}, 800);
    }
	    
};

function OnClickReactTmp(evt, canvas, details)
{
    let tooltip = document.getElementById('toolTip');
    if (!tooltip) {
	tooltip = document.createElement('DIV');
	tooltip.id = 'toolTip';
	tooltip.classList.add("hidden");
	tooltip.classList.add("arrow_box_left");
	tooltip.classList.add("tooltip");
	console.log(tooltip);
	document.body.appendChild(tooltip);
    }
    

    let tipHtml = '<table>';
    let keys = Object.keys(details);
    for(let k in keys) {
	tipHtml += "<tr><td><b>"+ keys[k] + "</b></td><td>" + details[keys[k]] + "</td></tr>";
    }
    tipHtml += "</table>";
    tooltip.innerHTML = tipHtml;

    let x = evt.pageX + 20;
    let y = evt.pageY - tooltip.clientHeight/2;
    tooltip.style.left = x + 'px';
    tooltip.style.top = y + 'px';
    tooltip.classList.remove('hidden');

}

var htmlDecodeMap = {};

SRV.HtmlDecode = function(html)
{
    if (htmlDecodeMap[html]) {
	return htmlDecodeMap[html];
    }
    
    let converter= document.getElementById('htmlConverter');
    if (!converter) {
        converter = document.createElement('div');
	converter.id = 'htmlConverter';
	converter.style.display = 'none';
	document.body.appendChild(converter);
    }
    converter.innerHTML = html;
    let text = converter.innerText;
    htmlDecodeMap[html] = text;
    return text;
};


SRV.OnAutoCompleteHover = function(evt, rxcId, rowIndex)
{
    let tooltip = document.getElementById('autoTip');
    if (!tooltip) {
	tooltip = document.createElement('div');
	tooltip.id = 'autoTip';
	tooltip.classList.add("hidden");
	tooltip.classList.add("arrow_box_left");
	tooltip.classList.add("tooltip");
	tooltip.innerHTML = "<canvas id='targetPreview'>";
	document.body.appendChild(tooltip);
    }

    if (evt.type == 'mouseout' || !rxcId) {
	tooltip.classList.add('hidden');
	return;
    }
    
    let a = evt.target;
    if (evt.target.tagName == 'DIV' &&
	evt.target.children.length == 1)
	a = evt.target.children[0];
    if (a.tagName != 'A')
	return;
    
    let bbox = a.getBoundingClientRect();
    if (evt.x < bbox.left ||
	evt.y < bbox.top ||
	evt.x > bbox.right ||
	evt.y > bbox.bottom) {
	tooltip.classList.add('hidden');
	return;
    }

    let results = {
	cpds : {
	}
    }
    let smiles = "";
    if (rowIndex >= 0 && rowIndex < Utils.autocomplete.cpd_smiles.length)
	smiles = Utils.autocomplete.cpd_smiles[rowIndex];
    
    results.cpds[rxcId] = {
	smiles: smiles
    };
    
    let canvas = tooltip.children[0];
    
    SRV.DrawRXC(rxcId, results, canvas, 1, 300);

    let x = evt.pageX + 30;
    let y = evt.pageY - tooltip.clientHeight/2;
    tooltip.style.left = x + 'px';
    tooltip.style.top = y + 'px';
    tooltip.classList.remove('hidden');

    return smiles;
}

//debugging functions
function OnKey(evt) {
    
    function DebugPurchasable(evt)
    {
	let canvas = document.querySelector('.refineCanvas');
	console.log('key', evt.key, canvas);
	if (!canvas)
	    return;
	let btns = canvas.parentNode.querySelectorAll('.purchaseBtn');
	console.log(btns);
	btns.forEach(b => {
	    console.log(b);
	    b.remove();
	});

	SRV.debugPurchasable =  SRV.debugPurchasable ? false : true;
	SRV.AddPurchasableSegments(canvas);
	canvas.draw(false);
    }

    switch(evt.key) {
    case 'p':
	//	return DebugPurchasable(evt);
	break;
    default:
    }
}
window.addEventListener('keydown', OnKey, false);

window.addEventListener('resize', SRV.OnRedraw);
window.addEventListener('printredraw', SRV.OnRedraw); // custom event for redrawing in print

console.log("SRV version", SRV.version);


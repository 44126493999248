/**
 * Styled search option to wrap around given children
 *
 */

import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  font-family: ${props => props.theme.primaryFont};
  font-size: ${props => props.theme.smallBodyFontSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width};
  border-bottom: ${props => `2px solid ${props.theme.secondaryBgColor}`};
  margin: 5px 0 5px 0;
  padding-bottom: 2px;
`;
// TODO: Revisit width within the context of SearchPage options

const StyledInputLabel = styled.label`
  margin-right: 5px;
`;

const SearchOption = props => (
  <InputContainer width={props.width}>
    <StyledInputLabel>{props.label}</StyledInputLabel>
    <div {...props}>{props.children}</div>
  </InputContainer>
);

SearchOption.defaultProps = {
  width: '100%'
};

export default SearchOption;

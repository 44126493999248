import { connect } from 'react-redux';
import StrategiesPage from './StrategiesPage';
import {
  fetchStrategies,
  updateChosenStrategy,
  updateFlagged
} from '../../actions';

const mapStateToProps = state => ({
  user: state.user,
  options: state.chosen.chosenSearchOption,
  strategies: state.strategies
});

const mapDispatchToProps = dispatch => {
  return {
    onStrategyChosen: (results, strategyId) => {
      dispatch(updateChosenStrategy(results, strategyId));
    },
    onSearch: (url, options, history, authToken) =>
      dispatch(fetchStrategies(url, options, history, authToken)),
    updateFlagged: flagged => dispatch(updateFlagged(flagged))
  };
};

const StrategiesPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StrategiesPage);

export default StrategiesPageContainer;

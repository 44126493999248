import { connect } from 'react-redux';
import StrategyPage from './StrategyPage';
import {
  updateChosenRouteId,
  fetchStrategies,
  createNewProject,
  addToSavedRoute,
  updateSavedRoutes,
  updateFlagged
} from '../../actions';

const mapStateToProps = state => ({
  user: state.user,
  options: state.chosen.chosenSearchOption,
  strategyId: state.chosen.chosenStrategyId,
  results: state.chosen.results,
  strategies: state.strategies
});

const mapDispatchToProps = dispatch => {
  return {
    onRouteChosen: id => {
      dispatch(updateChosenRouteId(id));
    },
    onSearch: (url, options, history, authToken) =>
      dispatch(fetchStrategies(url, options, history, authToken)),
    onCreateNewProject: (name, desc, user) =>
      dispatch(createNewProject(name, desc, user)),
    addToSavedRoute: saveRoute => dispatch(addToSavedRoute(saveRoute)),
    updateSavedRoutes: savedRoutes => dispatch(updateSavedRoutes(savedRoutes)),
    updateFlagged: flagged => dispatch(updateFlagged(flagged))
  };
};

const StrategyPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StrategyPage);

export default StrategyPageContainer;

import { connect } from 'react-redux';
import App from './App';
import { logout, fetchUser } from '../../actions/userActions';

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    fetchUser: (username, password, authToken) =>
      dispatch(fetchUser(username, password, authToken))
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;

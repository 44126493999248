import React, { Component } from 'react';
import StrategyPanel from '../../components/StrategyPanel';
import { MAX_ROUTE_OPTIONS } from '../../constants/constants';
import SearchOptionStrategy from '../../components/SearchOptionStrategy';
import { isEmpty, isEqual } from 'lodash';
import { SRV } from '../../utils/RouteVisualization';
import Canvas from '../../components/Canvas';
import FlagEmailPopup from '../../components/FlagEmailPopup';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import { Utils } from '../../utils/utils.js';
import SaveRoutePanel from '../../components/SaveRoutePanel';
import Loading from '../../assets/images/loading.gif';
import { STRINGS } from '../../constants/strings';
import { config } from '../../config';

export default class StrategiesPage extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.routeClicked = this.routeClicked.bind(this);
    this.buildRoutePanels = this.buildRoutePanels.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.toggleSavePanel = this.toggleSavePanel.bind(this);
    this.onFlagClick = this.onFlagClick.bind(this);

    this.state = {
      routeMode: false,
      isSavePanelOpen: false,
      isSearching: false,
      authToken: '',
      routeId: null,
      flagEmailShow: false
    };
  }

  componentDidMount() {
    const { options, results } = this.props;

    if (isEmpty(options) || isEmpty(results)) {
      console.log('options empty, returning to search page');
      this.props.history.push('/search');
    }

    this.setState({
      literatureDerivedReactions: options.literatureDerivedReactions,
      computerGeneratedReactions: options.computerGeneratedReactions,
      maxRoute: options.maxRoute,
      maxReactionSteps: options.maxReactionSteps,
      maxReagentCost: options.maxReagentCost,
      autoSYNCompatible: options.autoSYNCompatible,
      authToken: this.props.user.authToken
    });

      this.props.user.breadcrumb.Update(this.props.results, this.props.strategyId, -1);
  }

  // Check if prop updated, mainly strategies
  componentDidUpdate(prevProps, prevState) {
    const { options, strategies } = this.props;
    // only update if strategies changed
    if (strategies.errorMsg !== '' && this.state.isSearching) {
      this.setState({
        isSearching: false,
        errorMsg: strategies.errorMsg
      });
    }

    // update search option
    if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({
        literatureDerivedReactions: options.literatureDerivedReactions,
        computerGeneratedReactions: options.computerGeneratedReactions,
        maxRoute: options.maxRoute,
        maxReactionSteps: options.maxReactionSteps,
        maxReagentCost: options.maxReagentCost,
        autoSYNCompatible: options.autoSYNCompatible
      });
    }
  }

  onFlagClick(routeId) {
    let flagEmailShow = !this.state.flagEmailShow;

    if (!flagEmailShow || isNaN(routeId)) {
	this.setState({
	    flagEmailShow: false,
	    desc: '',
	    details: ''
	});
	return;
    }
    let options = this.props.options;
    let strategyId = this.props.strategyId;
    let canvasId = 'routeCanvasS' + strategyId + 'R' + routeId;      

    let details = {
      type: 'strategyRouteDetails',
      searchOptions: options,
      strategyId: strategyId,
      routeId: routeId,
      results: this.props.results,
      canvas: document.getElementById(canvasId)
    };
    let body =
      '<b>Strategy ' + (strategyId+1) + ' Route ' + (routeId+1) + '</b>\n\n' +	 
      'Search options: \n' +
      '\tMax Routes: ' +
      options.maxRoute.value +
      '\n' +
      '\tMax Cost: ' +
      options.maxReagentCost.value +
      '\n' +
      '\tMax Steps: ' +
      options.maxReactionSteps.value +
      '\n' +
      '\tAutoSyn Compatible: ' +
      options.autoSYNCompatible +
      '\n' +
      '\tLiterature Derived: ' +
      options.literatureDerivedReactions +
      '\n' +
      '\tComputer Generated: ' +
      options.computerGeneratedReactions +
      '\n';

    this.setState({
      flagEmailShow: flagEmailShow,
      desc: flagEmailShow ? body : '',
      details: flagEmailShow ? details : ''
    });
  }

  onSearchClick() {
    let searchStr = this.props.options.searchTarget;

    let options = {
      maxRoute: this.state.maxRoute,
      maxReactionSteps: this.state.maxReactionSteps,
      literatureDerivedReactions: this.state.literatureDerivedReactions,
      maxReagentCost: this.state.maxReagentCost,
      autoSYNCompatible: this.state.autoSYNCompatible,
      computerGeneratedReactions: this.state.computerGeneratedReactions,
      searchTarget: searchStr
    };

    let url = Utils.BuildSearchURL(options);
    if (url == null) {
      alert(
        'Must selection either Literature Derived or Computer Generated reactions or both'
      );
      return;
    }
    this.setState({
      isSearching: true
    });

    this.props.onSearch(
	url,
	options,
	this.props.history,
	this.props.user.tokenGW, //authToken
    );
  }

    routeClicked(id) {
    this.props.onRouteChosen(id);
    this.props.history.push('/route');
  }

  buildRoutePanels(results, strategyId) {
    let errors = SRV.GetSearchErrors(results);
    if (errors) {
      alert(errors);
      return;
    }

    const { strategies } = this.props;

    let strategyCounts = SRV.GetStrategyRouteCounts(results);

    const panels = [];

    for (let r = 0; r < strategyCounts[strategyId]; r++) {
	let desc = SRV.GetRouteDesc(results, strategyId, r);

      panels.push(
        <StrategyPanel
          key={strategies.searchCount + '-route-' + (r + 1)}
          title={'Route ' + (r + 1)}
          description={desc}
          strategyId={strategyId}
          routeId={r}
          results={results}
          onSaveClick={() => this.toggleSavePanel(r)}
          onOpenClick={() => this.routeClicked(r)}
          onFlagClick={() => this.onFlagClick(r)}
        />
      );
    }
    return panels;
  }

  handleChange(event, meta) {
    handleOptionChange(this, event, meta);
  }

  // toggle savepanel and also update routeId
  toggleSavePanel(routeId) {
    let isSavePanelOpen = !this.state.isSavePanelOpen;

    this.setState({
      isSavePanelOpen: isSavePanelOpen,
      routeId: isSavePanelOpen ? routeId : undefined
    });
  }

  render() {
    const {
      options,
      strategyId,
      results,
      onCreateNewProject,
      user,
      updateSavedRoutes,
      updateFlagged
    } = this.props;

    if (isEmpty(options) || isEmpty(results)) {
      return null;
    }

    let panels = results ? this.buildRoutePanels(results, strategyId) : [];
    let description = SRV.GetStrategyDesc(results, strategyId);
    let title = 'Strategy ' + (strategyId + 1);

    return (
      <div className="strategy-page">
        {this.state.isSavePanelOpen && (
          <SaveRoutePanel
            results={results}
            routeId={this.state.routeId}
            strategyId={strategyId}
            options={options}
            user={user}
            authToken={this.state.authToken}
            onCloseClick={this.toggleSavePanel}
            onCreateNewProjectClick={onCreateNewProject}
            updateSavedRoutes={updateSavedRoutes}
            projects={user.projects}
          />
        )}
        {this.state.flagEmailShow && (
          <div className="search-notification-container">
            <FlagEmailPopup
              type="flagged"
              label={STRINGS.flagEmailHeader}
              subject="Route"
              sendTo={config.emailFlag}
              cancelClick={this.onFlagClick}
              desc={this.state.desc}
              user={user}
              updateFlagged={updateFlagged}
              details={this.state.details}
              placeholder={'Please describe what is wrong with this Route'}
            />
          </div>
        )}
        {this.state.isSearching && (
          <div className="search-notification-container">
            <img src={Loading} alt={STRINGS.searching} />
            <h3 className="search-notification">{STRINGS.searching}</h3>
          </div>
        )}
        <div className="strategy-contents">
          <div className="strategy-left">
            <div className="strategy-jsme">
              <h3>{STRINGS.targetCompound}</h3>
              {<Canvas id={0} results={results} type="targetCanvas" />}
            </div>
            <SearchOptionStrategy
              onChange={this.handleChange}
              onSearch={this.onSearchClick}
              maxRouteValue={
                this.state.maxRoute ? this.state.maxRoute : options.maxRoute
              }
              maxRouteOptions={MAX_ROUTE_OPTIONS}
              maxReactionStepsValue={
                this.state.maxReactionSteps
                  ? this.state.maxReactionSteps
                  : options.maxReactionSteps
              }
              maxReagentCostValue={
                this.state.maxReagentCost
                  ? this.state.maxReagentCost
                  : options.maxReagentCost
              }
              autoSYNCompatibleValue={
                this.state.autoSYNCompatible !== undefined
                  ? this.state.autoSYNCompatible
                  : options.autoSYNCompatible
              }
              literatureDerivedReactionsValue={
                this.state.literatureDerivedReactions !== undefined
                  ? this.state.literatureDerivedReactions
                  : options.literatureDerivedReactions
              }
              computerGeneratedReactionsValue={
                this.state.computerGeneratedReactions !== undefined
                  ? this.state.computerGeneratedReactions
                  : options.computerGeneratedReactions
              }
            />
          </div>
          <div className="strategy-right">
            {this.props.strategies.errorMsg !== '' && (
              <div className="strategy-options-error">
                {this.props.strategies.errorMsg}
              </div>
            )}
            {this.props.strategies.errorMsg === '' && (
              <div>
                <div className="strategy-route-top">
                  <div className="strategy-route-label">
                    <strong>{title}</strong>
                    <div>{description[0]}
		      <span style={{color:'red'}}>{description[1]}</span>
		    </div>
                  </div>
                  <Canvas
                    strategyId={strategyId}
                    routeId={0}
                    results={results}
                    type="strategyCanvas"
                  />
                </div>
		{panels}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

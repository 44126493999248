/**
 * Button with custom styling, could add more color variations in StyledButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.isSquare ? '0' : '0.6em')};
  color: ${props => props.fontColor};
  font-weight: 700 !important;
  cursor: ${props => (props.disable ? 'default' : 'pointer')};
  border: none !important;

  background-color: ${props =>
    (props.color === 'lightBlue' && props.theme.lightBlue) ||
    (props.color === 'darkBlue' && props.theme.darkBlue) ||
    (props.color === 'yellow' && props.theme.yellow) ||
    (props.color === 'red' && props.theme.red)};

  img {
    width: ${props => props.width};
    height: ${props => props.height};
    filter: opacity(${props => (props.disable ? '0.5' : '1')});
  }
`;

const ButtonIcon = ({
  color,
  disable,
  onClick,
  iconURI,
  isSquare,
  type,
  width,
  height
}) => {
  return (
    <span>
      <StyledButtonIcon
        disable={disable}
        width={width}
        height={height}
        onClick={onClick}
        color={color}
        isSquare={isSquare}
        type={type}
      >
        <img src={iconURI} alt="" />
      </StyledButtonIcon>
    </span>
  );
};

ButtonIcon.defaultProps = {
  disable: false,
  isSquare: false,
  type: 'button'
};

ButtonIcon.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  iconURI: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default ButtonIcon;

import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import leftArrowImg from '../../assets/images/left-white.png';
import rightArrowImg from '../../assets/images/right-white.png';
import ButtonIcon from '../../components/ButtonIcon';
import {StagePanel, ReferencesPanel} from '../../components/DetailsPanel';
import { STRINGS } from '../../constants/strings';
import {DetailsShowHidePanel, DetailsShowHideCostPanel} from '../../components/DetailsShowHidePanel';

const StyledStagesContainer = styled.div`
  height: calc(
    100vh - (${props => props.theme.headerSize} + 390px)
  ); // 390 might not be fixed
  overflow-y: auto;
  overflow-x: hidden;
  width: 310px;
//  margin-right: 0px; // push scroll bar
`;

const StyledStages = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  color: black;
  font-weight: 700;
  width: 300px;
`;

const StyledVariation = styled.div`
  width: 290px;
`;

const ReactionDetails = ({ details, onClose, onFlagClick, maxCount }) => {
  const [currentNum, setCurrentNum] = useState(0);

  useEffect(() => {
    // check if length goes below current num
      // this means user clicked on new route
    if (details.variation.length <= currentNum) {
      setCurrentNum(0);
    }
  }, [details.variation.length, currentNum]);

    const refClicked = ref => {
	var win = window.open(ref.url, '_blank');
	win.focus();
    }
    
  const navigationClicked = num => {
    let newNum = currentNum + num;
    // if below zero do not change
     if (newNum < 0 || newNum === details.variation.length) {
      newNum = currentNum;
    } else {
      setCurrentNum(newNum);
    }
  };

    return (
    <div>
      {details.variation.length > currentNum && (
        <Fragment>
          <div className="flex-space-between">
            <h3>{STRINGS.reactionDetails}</h3>
            <Button type="close" isIconOnly={true} onClick={onClose} />
          </div>

          {details.variation.length !== 1 && (
          <div className="flex-space-between">
            <h3>
              Variation {currentNum + 1} of {details.variation.length}{' '}
            </h3>
            <div className="route-arrow-container">
              {details.variation.length !== 1 && (
                <Fragment>
                  <ButtonIcon
                    disable={currentNum === 0 ? true : false}
                    width="25px"
                    height="auto"
                    iconURI={leftArrowImg}
                    onClick={() => navigationClicked(-1)}
                  />
                  <ButtonIcon
                    disable={currentNum === details.variation.length - 1 ? true : false}
                    width="25px"
                    height="auto"
                    iconURI={rightArrowImg}
                    onClick={() => navigationClicked(1)}
                  />
                </Fragment>
              )}
            </div>
	  </div>
	  )}
          <StagePanel
            maxCount={maxCount}
            label={details.idLabel}
            desc={details.id}
          />
	  {details.variation[currentNum].source == "Computer Generated" && (
	    <StagePanel
              maxCount={maxCount}
              label={"Computer Generated"}
              desc={"Yes"}
	    />
	  )}		  
	  {details.variation[currentNum].source == "Literature" && (
	     <ReferencesPanel
                maxCount={maxCount}
                label={details.variation[currentNum].source}
   	        refs={details.variation[currentNum].refs}
	        onClick={refClicked}
		  />
	  )}

     {details.variation[currentNum].comment && (
        <DetailsShowHidePanel
          maxCount={maxCount}
          width="90px"
          label="Procedure"
  	  title={details.variation[currentNum].title}
  	  desc={details.variation[currentNum].comment}
	/>)}
     {!details.variation[currentNum].comment && (
      <StagePanel
	     maxCount={maxCount}
	     label="Procedure"
	     desc="n/a"
             />)}

      <StagePanel
	     maxCount={maxCount}
	     label={details.variation[currentNum].yieldLabel}
	     desc={details.variation[currentNum].yield}
           />
           <StagePanel
             maxCount={maxCount}
             label="Yield Id"
	     desc={details.variation[currentNum].yieldId}
            />
	      
           <StyledStagesContainer>
              {details.variation[currentNum].stages.map((data, i) => {
              return (
                <Fragment key={'route-details-' + i}>
		  { details.variation[currentNum].stages.length > 1 && (
		  <StyledStages>Stage {i + 1}</StyledStages>
		  )}
 	      <StyledVariation>
                  <StagePanel
                    maxCount={maxCount}
                    label="Time"
                    desc={data.times}
                  />
                  <StagePanel
                    maxCount={maxCount}
                    label="Temperature"
                    desc={data.temps}
                  />
                  <StagePanel
                    maxCount={maxCount}
                    label="Solvents"
                    desc={data.solvents}
                  />
                  <StagePanel
                    maxCount={maxCount}
                    label="Reagents"
                    desc={data.reagents}
                  />
                  <StagePanel
                    maxCount={maxCount}
                    label="Catalysts"
                    desc={data.catalysts}
                  />
                  <StagePanel
                    maxCount={maxCount}
                    label="pH"
                    desc={data.ph}
                      />
  	         </StyledVariation>		      
                </Fragment>
              );
            })}
          </StyledStagesContainer>
        </Fragment>
      )}
    </div>
  );
};

export default ReactionDetails;

// TODO change form to submit, allow button types

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../components/Button';
import { STRINGS } from '../../constants/strings';
import { Utils } from '../../utils/utils';

const StyledInput = styled.input`
  width: 90%;
  margin: 0;
  padding: 10px;
  font-size: ${props => props.theme.primaryBodyFontSize};
  ::placeholder {
    font-weight: 400;
    font-style: italic;
    color: ${props => props.theme.placeholderColor};
  }
`;
const StyledData = styled.div`
  width: 90%;
  margin: 0;
  padding: 0;
  font-size: ${props => props.theme.primaryBodyFontSize};
  border: none;
  height: 75px;
  label {
    text-transform: uppercase;
    font-size: ${props => props.theme.smallBodyFontSize};
  }
`;

const StyledError = styled.div`
  color: ${props => props.theme.flagColor};
  padding: 10px 0 0 0;
`;
const UserEdit = ({ toggleEditMode, user, updateUserProfile }) => {
  const [inputs, setInputs] = useState({ ...user });
  const [errors, setErrors] = useState([]);

  const validate = (name, email) => {
    const errors = [];

    if (name === undefined || email === undefined) {
      errors.push('Name and Email cannot be empty');
    }
    if (name.length === 0) {
      errors.push('Error: Name cannot be empty');
    }

    if (email.length < 5) {
      errors.push('Error: Email should be at least 5 characters long');
    }
    if (email.split('').filter(x => x === '@').length !== 1) {
      errors.push('Error: Email should contain a @');
    }
    if (email.indexOf('.') === -1) {
      errors.push('Error: Email should contain at least one dot');
    }

    return errors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { name, email } = inputs;

    // check for errors
    const errors = validate(name, email);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    //create object to send
    let newUserDetail = {
      name: name,
      phone: inputs.phone,
      email: email,
      jobTitle: inputs.jobTitle,
      teamName: inputs.teamName
    };

    Utils.UpdateUserProfile(newUserDetail, user, onEditCallBack);
  };

  const onEditCallBack = (user, status) => {
      if (status === 200) {
	  // success
	  updateUserProfile(user);
	  toggleEditMode();
	  setErrors([]);
      } else {
	  setErrors(['Error, profile update failed']);
      }
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <Fragment>
      <form>
        <StyledData>
          <label>{STRINGS.userDataName}</label>
          <StyledInput
            type="text"
            name="name"
            defaultValue={inputs.name}
            placeholder={STRINGS.userDataName}
            onChange={handleInputChange}
          ></StyledInput>
        </StyledData>
        <StyledData>
          <label>{STRINGS.userDataTitle}</label>
          <StyledInput
            type="text"
            name="jobTitle"
            defaultValue={inputs.jobTitle}
            placeholder={STRINGS.userDataTitle}
            onChange={handleInputChange}
          />
        </StyledData>
        <StyledData>
          <label>{STRINGS.userDataTeam}</label>
          <StyledInput
            type="text"
            name="teamName"
            defaultValue={inputs.teamName}
            placeholder={STRINGS.userDataTeam}
            onChange={handleInputChange}
          />
        </StyledData>
        <StyledData>
          <label>{STRINGS.userDataEmail}</label>
          <StyledInput
            type="text"
            name="email"
            defaultValue={inputs.email}
            placeholder={STRINGS.userDataEmail}
            onChange={handleInputChange}
          />
        </StyledData>
        <StyledData>
          <label>{STRINGS.userDataTelephone}</label>
          <StyledInput
            type="text"
            name="phone"
            defaultValue={inputs.phone}
            placeholder={STRINGS.userDataTelephone}
            onChange={handleInputChange}
          />
        </StyledData>
        <div className="controls">
          <Button text="Cancel" onClick={toggleEditMode} />
          <Button isPrimary={true} text="Update" onClick={handleSubmit} />
        </div>
      </form>
      {errors.length >= 1 && <StyledError>{errors[0]}</StyledError>}
    </Fragment>
  );
};

UserEdit.propTypes = {
  toggleEditMode: PropTypes.func,
  user: PropTypes.object
};

export default UserEdit;

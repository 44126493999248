/**
 * Route visualization canvas
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SRV } from '../../utils/RouteVisualization';

const StyledCanvas = styled.div`
  width: ${props => props.width};
  background-color: ${props => props.backgroundColor};
`;
var cid = 0;

class Canvas extends React.Component {
  componentDidMount() {
    const canvas = this.refs.canvas;
    let routeId = this.props.routeId ? this.props.routeId : 0;
    let strategyId = this.props.strategyId ? this.props.strategyId : 0;
    //console.log('canvas mount', cid++, canvas.id, canvas.uid);
    canvas.uid = cid++;
    SRV.CanvasMount(
      canvas,
      strategyId,
      routeId,
      this.props.type,
      this.props.results,
      this.props.onCanvasClick
    );
  }

  componentWillUnmount() {
    const canvas = this.refs.canvas;
    //console.log('canvas unmount', cid, canvas.id, canvas.uid);
    SRV.CanvasUnmount(canvas);
  }

  // Check if prop updated, mainly canvas type
  /*
  componentDidUpdate(prevProps, prevState) {

    // only update canvas if type changed
    if (prevProps.type !== this.props.type) {
      const canvas = this.refs.canvas;
      let routeId = this.props.routeId ? this.props.routeId : 0;
      console.log('canvas update', cid, mid--, canvas.id);
      SRV.InitCanvas(canvas, this.props.id, routeId, this.props.type, this.props.results, this.props.onCanvasClick);
    }
    }
*/
    render() {
	let strategyId = this.props.strategyId ? this.props.strategyId : 0;
	let routeId = this.props.routeId ? this.props.routeId : 0;	
	let id = this.props.type + 'S' + strategyId + 'R' + routeId;

    return (
      <StyledCanvas width={this.props.width}>
        <canvas id={id} ref="canvas" />
      </StyledCanvas>
    );
  }
}

Canvas.defaultProps = {
  width: '100%',
  backgroundColor: 'transparent'
};

Canvas.propTypes = {
/*  id: PropTypes.number,*/
  width: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default Canvas;

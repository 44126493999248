/**
 * Panel to display users projects
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import SavedRoutePanel from './SavedRoutePanel';
import InputBox from '../InputBox';
import TextareaAutosize from '../TextareaAutoResize';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import { Utils } from '../../utils/utils';

const StyledProject = styled.div`
  background-color: inherit;
  padding: 10px;
  margin-bottom: 25px;
`;

const StyledProjectInfo = styled.div`
  background-color: ${props => props.theme.primaryBgColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;

  .project-container {
    width: 100%;
  }
`;

const StyledProjectLabelRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .project-label-container {
    //width: 600px;
    display: flex;
    align-items: center;

    h3 {
      margin-right: 10px;
    }

    p {
      margin-left: 10px;
    }
  }
`;

const StyledButtonSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
`;

const StyledErrorMsg = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.color};
`;

class ProjectPanel extends React.Component {
  constructor(props) {
    super(props);

    this.toggleView = this.toggleView.bind(this);
    this.handleRemoveRouteClick = this.handleRemoveRouteClick.bind(this);
    this.handleRemoveProjectClick = this.handleRemoveProjectClick.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onSaveCallback = this.onSaveCallback.bind(this);

    this.state = {
      showView: false,
      uniqueNum: 0,
      isEdit: false,
      projectName: '',
      projectDesc: '',
      errorMsg: ''
    };
  }

  componentDidMount() {
    this.setState({
      projectName: this.props.data.name,
      projectDesc: this.props.data.desc
    });
  }

  toggleView() {
    let showView = !this.state.showView;
    this.setState({
      showView: showView
    });
  }

  toggleEdit() {
    let isEdit = !this.state.isEdit;
    this.setState({
      isEdit: isEdit,
      errorMsg: ''
    });
  }

  handleRemoveRouteClick(i, text) {
    this.props.onRemoveToggle('savedRoute', i, text);
  }

  handleRemoveProjectClick() {
    let savedRotueMsg = '';
    let savedRouteCount =
      this.props.savedRouteCanvasArr !== undefined
        ? this.props.savedRouteCanvasArr.length
        : 0;
    if (savedRouteCount > 0) {
      let routeText = savedRouteCount === 1 ? 'route' : 'routes';
      savedRotueMsg = `This will remove ${savedRouteCount} ${routeText} inside this project.`;
    }
    let text = `Are you sure you want to remove <i>${this.state.projectName}</i>?`;

    this.props.onRemoveProjectClick(text + '\r\n' + savedRotueMsg);
  }

  handleSave = () => {
    let updatedProjectDetail = {
      projectName: this.state.projectName,
      projectDesc: this.state.projectDesc
    };
    Utils.UpdateProject(
      this.props.data,
      updatedProjectDetail,
      this.props.user,
      this.onSaveCallback
    );
  };

  onSaveCallback(user, status) {
    if (status === 200) {
      this.props.updateProjects(user.projects);
      this.props.updateSavedRoutes(user.savedRoutes);
      this.toggleEdit();
    } else {
      this.setState({
        errorMsg: 'Error, project edit failed'
      });
    }
  }

  render() {
    const {
      user,
      savedRouteCanvasArr,
      uniqueNum,
      onSavedRouteClick,
      updateSavedRoutes
    } = this.props;

    let savedCanvasLength =
      savedRouteCanvasArr !== undefined ? savedRouteCanvasArr.length : 0;
    return (
      <StyledProject>
        <StyledProjectInfo>
          <div className="project-container">
            <StyledErrorMsg color="red">{this.state.errorMsg}</StyledErrorMsg>
            <StyledProjectLabelRow>
              <div className="project-label-container">
                <h3>Project</h3>
                <InputBox
                  type="text"
                  name="projectName"
                  value={this.state.projectName}
                  width="150px"
                  height="28px"
                  align="left"
                  disabled={!this.state.isEdit}
                  onChange={event => handleOptionChange(this, event)}
                />
                <p>
                  {savedCanvasLength} saved routes
                </p>
            </div>
              <StyledButtonSpace>
	       {!user.tokenOkta && (
                <Button
                  text={!this.state.isEdit ? 'Edit' : 'Save'}
                  onClick={
                    !this.state.isEdit ? this.toggleEdit : this.handleSave
                  }
                    />
	       )}
                <Button
                  text={this.state.showView ? 'Hide' : 'View'}
                  onClick={this.toggleView}
                />
	       {!user.tokenOkta && (
                <Button
                  buttonColor="flagButtonHoverColor"
                  text="Remove"
                  onClick={this.handleRemoveProjectClick}
                       />
	       )}
              </StyledButtonSpace>
            </StyledProjectLabelRow>
            <TextareaAutosize
              type="text"
              name="projectDesc"
              placeholder=""
              value={this.state.projectDesc}
              disabled={!this.state.isEdit}
              width="100%"
              height="60px"
              align="left"
              handleInput={event => handleOptionChange(this, event)}
            />
          </div>
        </StyledProjectInfo>
        {this.state.showView &&
          savedRouteCanvasArr !== undefined &&
          savedRouteCanvasArr.map((route, i) => {
            return (
              <SavedRoutePanel
                key={
                  'route-' +
                  i +
                  'uni-num-' +
                  uniqueNum +
                  '-' +
                  savedRouteCanvasArr.length
                }
                route={route}
                user={user}
                onSavedRouteClick={onSavedRouteClick}
                updateSavedRoutes={updateSavedRoutes}
                handleRemoveRouteClick={this.handleRemoveRouteClick}
              />
            );
          })}
      </StyledProject>
    );
  }
}

ProjectPanel.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onRemoveToggle: PropTypes.func
};

export default ProjectPanel;

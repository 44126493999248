import React from 'react';
import Button from '../../components/Button';
import {DetailsPanel} from '../../components/DetailsPanel';
import {DetailsShowHidePanel, DetailsShowHideCostPanel} from '../../components/DetailsShowHidePanel';
import { STRINGS } from '../../constants/strings';

const CompoundDetails = ({ details, onClose, maxCount }) => {
  return (
    <div>
      <div className="flex-space-between">
        <h3>{STRINGS.compoundDetails}</h3>
        <Button type="close" isIconOnly={true} onClick={onClose} />
      </div>
      <DetailsPanel
        maxCount={maxCount}
        width="90px"
        label="Name"
        desc={details.name}
      />
      <DetailsPanel
        maxCount={maxCount}
        width="90px"
        label="ID"
        desc={details.id}
      />
      <DetailsPanel
        maxCount={maxCount}
        width="90px"
        label="Source"
        desc={details.CompoundType}
      />
      <DetailsShowHidePanel
        maxCount={maxCount}
        width="90px"
        label="SMILES"
        desc={details.smiles}
      />
      <DetailsShowHidePanel
        maxCount={maxCount}
        width="90px"
        label="InChI"
        desc={details.inchi}
      />
      <DetailsShowHideCostPanel
        maxCount={maxCount}
        width="90px"
        label="Cost"
        desc={details.mol_cost}
        bom={details.bom}
      />
      <DetailsPanel
        maxCount={maxCount}
        width="90px"
        label="Mol weight"
        desc={details.mol_weight}
	  />
      {/*
      <DetailsPanel
        maxCount={maxCount}
        width="90px"
        label="MCT"
        desc={details.MCT}
      />
     */}
    </div>
  );
};

export default CompoundDetails;

import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

const StyledTextarea = styled(TextareaAutosize)`
  width: ${props => props.width};
  min-height: 40px;
  height: ${props => props.height};
  font: 400 16px Open Sans !important; // NOTE props is not working for this
  margin: 0;
  text-align: ${props => props.align};
  border: ${props => `1px solid ${props.theme.edgeColor}`};
  resize: vertical;
  overflow-y: scroll;
  box-sizing: border-box;

  :disabled {
    background: #dddddd;
  }
`;

const TextareaAutoResize = ({
  value,
  disabled,
  handleInput,
  width,
  height,
  placeholder,
  name
}) => {
  return (
    <StyledTextarea
      type="text"
      name={name}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      width={width}
      height={height}
      align="left"
      onChange={handleInput}
    />
  );
};

TextareaAutoResize.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  handleInput: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string
};

export default TextareaAutoResize;

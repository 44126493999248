import React, { Component } from 'react';
import LoginInput from './Inputs/LoginInput';
import { config } from '../../config';
import ProductNameImg from '../../assets/images/logo-synroute@3x.png';
import Button from '../../components/Button';
import { STRINGS } from '../../constants/strings';
import {Okta} from '../../utils/okta';
import { FB } from './firebase';

export default class LoginPage extends Component {
    constructor(props) {
	super(props);

	this.handleSubmit = this.handleSubmit.bind(this);
	this.handleChange = this.handleChange.bind(this);
	this.onLogin = this.onLogin.bind(this);
	this.onAuthorizedFB = this.onAuthorizedFB.bind(this);
	this.changeType = this.changeType.bind(this);
	this.onKeyPress = this.onKeyPress.bind(this);
	this.onReset = this.onReset.bind(this);

	this.state = {
	    username: '',
	    password: '',
	    inputType: 'default',
	    inReset: false
	};
    }

    componentDidMount() {
    // Look for provided authentication token
    // ?token=... (like Okta) or ?saml_token=...
	var params = new URLSearchParams(window.location.search);
	var tokenOkta = params.get('token');
	var saml_token = params.get('saml_token');

	if (saml_token) {
	    Okta.Exchange(saml_token);
	    return;
	}

	if (tokenOkta) {
        sessionStorage.setItem(STRINGS.synfiniOktaStorageToken, tokenOkta);
        console.log("caching okta token in session storage");
	} else {
	    if (config.DEBUG_USER === 'jim') {
            tokenOkta = localStorage.getItem("okta");
            if (tokenOkta) {
                console.log("using cached token");
            }
	    }
	}
    
    // This is also the token that the SynFini site provides.
	var tokenGW = null;
	if (config.DEBUG_AUTOLOGIN)
	    tokenGW = localStorage.getItem(STRINGS.localStorageToken);
	
	if (tokenOkta) {
	    this.props.userLogin(tokenGW, tokenOkta);
	    this.props.history.push('/search');
	} else {
	    if (tokenGW && tokenGW !== '') {
		this.props.userLogin(tokenGW, null);
		this.props.history.push('/search');
	    }
	}

	var reset = document.querySelector(".resetPassword");
	reset.onclick = this.onReset;

	if (tokenGW) {
	    FB.Init(this.onAuthorizedFB);
	} else {
	    FB.Init();
	    FB.Logout();
	}
    }

    onReset(event) {
	event.stopImmediatePropagation();	
	event.stopPropagation();	
	var email = document.querySelector('input[name="username"]').value;

	if (!email || !this.validateEmail(email)) {
	    alert("Invalid email " + email);
	    return;
	}

	FB.ResetPassword(email, function() {
	    alert("Password reset sent to: " + email);
	});

	return 0; 
    }

    validateEmail(email)
    {
	return String(email)
	    .toLowerCase()
	    .match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	    );
    }
    
    handleChange(event) {
	const target = event.target;
	const value = target.value;
	const name = target.name;
	this.setState({
	    [name]: value
	});
    }

    onLogin(email, password)
    {
	//console.log("OnLogin", email, password);
	FB.Login(email, password, this.onAuthorizedFB);
	
    }

    onAuthorizedFB(user)
    {
	if (user) {
	    let tokenGW = btoa(user.email + ':' + user.uid);
	    //console.log("onAuthorizedFB", user.email, user.uid);
	    localStorage.setItem(STRINGS.localStorageToken, tokenGW);
	    this.props.userLogin(tokenGW, null);
	    this.props.history.push('/search');
	}
    }
    
    onLoginOld(username, password) {
	let url = config.GATEWAY_SERVER + '/login.php';
	let req = new XMLHttpRequest();
	let self = this;

	req.open('GET', url);
	let tokenGW = btoa(username + ':' + password);
	req.setRequestHeader('Authorization', 'Basic ' + tokenGW);
	//req.setRequestHeader('Authorization', this.props.user.authToken);
	req.onreadystatechange = function() {
	    if (req.readyState === 4) {
		if (req.status === 200) {
		    // save tokenGW to localStorage
		    //localStorage.setItem(STRINGS.localStorageToken, tokenGW);

		    //self.props.user.authToken = authToken;
		    self.props.userLogin(tokenGW, null);
		    self.props.history.push('/search');
		} else {
		    //alert("login failed:", req.statusText + "(" + req.status + ")");
		    self.changeType('invalid');
		}
	    }
	};

	req.send(null);
	return;
    }

    handleSubmit(event) {
	event.preventDefault();
	if (this.state.username === '' && this.state.password === '') {
	    /*
	    this.setState(
		{
		    username: config.DEBUG_EMAIL,
		    password: config.DEBUG_PASSWD
		},
		() => {
		    this.onLogin(this.state.username, this.state.password);
		}
	    );
	    */
	} else {
	    this.onLogin(this.state.username, this.state.password);
	}

	/*
	// TODO make this into actual log in check
	if((this.state.username).length > 3) {
	this.props.userLogin(this.state.username);
	this.props.history.push("/search");
	}    
	event.preventDefault();
	*/
    }

    changeType(type) {
	// check type is reset
	if (type === 'reset') {
	    // set inputType to createOrReset and inReset to true
	    // This is since Create and Reset are identical except the button color + text
	    this.setState({
		inputType: 'createOrReset',
		inReset: true
	    });
	} else {
	    this.setState({
		inputType: type,
		inReset: false
	    });
	}
    }

    onKeyPress = event => {
	if (event.key === 'Enter') {
	    this.handleSubmit(event);
	}
    };

    render() {
	let onSubmit = this.handleSubmit;
	let submitColor = 'lightBlue';
	let submitText = 'LOGIN';
	let secondSubmitText = 'CREATE AN ACCOUNT';
	let secondSubmitColor = 'darkBlue';
	let type = '';
	let text = '';
	let textColor = '';

	// change according to type
	switch (this.state.inputType) {
	case 'createOrReset':
            onSubmit = this.state.inReset ? this.createAccount : this.resetPassword;
            submitColor = this.state.inReset ? 'flagColor' : 'spotColor';
            submitText = this.state.inReset ? 'RESET' : 'SUBMIT';
            secondSubmitText = 'BACK';
            secondSubmitColor = 'spotColor';
            type = '';
            text =
		'Password must contain 12 total characters, at least one uppercase letter and one number';
            textColor = '';
            break;
	case 'invalid':
            onSubmit = this.handleSubmit;
            submitColor = 'tertiaryColor';
            submitText = 'Login';
            secondSubmitText = 'RESET PASSWORD';
            secondSubmitColor = 'flagColor';
            type = 'reset'; // change type to reset which will auto set inReset
            text =
		'The username and password you entered below are invalid. Use the button below to reset';
            textColor = 'red';
            break;
	default:
            onSubmit = this.handleSubmit;
            submitColor = 'spotColor';
            submitText = 'Login';
            secondSubmitText = 'CREATE AN ACCOUNT';
            secondSubmitColor = 'spotColor';
            type = 'createOrReset'; // change type to create
            text = '';
            textColor = '';
            break;
	}

	return (
		<div className="login-page">
		<div className="login-content-container">
		<section className="login-introduction">
		<div>
		<h1 style={{ backgroundImage: `url(${ProductNameImg})` }}>
                {STRINGS.productName}
            </h1>
		<p>{STRINGS.summary}</p>
		</div>
		</section>
		<section className="login-field">
		<form>
		<LoginInput
            onSubmit={onSubmit}
            submitColor={submitColor}
            submitText={submitText}
            secondSubmitText={secondSubmitText}
            secondSubmitColor={secondSubmitColor}
            onSecondSubmit={() => this.changeType(type)}
            handleChange={this.handleChange}
            text={text}
            textColor={textColor}
            type={this.state.inputType}
            onKeyPress={this.onKeyPress}
		/>
		</form>
		{config.OktaEnable && (
			<div className="login-button-container" style={{paddingLeft: "75px"}}>
			<Button
		    buttonColor={'flagColor'}
		    text={"Okta Login"}
		    onClick={Okta.Login}
		    isPrimary={true}
			/>
			</div>
		)}
            </section>
		</div>
		</div>
	);
    }
}


/**
 * This is a skeleton around the actual pages
 *
 */

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoginPage from '../LoginPage';
import SearchPage from '../SearchPage';
import StrategiesPage from '../StrategiesPage';
import RoutePage from '../RoutePage';
import StrategyPage from '../StrategyPage';
import UserPage from '../UserPage';


/* 
 * WARNING: when modifying the following CSS rules also modify them in `src/styles/variables.scss`
 * This is due to complications loading this file into a JavaScript object in App.js. 
 * 
 * Due to node compatibilty issues and deprecations of `node-sass` and `node-sass-extract-loader`, the object is currently created manually.
 * It should be possible to just use an import like `import theme from '../../styles/variables.scss';`, unfortunately that doesn't work at all.
 */


// OLD CODE:
// https://medium.com/styled-components/getting-sassy-with-sass-styled-theme-9a375cfb78e8
// Extract our Sass variables into a JS object
// eslint-disable-next-line import/no-webpack-loader-syntax
// const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!../../styles/variables.scss');


// TEMPORARY WORKAROUND
// 
// the following content should be identical to `variables.scss`
var theme = {
  // sizes
  sizeXsmall: "5px",
  sizeSmall: "15px",
  sizeMedium: "30px",
  sizeLarge: "50px",

  headerSize: "75px",
  headerLargeSize: "150px",
  footerSize: "75px",
  contentSize: "1200px",

  borderRadius: "4px",

  // New color standards
  debugColor: "ff0000",
  primaryColor: "191d1e",
  secondaryColor: "808080",
  tertiaryColor: "2c4652", // darkBlue
  edgeColor: "979797",
  placeholderColor: "9b9b9b",

  spotColor: "#46c5e9", // lightBlue
  saveColor: "#f8d33b", // yellow
  flagColor: "#e40d2b", // red
  keepColor: "#284451", //
  primaryBgColor: "#ffffff",
  secondaryBgColor: "#e7e7e7",
  tertiaryBgColor: "#000000",
  popupBgColor: "#00000055",
  popupAltBgColor: "#ffffff77",

  // New size standards
  largeBodyFontSize: "18px",
  primaryBodyFontSize: "16px",
  smallBodyFontSize: "12px",
  primaryButtonFontSize: "12px",

  // Button color
  primaryButtonColor: "#2c4652", // darkBlue
  primaryButtonHoverColor: "#46c5e9", // lightBlue
  disabledButtonColor: "#cccccc", // edgeColor 979797
  disabledButtonHoverColor: "#cccccc", // edgeColor
  flagButtonHoverColor: "#e40d2b", // red
  transparentButtonColor: "transparent",
  saveButtonColor: "#f8d33b", // TODO: yellow, to be removed?
}
// END of section that should be identical to `variables.scss`
//////////


const LoginContainer = () => (
  <div className="app-login">
    <Route path="/" component={LoginPage} />
  </div>
);

class App extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <ThemeProvider theme={theme}>
          <Header
            user={this.props.user}
            onLogoutClick={this.props.logout}
            fetchUser={this.props.fetchUser}
          />
          <Switch>
            <Route exact path="/" component={LoginContainer} />
            <Route path="/search" component={SearchPage} />
            <Route path="/strategies" component={StrategiesPage} />
            <Route path="/strategy" component={StrategyPage} />
            <Route path="/route" component={RoutePage} />
            <Route path="/user" component={UserPage} />
          </Switch>
          <Footer />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;

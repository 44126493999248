/**
 * Panel of /Strategy page
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Canvas from '../Canvas';

const StyledStrategy = styled.div`
  background-color: ${props => props.theme.primaryBgColor};
  padding: 0px 15px;
  margin-bottom: 10px;
`;

const StyledStrategyInfo = styled.div`
  position: relative;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  div:nth-child(2) {
    //margin-top: 10px;
    display: flex;
    div {
      margin-right: 0px;
    }
  }
`;

const StyledStrategyCenter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  h3 {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
    margin: 0;
  }
  p {
    white-space: pre-wrap;
    padding: 5px 5px;
    margin: 0;
  }
  p>span {
    color: red;  
  }
`;

class StrategyPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onHideClick = this.onHideClick.bind(this);

    this.state = {
      hide: false
    };
  }

  onHideClick() {
    let b = !this.state.hide;
    this.setState({
      hide: b
    });
  }

  render() {
    const {
      title,
      description,
      onOpenClick,
      routeId,
      strategyId,
      results,
      onFlagClick,
      onSaveClick
    } = this.props;

   return (
      <StyledStrategy>
        <StyledStrategyInfo>
          <StyledStrategyCenter>
            <h3>{title}</h3>
	      <p>{description[0]}
   	         <span>{description[1]}</span>
	    </p>
          </StyledStrategyCenter>
          <div>
            <Button
              text="View details"
              isPrimary={true}
	      height="35px"  /*fix this -- should be scss. JAH */
              onClick={onOpenClick}
            />
            <Button type="save"  height="35px" onClick={onSaveClick} />
            <Button type="flag"  height="35px" onClick={onFlagClick} />
            <Button
              width="60px"
              height="35px"	
              text={this.state.hide ? 'Show' : 'Hide'}
              onClick={this.onHideClick}
            />
          </div>
        </StyledStrategyInfo>
        {!this.state.hide && (
          <StyledStrategyCenter>
            <Canvas
              id={'S' + strategyId +'R' + routeId}
	      strategyId={strategyId}
              routeId={routeId}
              results={results}
              type="routeCanvas"
            />
          </StyledStrategyCenter>
        )}
      </StyledStrategy>
    );
  }
}

StrategyPanel.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onCanvasClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onFlagClick: PropTypes.func,
  routeId: PropTypes.number
};

export default StrategyPanel;

import React, { Component } from 'react';
import StrategiesPanel from '../../components/StrategiesPanel';
import { MAX_ROUTE_OPTIONS } from '../../constants/constants';
import SearchOptionStrategy from '../../components/SearchOptionStrategy';
import Canvas from '../../components/Canvas';
import FlagEmailPopup from '../../components/FlagEmailPopup';
import { SRV } from '../../utils/RouteVisualization';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import { Utils } from '../../utils/utils.js';
import { isEmpty, isEqual } from 'lodash';
import Loading from '../../assets/images/loading.gif';
import { STRINGS } from '../../constants/strings';
import { config } from '../../config';

export default class StrategiesPage extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);
    this.buildStrategyPanels = this.buildStrategyPanels.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onFlagClick = this.onFlagClick.bind(this);

    this.state = {
      data: [],
      routeMode: false,
      chosenDataId: -1,
      isSearching: false,
      flagEmailShow: false,
      desc: ''
    };
  }

  componentDidMount() {
    //console.log("SP mount", this, this.props.options.searchTarget);

    const { options, strategies } = this.props;

    if (isEmpty(options)) {
      console.log('options empty, returning to search page');
      this.props.history.push('/search');
    }

      if (options.results)
	  strategies.data = options.results;
      
    this.setState({
      data: strategies.data,
      literatureDerivedReactions: options.literatureDerivedReactions,
      computerGeneratedReactions: options.computerGeneratedReactions,
      maxRoute: options.maxRoute,
      maxReactionSteps: options.maxReactionSteps,
      maxReagentCost: options.maxReagentCost,
      autoSYNCompatible: options.autoSYNCompatible,
      flagEmailShow: false
    });

  }

  // Check if prop updated, mainly strategies
  componentDidUpdate(prevProps, prevState) {
    const { options, strategies } = this.props;

    if (!isEqual(prevProps.strategies, this.props.strategies)) {
      console.log('change!');
      this.setState({
        data: strategies.data,
        isSearching: false,
        errorMsg: ''
      });
    } else if (strategies.errorMsg !== '' && this.state.isSearching) {
      this.setState({
        isSearching: false,
        errorMsg: strategies.errorMsg
      });
    }

    this.props.user.breadcrumb.Update(strategies.data, -1, -1);

    // update search option
    if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({
        literatureDerivedReactions: options.literatureDerivedReactions,
        computerGeneratedReactions: options.computerGeneratedReactions,
        maxRoute: options.maxRoute,
        maxReactionSteps: options.maxReactionSteps,
        maxReagentCost: options.maxReagentCost,
        autoSYNCompatible: options.autoSYNCompatible,
        flagEmailShow: false
      });
    }
  }

  // meta is for react-select
  handleChange(event, meta) {
    handleOptionChange(this, event, meta);
  }

  onMoreClick(results, strategyId) {
    this.props.onStrategyChosen(results, strategyId);
    this.props.history.push('/strategy');
  }

  onFlagClick(strategyId, desc) {
    let flagEmailShow = !this.state.flagEmailShow;
    let options = this.props.options;
    let canvas = document.getElementById('strategyCanvasS' + strategyId + 'R0');
      
    let details = {
      type: 'strategyDetails',
      searchOptions: options,
      strategyId: strategyId,
      results: this.props.strategies.data,
      canvas: canvas
    };

    let body =
      '<b>Strategy ' + (strategyId+1) + '</b>\n\n' +
      'Search options: \n' +
      '\tMax Routes: ' +
      options.maxRoute.value +
      '\n' +
      '\tMax Cost: ' +
      options.maxReagentCost.value +
      '\n' +
      '\tMax Steps: ' +
      options.maxReactionSteps.value +
      '\n' +
      '\tAutoSyn Compatible: ' +
      options.autoSYNCompatible +
      '\n' +
      '\tLiterature Derived: ' +
      options.literatureDerivedReactions +
      '\n' +
      '\tComputer Generated: ' +
      options.computerGeneratedReactions +
      '\n';

    this.setState({
      flagEmailShow: flagEmailShow,
      desc: flagEmailShow ? body : '',
      details: flagEmailShow ? details : '',
      chosenDataId: strategyId
    });
  }

  onSearchClick() {
    let searchStr = this.props.options.searchTarget;

    let options = {
      maxRoute: this.state.maxRoute,
      maxReactionSteps: this.state.maxReactionSteps,
      literatureDerivedReactions: this.state.literatureDerivedReactions,
      maxReagentCost: this.state.maxReagentCost,
      autoSYNCompatible: this.state.autoSYNCompatible,
      computerGeneratedReactions: this.state.computerGeneratedReactions,
      searchTarget: searchStr
    };

    let url = Utils.BuildSearchURL(options);
    if (url == null) {
      alert(
        'Please select either Literature Derived or Computer Generated reactions or both'
      );
      return;
    }
    this.setState({
      isSearching: true
    });

      let tokenGW = this.props.user.tokenGW;
      if (!tokenGW)
	  tokenGW = config.TOKEN_GW;
	
      this.props.onSearch(
        url,
        options,
        this.props.history,
        tokenGW
      );
  }

  buildStrategyPanels(results, options) {
    const { strategies } = this.props;
    let strategyCounts = SRV.GetStrategyRouteCounts(results);
    const panels = [];

    for (let s = 0; s < strategyCounts.length; s++) {
      let desc = SRV.GetStrategyDesc(results, s);
      let title = 'Strategy ' + (s + 1);
      panels.push(
        <StrategiesPanel
          key={strategies.searchCount + '-strategy-' + (s + 1)}
          title={title}
          description={desc}
          strategyId={s}
          routeCount={strategyCounts[s]}
          results={results}
          onFlagClick={() => this.onFlagClick(s, desc)}
          onMoreClick={() => this.onMoreClick(results, s)}
        />
      );
    }
    return panels;
  }

  render() {
    const { user, options, updateFlagged } = this.props;
    if (isEmpty(options)) {
      return null;
    }

    let resultMsg = '';
    let results = this.props.strategies.data;

    let panels = results ? this.buildStrategyPanels(results, options) : [];

    if (panels.length === 0) {
      resultMsg = 'No result found with current search options.';
    }

    return (
      <div className="strategies-page">
        {this.state.isSearching && (
          <div className="search-notification-container">
            <img src={Loading} alt={STRINGS.searching} />
            <h3 className="search-notification">{STRINGS.searching}</h3>
          </div>
        )}
        {this.state.flagEmailShow && (
          <div className="search-notification-container">
            <FlagEmailPopup
              type="Flagged"
              label={STRINGS.flagEmailHeader}
              subject="Strategy"
              sendTo={config.emailFlag}
              cancelClick={this.onFlagClick}
              desc={this.state.desc}
              user={user}
              updateFlagged={updateFlagged}
              details={this.state.details}
              placeholder={'Please describe what is wrong with this Strategy'}
            />
          </div>
        )}
        <div className="strategies-contents">
          <div className="strategies-left">
            <div className="strategies-jsme">
              <h3>{STRINGS.targetCompound}</h3>
            </div>
            {panels.length !== 0 && (
              <Canvas id={0} results={results} type="targetCanvas" />
            )}
            <SearchOptionStrategy
              onChange={this.handleChange}
              onSearch={this.onSearchClick}
              maxRouteValue={
                this.state.maxRoute ? this.state.maxRoute : options.maxRoute
              }
              maxRouteOptions={MAX_ROUTE_OPTIONS}
              maxReactionStepsValue={
                this.state.maxReactionSteps
                  ? this.state.maxReactionSteps
                  : options.maxReactionSteps
              }
              maxReagentCostValue={
                this.state.maxReagentCost
                  ? this.state.maxReagentCost
                  : options.maxReagentCost
              }
              autoSYNCompatibleValue={
                this.state.autoSYNCompatible !== undefined
                  ? this.state.autoSYNCompatible
                  : options.autoSYNCompatible
              }
              literatureDerivedReactionsValue={
                this.state.literatureDerivedReactions !== undefined
                  ? this.state.literatureDerivedReactions
                  : options.literatureDerivedReactions
              }
              computerGeneratedReactionsValue={
                this.state.computerGeneratedReactions !== undefined
                  ? this.state.computerGeneratedReactions
                  : options.computerGeneratedReactions
              }
            />
          </div>
          <div className="strategies-right">
            {panels.length === 0 && this.props.strategies.errorMsg === '' && (
              <div className="strategies-result-msg">{resultMsg}</div>
            )}
            {this.props.strategies.errorMsg !== '' && (
              <div className="strategies-options-error">
                {this.props.strategies.errorMsg}
              </div>
            )}
            {panels.length !== 0 && <span>{panels}</span>}
          </div>
        </div>
      </div>
    );
  }
}

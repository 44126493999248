/**
 * Custom styled react-select
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const styles = {
  control: base => ({
    ...base,
    height: 32,
    minHeight: 32,
    cursor: 'pointer'
  }),
  option: (styles, { data }) => {
    const fontStyle =
      data.fontStyle !== 'undefined' ? data.fontStyle : 'normal';
    return {
      ...styles,
      fontStyle: fontStyle
    };
  },
  container: (provided, state) => ({
    ...provided,
    textAlign: state.textAlign // TODO make this dynamic work
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  }),
  clearIndicator: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0
  })
};

const Select = ({
  textAlign,
  options,
  width,
  name,
  value,
  onChange,
  placeholder,
  selectedValule
}) => (
  <div style={{ width: width }}>
    <ReactSelect
      textAlign={'right'}
      name={name}
      width={width}
      defaultValue={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      menuPlacement="auto"
      menuPosition="fixed"
      styles={styles}
    />
  </div>
);

Select.defaultProps = {
  width: 100,
  textAlign: 'right',
  placeholder: 'Select....'
};

Select.propTypes = {
  name: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectedValule: PropTypes.object,
  value: PropTypes.object
};

export default Select;

import { 
  SEARCH_INITIATED,
  SEARCH_SUCCEEDED, 
  SEARCH_FAILED,
} from '../constants/actionTypes';

const initialState = {
  data: null,
  errorMsg: '',
  searchCount: 0
};

export default (state = initialState, action) => {
 switch (action.type) {
  case SEARCH_INITIATED:
    return {
      ...state,
      date: null,
      errorMsg: ''
    }
  case SEARCH_SUCCEEDED:
   return {
     ...state,
     data: action.data,
     errorMsg: '',
     searchCount: state.searchCount + 1
   }
  case SEARCH_FAILED:
    return {
      ...state,
      data: null,
      errorMsg: action.msg
    }
  default:
    return state
 }
}
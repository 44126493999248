import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchPage from './SearchPage';
import {
  fetchStrategies,
  savedRouteClicked,
  updateSearchOption,
  updateBatchResults
} from '../../actions';

const mapStateToProps = state => ({
  user: state.user,
  errorMsg: state.strategies.errorMsg
});

const mapDispatchToProps = dispatch => {
  return {
    onSearch: (url, options, history, authToken) =>
      dispatch(fetchStrategies(url, options, history, authToken)),
    savedRouteClicked: (results, strategyId, routeId) =>
      dispatch(savedRouteClicked(results, strategyId, routeId)),
    updateSearchOption: options => dispatch(updateSearchOption(options)),
    updateBatchResults: batchResults => dispatch(updateBatchResults(batchResults))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchPage));

//export default SearchPageContainer;

/**
 * Search page, URL: /search
 *
 */

import React, { Component } from 'react';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import SearchOption from '../../components/SearchOption';
import Search from '../../components/Search';
import Dropzone from 'react-dropzone';
import {
  MAX_ROUTE_OPTIONS,
  MAX_REAGENT_OPTIONS,
  MAX_REACTION_OPTIONS
} from '../../constants/constants';
import JSMEWindow from '../../components/JSMEWindow';
import { Utils } from '../../utils/utils.js';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import Canvas from '../../components/Canvas';
import { config } from '../../config';
import { STRINGS } from '../../constants/strings';
import Loading from '../../assets/images/loading.gif';
import SavedRouteProxy from '../../assets/images/icon-empty-saved-route.png';
import {SRV} from '../../utils/RouteVisualization';

export default class SearchPage extends Component {
    
  constructor(props) {
    super(props);
    let lastSmilesStr = localStorage.getItem("lastSmilesStr");
    let lastSearchStr = localStorage.getItem("lastSearchStr");
      
    this.handleChange = this.handleChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onDrawIconClick = this.onDrawIconClick.bind(this);
    this.updateSmilesStr = this.updateSmilesStr.bind(this);
    this.onSuggestClick = this.onSuggestClick.bind(this);
    this.readFile = this.readFile.bind(this);
    this.toggleDropzone = this.toggleDropzone.bind(this);
    this.onSearchInput = this.onSearchInput.bind(this);
    this.updateSearchSuggestion = this.updateSearchSuggestion.bind(this);
    this.onSavedRouteClick = this.onSavedRouteClick.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleNonSearchClick = this.handleNonSearchClick.bind(this);
    this.onAutoCompleteHover = this.onAutoCompleteHover.bind(this);
    this.onSmartsClick = this.onSmartsClick.bind(this);
    this.state = {
      showSuggestion: false,
      suggestionList: [],
      maxRoutes: { label: '20', value: 20 },
      maxReactionSteps: { label: '15', value: 15 },
      literatureDerivedReactions: true,
      maxReagentCost: { label: '$1000', value: 1000 },
      autoSYNCompatible: false,
      computerGeneratedReactions: true,
      showJSME: false,
      searchStr: lastSearchStr ? lastSearchStr : '',
      smilesStr: lastSmilesStr ? lastSmilesStr : '',
      smilesAutocomplete: '',
      searchDisabled: false,
      isSearching: false,
      showOptionsError: false,
      optionsErrorMsg: '',
      errorMsg: '',
      molFile: '',
      tsvFile: '',	
      showDropzone: false,
      requestId: 0
    };
  }

    componentDidMount() {
	var batchResults = this.props.user.batchResults;	
	if (batchResults)
	    Utils.OnSmarts(batchResults, this.onSmartsClick);
	this.props.user.breadcrumb.Update(null, -1, -1);
  }
    
  // Check if prop updated, mainly errorMsg
    componentDidUpdate(prevProps, prevState) {
	//console.log("search did update", this.props.users);
    // only update if error msg changed in store
    if (this.props.errorMsg !== '' && this.state.isSearching) {
      this.setState({
        isSearching: false,
        errorMsg: this.props.errorMsg
      });
    }
  }

  onSavedRouteClick(results) {

    // update results, strategyId, routeId
    this.props.savedRouteClicked(results, 0, 0);
    //update search options
    this.props.updateSearchOption(results.searchOptions);
    this.props.history.push('/route');
  }

  // detect click outside of search component
  handleNonSearchClick(e) {
    e.preventDefault();
    this.setState({
      showSuggestion: false
    });
  }

  onAutoCompleteHover(evt, str, n) {
      let smiles = SRV.OnAutoCompleteHover(evt, str, n);
      if (smiles) {
	  this.setState({
	      //smilesAutocomplete: smiles,
	      smilesStr: smiles,
	  });
      }
  }

    onSmartsClick(results) {
	console.log("onSmartsClick", results.smartsId);
	// update results, strategyId, routeId
	this.props.savedRouteClicked(results, 0, 0);
	//update search options
      let options = {
        maxRoute: this.state.maxRoutes,
        maxReactionSteps: this.state.maxReactionSteps,
        literatureDerivedReactions: this.state.literatureDerivedReactions,
        maxReagentCost: this.state.maxReagentCost,
        autoSYNCompatible: this.state.autoSYNCompatible,
        computerGeneratedReactions: this.state.computerGeneratedReactions,
          searchTarget: '',
	  smilesTarget: '',
          tsvFile: this.state.tsvFile,
	  results: results
      };
	this.props.updateSearchOption(options);
//	this.props.history.push('/route');
	//		  dispatch(searchSucceeded(data));

	
	this.props.history.push('/strategies');
    }
    
  readFile(file) {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      let ext = file[0].name.substr(-4);
      switch(ext) {
      case '.tsv':
      case '.TSV':
	  reader.onload = () => {
	      var tsvFile = reader.result;
	      console.log("tsvFile", tsvFile.length);
	      //batch input or output?
	      var tsv = tsvFile.split("\n");
	      if (!tsv)
		  return;
	      var hdr = tsv[0].split('\t');
	      console.log("hdr", hdr.length);
	      if (hdr && hdr.length > 40) {
		  // batch output
		  Utils.OnSmarts(tsv, this.onSmartsClick);
		  this.props.updateBatchResults(tsv);
	      } else {
		  // batch input
		  this.setState({
		      tsvFile: file[0],
		      searchStr: file[0].name
		  });
		  this.onSearchClick();
	      }
	  };
	  reader.readAsText(file[0]);
	  break;
      case '.mol':
      case '.MOL':	  
	  reader.onload = () => {
	      let molFile = reader.result;
	      this.setState({
		  showJSME: true,
		  molFile: molFile,
		  tsvFile: '',
		  smilesStr: '',   
		  searchDisabled: true,
		  showDropzone: false
	      });
	  };
	  reader.readAsText(file[0]);
	  break;
      default:
	  console.log("Invalid mol or tsv file", file[0].name);
	  return;
      }
      
  }

  // meta is for react-select
  handleChange(event, meta) {
    handleOptionChange(this, event, meta);
  }

  onSearchInput(event) {
    const target = event.target;
    let searchStr = target.value;
      
      if (this.state.showJSME) {
	  // set searchStr to display, update requestID
	  let requestId = this.state.requestId + 1;
	  this.setState({
              searchStr: searchStr,
              smilesStr: searchStr,	  
              requestId: requestId
	  });
	  let JSMEframe = document.getElementsByName("JSMEFrame");
	  if (!JSMEframe || JSMEframe.length !== 1) {
	      console.log("invalid JSMEFrame", JSMEframe);
	      return;
	  }
	  JSMEframe[0].contentWindow.postMessage(
	      { type: 'smiles', str: searchStr },
	      config.ORIGIN_URL
	  );	  
      } else { 	  
    // check if user input more than three character -allen
    if (searchStr.length >= 3) {
      let requestId = this.state.requestId + 1;
      // set searchStr to display, update requestID
      this.setState({
          searchStr: searchStr,
	  smilesStr: searchStr,
        requestId: requestId
      });
      Utils.AutoComplete(
        this.props.user.tokenGW,
        requestId,
        searchStr,
        this.updateSearchSuggestion
      );
    } else {
      this.setState({
        searchStr: searchStr,
        smilesJSME: '',	  
        showSuggestion: false
      });
    }
      }
  }

  updateSearchSuggestion(requestId, searchStr, result, status) {
    if (status !== 200) {
      console.log('auto complete failed');
      return;
    }
    //check if requestID is same - allen
    if (requestId === this.state.requestId) {
      this.setState({
        suggestionList: result, // put in response
        showSuggestion: true
      });
    }
  }

    onSuggestClick(s) {
    this.setState({
      searchStr: s,
      smilesStr: this.state.smilesStr, //Autocomplete,
      showSuggestion: false
    });
  }

    updateSmilesStr(str) {
    this.setState({
      smilesStr: str,
      searchStr: str,
      showSuggestion: false
    });
   localStorage.setItem('lastSmilesStr', this.state.smilesStr);	
  }

  toggleDropzone() {
    let showDropzone = !this.state.showDropzone;
    this.setState({
      showDropzone: showDropzone,
      showJSME: false,
      showSuggestion: false
    });
  }

  onDrawIconClick() {
      
    let showJSME = !this.state.showJSME;
      let searchStr = !showJSME ?
	  this.state.smilesStr :
	  this.state.searchStr;

    this.setState({
      showJSME: showJSME,
      molFile: '',
      searchStr: searchStr,	
      //searchDisabled: showJSME,
      showDropzone: false,
      showSuggestion: false
    });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSearchClick();
    }
  }

  onSearchClick() {
    let searchStr = this.state.searchStr;

    if (this.state.searchStr === '') {
      // TODO change this to return null or stop the search when empty
      searchStr = config.DEBUG_SEARCH;
    }
   localStorage.setItem('lastSearchStr', this.state.searchStr);	
   localStorage.setItem('lastSmilesStr', this.state.smilesStr);	

    // double check if searchStr is still '' or undefined
    if (searchStr === '' || searchStr === undefined) {
      this.setState({
        isSearching: false,
        errorMsg: 'Please enter target compound'
      });
    } else {
      let options = {
        maxRoute: this.state.maxRoutes,
        maxReactionSteps: this.state.maxReactionSteps,
        literatureDerivedReactions: this.state.literatureDerivedReactions,
        maxReagentCost: this.state.maxReagentCost,
        autoSYNCompatible: this.state.autoSYNCompatible,
        computerGeneratedReactions: this.state.computerGeneratedReactions,
        searchTarget: searchStr,
	smilesTarget: this.state.smilesStr,
        tsvFile: this.state.tsvFile
      };

      let url = Utils.BuildSearchURL(options);
      if (url == null) {
        alert(
          'Please select either Literature Derived or Computer Generated reactions or both'
        );
        return;
      }

      this.setState({
        isSearching: true,
        showSuggestion: false
      });

      let tokenGW = this.props.user.tokenGW;
      if (!tokenGW)
	  tokenGW = config.TOKEN_GW;
	
      this.props.onSearch(
        url,
        options,
        this.props.history,
        tokenGW
      );
    }
  }

  render() {
    const { user } = this.props;

    // accepted file type for dropzone
    const accept = ['.mol','.tsv'];
    let savedRoutes = user.savedRoutes;
    // searchOptions, results,
    // when clicked do strategyId 0, routeId 0

    // grab only the first six from savedRoutes
    let savedRouteCanvas = savedRoutes.slice(0, 6).map((routes, i) => {
      return (
        <div
          key={'saved-routes-canvas-' + i}
          className="recent-search-canvas-container flex-center-column"
        >
          <div className="recent-search-canvas-timestamp">
            {routes.timestamp}
          </div>
          <div className="recent-search-canvas =" key={'route-' + i}>
            <Canvas
              id={i}
              results={routes}
              type="recentCanvas"
              onCanvasClick={this.onSavedRouteClick}
            />
          </div>
        </div>
      );
    });

    // Empty saved route additions
    let proxySavedRouteCanvas,
      savedRoutesCount = savedRoutes.length;
    if (savedRoutesCount < 6) {
      let currentSavedRoutesCount = savedRoutesCount,
        proxyRoutes = [];
      while (currentSavedRoutesCount < 6) {
        proxyRoutes.push(1);
        currentSavedRoutesCount++;
      }
      // Add in the proxy empty saved routes
      proxySavedRouteCanvas = proxyRoutes.map((proxy, index) => {
        return (
          <div
            key={'proxy-canvas-' + index}
            className="recent-search-canvas-container flex-center-column proxy"
          >
            <div className="recent-search-canvas">
              <img src={SavedRouteProxy} alt="" />
              <p>{STRINGS.savedRoutesCTA}</p>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="search-page">
        <div
          className="search-page-whole"
          onClick={e => this.handleNonSearchClick(e)}
        />
        {this.state.isSearching && (
          <div className="search-notification-container">
            <img src={Loading} alt={STRINGS.searching} />
            <h3 className="search-notification">{STRINGS.searching}</h3>
          </div>
        )}
        <div className="search-field">
          <div className="spacing">
            {this.state.errorMsg !== '' && (
              <div className="search-error-msg">{this.state.errorMsg}</div>
            )}
            <Search
              disabled={this.state.searchDisabled}
              name="searchStr"
              placeHolder={STRINGS.searchPlaceholder}
              value={this.state.searchStr}
              showSuggestion={this.state.showSuggestion}
              suggestionList={this.state.suggestionList}
              onChange={this.onSearchInput}
              onSearchClick={this.onSearchClick}
              onUploadClick={this.toggleDropzone}
              onSuggestClick={this.onSuggestClick}
              onDrawIconClick={this.onDrawIconClick}
              onKeyPress={this.onKeyPress}
	      onAutoCompleteHover={this.onAutoCompleteHover}
            />
          </div>
        </div>
        {this.state.showDropzone && (
 	   <div className="search-dropzone-container search-content-size ">
            <div className="spacing">
              <Dropzone
                accept={accept}
                onDrop={acceptedFiles => this.readFile(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: 'dropzone '
                      })}
		     >
                      <input {...getInputProps()} />
                      <p>{STRINGS.molInstructions}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        )}
        {this.state.showJSME && (
          <div className="search-field-jsme search-content-size">
            <div className="spacing">
              <JSMEWindow
                updateSmilesStr={this.updateSmilesStr}
                molFile={this.state.molFile}
	        smilesStr={this.state.smilesStr}
              />
            </div>
          </div>
        )}
        <div className="search-options-container">
          <div className="search-content-size">
            <div className="spacing">
              <h3>{STRINGS.searchOptionsHeader}</h3>
              {this.state.showOptionsError && (
                <div className="search-options-error">
                  {this.state.optionsErrorMsg}
                </div>
              )}
              <div className="search-options-row">
                <SearchOption label={STRINGS.maxRoutes} className="column">
                  <Select
                    name="maxRoutes"
                    onChange={this.handleChange}
                    value={this.state.maxRoutes}
                    options={MAX_ROUTE_OPTIONS}
                  />
                </SearchOption>
                <SearchOption
                  label={STRINGS.maxReactionSteps}
                  className="column"
                >
                  <Select
                    name="maxReactionSteps"
                    onChange={this.handleChange}
                    value={this.state.maxReactionSteps}
                    options={MAX_REACTION_OPTIONS}
                  />
                </SearchOption>
                <SearchOption
                  label={STRINGS.literatureDerivedReactions}
                  className="column"
                >
                  <Checkbox
                    name="literatureDerivedReactions"
                    value={this.state.literatureDerivedReactions}
                    onChange={this.handleChange}
                  />
                </SearchOption>
              </div>
              <div className="search-options-row">
                <SearchOption label={STRINGS.maxReagantCost} className="column">
                  <Select
                    name="maxReagentCost"
                    onChange={this.handleChange}
                    value={this.state.maxReagentCost}
                    options={MAX_REAGENT_OPTIONS}
                  />
                </SearchOption>
                <SearchOption
                  label={STRINGS.autoSynCompatible}
                  className="column"
                >
                  <Checkbox
                    name="autoSYNCompatible"
                    value={this.state.autoSYNCompatible}
                    onChange={this.handleChange}
                  />
                </SearchOption>
                <SearchOption
                  label={STRINGS.computerGeneratedReactions}
                  className="column"
                >
                  <Checkbox
                    name="computerGeneratedReactions"
                    value={this.state.computerGeneratedReactions}
                    onChange={this.handleChange}
                  />
                </SearchOption>
              </div>
            </div>
          </div>
        </div>
        <div className="recent-search-content">
          <div className="search-content-size">
            <div className="spacing">
              <h3>{STRINGS.savedRoutesHeader}</h3>
              <div className="search-saved-routes">
                {savedRouteCanvas}
                {proxySavedRouteCanvas}
              </div>
            </div>
          </div>
            </div>

	    <div className="popup" id="SMARTS-popup">
	      <div className="buttons">
	        <button onClick={Utils.CloseSMARTS}>CLOSE </button>
	        <button onClick={Utils.CopySMARTS}>COPY </button>
  	        <button onClick={Utils.SaveSMARTS}>SAVE </button>
    	      </div>
	      <div id="SMARTS-div">
     	        <table id="SMARTS-table"></table>
	      </div>
            </div>
	
      </div>
    );
  }
}

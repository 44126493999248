import { Utils } from '../utils/utils';
import { SRV } from '../utils/RouteVisualization';

import {
  REQUEST_STRATEGIES,
  SEARCH_INITIATED,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED
} from '../constants/actionTypes';
import { updateChosenSearchOption } from '../actions';
//import { push } from 'connected-react-router';

// TODO check if this is used, if not remove -allen
export const requestStrategies = () => ({
  type: REQUEST_STRATEGIES
});

export const searchInitiated = () => ({
  type: SEARCH_INITIATED
});

export const searchSucceeded = data => ({
  type: SEARCH_SUCCEEDED,
  data
});

export const searchFailed = msg => ({
  type: SEARCH_FAILED,
  msg
});

export function fetchStrategies(url, options, history, authToken) {
  return function(dispatch) {
      // returns initial state
      dispatch(searchInitiated());

      function searchCallback(data, status) {
	  if (status === 200) {
              let errors = SRV.GetSearchErrors(data);
              if (errors) {
		  dispatch( searchFailed(errors) );
              } else {
		  dispatch(updateChosenSearchOption(options));
		  dispatch(searchSucceeded(data));
		  history.push('/strategies');
              }
	  } else {
              console.log('Error: ', data, status);
              dispatch(searchFailed('Error: Connection to SynRoute server failed.'));
	  }
      }

      if (options.tsvFile) {
	  Utils.PostFile(url, authToken, "tsvfile", options.tsvFile, searchCallback);
      } else {
	  Utils.LoadJSON(url, authToken, searchCallback);
      }
  };
}

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '../TextareaAutoResize';
import styled from 'styled-components';
import Button from '../Button';
import Canvas from '../Canvas';
import { Utils } from '../../utils/utils';

const StyledRecentSavedRoute = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  background-color: white;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
  }
`;

const StyledButtonSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
`;

const StyledInput = styled.input`
  width: ${props => props.width};
  height: ${props => props.height};
  font-family: ${props => props.theme.primaryFont};
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  margin-left: 10px;
  padding-left: 5px;
  text-align: ${props => props.align};
  border: ${props => `1px solid ${props.theme.edgeColor}`};

  :disabled {
    background: #dddddd;
  }
`;

const StyledRouteRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const StyledRouteName = styled.div`
  width: 480px;
  display: flex;
  align-items: center;
`;

const StyledInputLabel = styled.div`
  text-align: left;
  //align-self: start;
  font-weight: 700;
`;

const StyledTimeStamp = styled.div`
  margin-left: 5px;
`;

const StyledErrorMsg = styled.div`
  align-self: flex-end;
  color: ${props => props.color};
`;

const SavedRoutePanel = ({
  route,
  user,
  onSavedRouteClick,
  handleRemoveRouteClick,
  updateSavedRoutes
}) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSave, setSaveMode] = useState(false);
  const [routeName, setRouteName] = useState(route.routeName);
  const [routeDescription, setRouteDescription] = useState(
    route.routeDescription
  );

  const handleInput = e => {
    if (e.target.name === 'routeName') {
      setRouteName(e.target.value);
    } else if (e.target.name === 'routeDescription') {
      setRouteDescription(e.target.value);
    }
  };

  const handleSave = () => {
    let updatedSavedRouteDetail = {
      routeName: routeName,
      routeDescription: routeDescription
    };

    Utils.UpdateSavedRoute(
      route,
      updatedSavedRouteDetail,
      user,
      onSaveCallback
    );
  };

  const toggleEdit = () => {
    setSaveMode(!isSave);
  };

  const onSaveCallback = (user, status) => {
    if (status === 200) {
      // success
      setErrorMsg('');
      updateSavedRoutes(user.savedRoutes);
      toggleEdit();
    } else {
      setErrorMsg('Error, route edit failed');
    }
  };

  return (
    <StyledRecentSavedRoute>
      <Fragment>
        <StyledErrorMsg color="red">{errorMsg}</StyledErrorMsg>
        <StyledRouteRow>
          <StyledRouteName>
            <StyledInputLabel>Route Name</StyledInputLabel>
            <StyledInput
              type="text"
              name="routeName"
              placeholder="Route Name"
              value={routeName}
              width="150px"
              height="28px"
              align="left"
              disabled={!isSave}
              onChange={e => handleInput(e)}
            />
            <StyledTimeStamp>{route.timestamp}</StyledTimeStamp>
          </StyledRouteName>
          <StyledButtonSpace>
            <Button
              text={!isSave ? 'Edit' : 'Save'}
              onClick={!isSave ? toggleEdit : handleSave}
            />
            <Button
              buttonColor="flagButtonHoverColor"
              text="Remove"
              onClick={() =>
                handleRemoveRouteClick(
                  route.id,
                  'Are you sure you want to remove saved route <i>' +
                    routeName +
                    '</i>?'
                )
              }
            />
          </StyledButtonSpace>
        </StyledRouteRow>
        <TextareaAutosize
          type="text"
          name="routeDescription"
          placeholder="Route Description"
          value={routeDescription}
          disabled={!isSave}
          width="100%"
          height="60px"
          align="left"
          handleInput={e => handleInput(e)}
        />
      </Fragment>
      <div style={{ width: '100%' }}>
        <Canvas
          id={route.id}
          results={route}
          type="savedCanvas"
          onCanvasClick={onSavedRouteClick}
        />
      </div>
    </StyledRecentSavedRoute>
  );
};

SavedRoutePanel.defaultProps = {
  subject: 'Flagged'
};

SavedRoutePanel.propTypes = {
  subject: PropTypes.string
};

export default SavedRoutePanel;

const prod = {
  GATEWAY_SERVER: 'gateway',
  ORIGIN_URL: '*',
  emailFlag: 'syfi-synroute-flag@sri.com',
  TOKEN_GW:  "amltOnN5bnJvdXRl",
  OktaEnable: false,    
};

const dev = {
  GATEWAY_SERVER: '',
  ORIGIN_URL: 'http://localhost:3000',
  emailFlag: 'syfi-synroute-flag@sri.com',
  DEBUG_SEARCH: 'RXC-23915229',
  DEBUG_USER: 'jim',
  DEBUG_EMAIL: 'james.herson@sri.com',
  DEBUG_PASSWD: 'synroute',
  DEBUG_AUTOLOGIN: true,
  TOKEN_GW:  "amltOnN5bnJvdXRl",
  OktaEnable: false,    
};

export const config = (process.env.NODE_ENV === 'development') ? dev : prod;


/**
 * A component which has iframe to display JSME.html
 * Handles communication between the react and iframe
 */

import React from 'react';
import styled from 'styled-components';
//import InputBox from '../../components/InputBox';
import { config } from '../../config';

const StyledJSME = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.primaryBgColor};

  iframe {
    height: 400px;
    width: 100%;
    border: 0;
  }
`;

class JSMEWindow extends React.Component {
  constructor(props) {
      super(props);
      
      console.log('new jsme');

    this.msgHandler = this.msgHandler.bind(this);
    this.state = {
	smilesStr: props.smilesStr,
        molFile: props.molFile
    };
  }

    componentDidMount() {
	window.addEventListener('message', this.msgHandler, false);
    }

    componentWillMount() {
    }
    componentWillUnmount() {
	window.removeEventListener('message', this.msgHandler, false);
    }

  //msg recieve from iframe
    msgHandler(e) {

    // TODO enable this later when ORIGIN_URL is not *
    /*
    if (e.origin !== config.ORIGIN_URL) {
      return;
    }
    */
	switch(e.data.type) {
	case 'load':

	    if (this.state.molFile && this.refs.iframe) {
		this.refs.iframe.contentWindow.postMessage(
		    { type: 'readmolfile', molfile: this.state.molFile},
		    config.ORIGIN_URL
		);
	    } else if (this.state.smilesStr && this.refs.iframe) {
		    this.refs.iframe.contentWindow.postMessage(
			{ type: 'smiles', str: this.state.smilesStr },
			config.ORIGIN_URL
		    );
	    }
	    
	    break;
	    
	case 'smiles':
	    this.props.updateSmilesStr(e.data.smilesStr);
	    this.setState({
		smilesJSME: e.data.smilesStr
	    });
	    let inputs = document.getElementsByName('searchStr');
	    if (inputs && inputs.length === 1) {
		inputs[0].value = e.data.smilesStr;
	    }
	    break;
	case 'molfile':
	    console.log('got mole file\n', e.data.molfile.length);
	    this.setState({
		molfile: e.data.molfile
	    });
	    break;
	default:
	    console.log('invalid msg from jsme', e);
	}
	/*
	    if (mol || this.props.JSMEReadMol && this.props.molContent !== '') {
		this.refs.iframe.contentWindow.postMessage(
		    { type: 'mols', str: mol }, //this.props.molContent },
		    config.ORIGIN_URL
		);
		}
	*/
	    
  }

    render() {
    return (
      <StyledJSME>
        <iframe src="/JSME.html" ref="iframe" title="JSMEFrame" name="JSMEFrame"></iframe>
      </StyledJSME>
    );
  }
}
export default JSMEWindow;

export const MAX_ROUTE_OPTIONS = [
  { label: '1', value: 1 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 }
];

export const MAX_REAGENT_OPTIONS = [
  { label: '$0', value: 0 },
  { label: '$10', value: 10 },
  { label: '$100', value: 100 },
  { label: '$1000', value: 1000 },
  { label: '$10000', value: 10000 }
];

export const MAX_REACTION_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 }
];

// user
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const REQUEST_STRATEGIES = 'REQUEST_STRATEGIES';
export const SEARCH_INITIATED = 'SEARCH_INITIATED';
export const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
export const SEARCH_FAILED = 'SEARCH_FAILED';

// Chosen actions
export const UPDATE_CHOSEN_STRATEGY = 'UPDATE_CHOSEN_STRATEGY';
export const UPDATE_CHOSEN_ROUTE_ID = 'UPDATE_CHOSEN_ROUTE_ID';
export const UPDATE_CHOSEN_SEARCH_OPTION = 'UPDATE_CHOSEN_SEARCH_OPTION';

// User actions
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const ADD_NEW_SAVED_ROUTE = 'ADD_NEW_SAVED_ROUTE';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_SAVED_ROUTE = 'REMOVE_SAVED_ROUTE';
export const UPDATE_SAVED_ROUTE = 'UPDATE_SAVED_ROUTE';
export const UPDATE_FLAGGED = 'UPDATE_FLAGGED';
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS';
export const UPDATE_BATCH_RESULTS = 'UPDATE_BATCH_RESULTS';

/**
 * Panel of /strategies page
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Canvas from '../Canvas';

const StyledStrategies = styled.div`
  background-color: ${props => props.theme.primaryBgColor};
  padding: 0px 15px;
  margin-bottom: 10px;
`;

const StyledStrategiesInfo = styled.div`
  position: relative;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  div:nth-child(2) {
    display: flex;
    div {
      margin-right: 0px;
    }
  }
`;

const StyledStrategiesCenter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  h3 {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
    margin: 0;
  }
  p {
    white-space: pre-wrap;
    padding: 5px 5px;
    margin: 0;
  }
  p>span {
    color: red;  
  }
`;

class StrategiesPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onHideClick = this.onHideClick.bind(this);

    this.state = {
      hide: false
    };
  }

  onHideClick() {
    let b = !this.state.hide;
    this.setState({
      hide: b
    });
  }

  render() {
    const {
      onMoreClick,
      title,
      description,
      strategyId,
      onFlagClick,
      routeCount,
      results
    } = this.props;

    return (
      <StyledStrategies>
        <StyledStrategiesInfo>
          <StyledStrategiesCenter>
            <h3>{title}</h3>
            <p>
	      {description[0]}
	      <span>{description[1]}</span>
	    </p>
          </StyledStrategiesCenter>
          <div>
            <Button
              text={
                'View ' +
                routeCount +
                ' ' +
                (routeCount > 1 ? 'routes' : 'route')
              }
              onClick={onMoreClick}
              isPrimary={true}
              height="35px"  /*fix this -- should be scss. JAH */
            />
            <Button
	       type="flag"
               height="35px"
    	       onClick={onFlagClick}
	    />
            <Button
              width="60px"
              height="35px"
              text={this.state.hide ? 'Show' : 'Hide'}
              onClick={this.onHideClick}
            />
          </div>
        </StyledStrategiesInfo>
        {!this.state.hide && (
          <>
            <StyledStrategiesCenter>
              <Canvas strategyId={strategyId} results={results} type="strategyCanvas" />
            </StyledStrategiesCenter>
          </>
        )}
      </StyledStrategies>
    );
  }
}

StrategiesPanel.propTypes = {
  onMoreClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.array,
  strategyId: PropTypes.number,
  routeIds: PropTypes.array,
  routes: PropTypes.object
};

export default StrategiesPanel;

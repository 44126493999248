/**
 * DetailsPanel is used on RoutePage to show details of the routes
 * in the sidebar.
 *
 */

import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  font-size: ${props => props.theme.smallBodyFontSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  border-bottom: ${props => `1px solid ${props.theme.secondaryColor}`};
`;

const StyledInputLabel = styled.label`
  min-width: ${props => props.width};
  font-weight: 700;
  padding: 10px 0;
`;

const StyledInputDescContainer = styled.div`
  text-align: right;
  width: calc(300px - ${props => props.width});
  display: flex;
  justify-content: flex-end;
`;

const StyledInputDesc = styled.div`
  width: calc(300px - ${props => props.width});
  padding: 5px 0;
  text-align: ${props => (props.isOverflow ? 'left' : 'right')};
  overflow-wrap: break-word;
  white-space: pre-line;
`;

const DetailsPanel = props => (
  <InputContainer>
    <StyledInputLabel width={props.width}>{props.label}</StyledInputLabel>
    <StyledInputDescContainer width={props.width}>
      <StyledInputDesc
        width={props.width}
        isOverflow={
          props.desc !== undefined && props.desc.length > props.maxCount
            ? false
            : false
        }
      >
        {props.desc}
      </StyledInputDesc>
    </StyledInputDescContainer>
  </InputContainer>
);

DetailsPanel.defaultProps = {
  maxCount: 0,
  label: '',
  width: 'auto',
  desc: ''
};

DetailsPanel.propTypes = {
  maxCount: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const StageInputContainer = styled.div`
  font-size: ${props => props.theme.smallBodyFontSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${props => `1px solid ${props.theme.secondaryColor}`};
`;

const StagePanel = props => (
  <StageInputContainer width="300px">
    <StyledInputLabel width={props.width}>{props.label}</StyledInputLabel>
    <StyledInputDescContainer width={props.width}>
      <StyledInputDesc
        width={props.width}
        isOverflow={false}
      >
	{Array.isArray(props.desc) ?
	 props.desc.map((desc, i) => {
	     if (desc.length > 38)
		 desc = desc.substring(0, 38) + "...";
	     if (i < props.desc.length-1) {
		 return (
			 <Fragment key={'desc'+i} >
			   <span>{desc}</span><br/>
			 </Fragment>
		 )
	     } else {
		 return (
			 <Fragment key={'desc'+i} >
			   <span>{desc}</span><br/>
			 </Fragment>
		 )
	     }
	 })
	 : props.desc}	     
      </StyledInputDesc>
    </StyledInputDescContainer>
  </StageInputContainer>
);

StagePanel.defaultProps = {
  maxCount: 0,
  label: '',
  width: 'auto',
  desc: ''
};

StagePanel.propTypes = {
  maxCount: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
};

const ReferencesPanel = props => (
  <StageInputContainer width="300px">
    <StyledInputLabel width={props.width}>{props.label}</StyledInputLabel>
    <StyledInputDescContainer width={props.width}>
      <StyledInputDesc
        width={props.width}
        isOverflow={false}
	>
	{props.refs.length ?
	 props.refs.map((ref, i) => {
	    let href = ref.href;
	    if (href.length > 38)
		href = href.substring(0, 38) + "...";
	    
	    if (i < props.refs.length-1) {
		return (
		    <Fragment key={'ref'+i} >
			<a href={ref.url} target="_blank">{href}</a><br/>
		    </Fragment>
	    )
	    } else {
	    return (
		    <Fragment key={'ref'+i} >
		      <a href={ref.url} target="_blank">{href}</a>
		    </Fragment>
		    
	    )
	    }
	 })
	 : <span>N/A</span>
	}
     </StyledInputDesc>
    </StyledInputDescContainer>
  </StageInputContainer>
);

export {DetailsPanel, StagePanel, ReferencesPanel, StyledInputDesc};

/**
 * DetailsPanel is used on RoutePage to show details of the routes
 * in the sidebar. this has hide show
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import {Utils} from '../../utils/utils';

const StyledInputDesc2 = styled.div`
  width: calc(300px - ${(props) => props.width});
  padding: 0px 30px;
  text-align: right;
  overflow-wrap: break-word;
  white-space: pre-line;
`;

const StyledContainer = styled.div`
  font-size: ${(props) => props.theme.smallBodyFontSize};
  width: 300px;
  border-bottom: ${(props) => `1px solid ${props.theme.secondaryColor}`};
`;
const InputContainer = styled.div`
  font-size: ${(props) => props.theme.smallBodyFontSize};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
`;

const StyledInputLabel2 = styled.label`
  min-width: ${(props) => props.width};
  font-weight: 700;
  padding: 0px 10px;
`;

const StyledInputLabel = styled.label`
  min-width: ${(props) => props.width};
  font-weight: 700;
  padding: 10px 0;
`;

const StyledInputDescContainer = styled.div`
  text-align: right;
  width: calc(300px - ${(props) => props.width});
  display: flex;
  justify-content: flex-end;
`;

/* unused warning
const StyledInputDesc = styled.div`
  width: calc(300px - ${props => props.width});
  padding: 5px 0;
  text-align: ${props => (props.isOverflow ? 'left' : 'right')};
  overflow-wrap: break-word;
  white-space: pre-line;
`;
*/

const StyledHiddenContent = styled.div`
  font-size: ${(props) => props.theme.smallBodyFontSize};
  width: 300px;
`;

const StyledHiddenInputDesc = styled.div`
  width: calc(100% - 30px);
  overflow-wrap: break-word;
  white-space: pre-line;
  text-align: ${(props) => props.align};
  text-indent: -30px;
  margin-left: 30px;
`;

const DetailsShowHidePanel = (props) => {
  const [isShow, toggleShow] = useState(false);

    function ToggleShow(isShow) {

	if (props.label == "Procedure") {
	    Utils.ShowProcedure(props.title, props.desc);
	} else {
	    toggleShow(isShow);
	}
    }
    
  return (
    <StyledContainer>
      <InputContainer>
        <StyledInputLabel width={props.width}>{props.label}</StyledInputLabel>
        <StyledInputDescContainer width={props.width}>
          <Button
            width="32px"
            text={isShow ? '[HIDE]' : '[SHOW]'}
            onClick={() => ToggleShow(!isShow)}
          />
        </StyledInputDescContainer>
      </InputContainer>
      <StyledHiddenContent>
        {isShow && (
          <StyledHiddenInputDesc width={props.width} align={'left'}>
            {props.desc}
          </StyledHiddenInputDesc>
        )}
      </StyledHiddenContent>
    </StyledContainer>
  );
};

DetailsShowHidePanel.defaultProps = {
  maxCount: 0,
  label: '',
  width: 'auto',
  desc: ''
};

DetailsShowHidePanel.propTypes = {
  maxCount: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const DetailsShowHideCostPanel = (props) => {
  const [isShow, toggleShow] = useState(false);
    const buttonWidth = (props.desc === 'n/a') ? '10px' : '110px';
    const buttonText = props.desc +
	  ((props.desc === 'n/a') ? '' : (isShow ? '    [HIDE]' : '    [SHOW]'));

  return (
    <StyledContainer>
      <InputContainer>
        <StyledInputLabel width={props.width}>{props.label}</StyledInputLabel>
        <StyledInputDescContainer width={props.width}>
          <Button
            width={buttonWidth}
            text={buttonText}
            onClick={() => toggleShow(!isShow)}
          />
        </StyledInputDescContainer>
      </InputContainer>
      <StyledHiddenContent>
        {isShow && (
          <StyledHiddenInputDesc>
		{props.bom.map((line, i) => {
		    return (
			    <InputContainer
			key={'line'+i} >
			    <StyledInputLabel2 width={props.width}>
			    {line.label}
			</StyledInputLabel2>
			    <StyledInputDesc2>
			    {line.desc}
			</StyledInputDesc2>
			    </InputContainer>
		    )})}
          </StyledHiddenInputDesc>
        )}
      </StyledHiddenContent>
    </StyledContainer>
  );
};

export { DetailsShowHidePanel, DetailsShowHideCostPanel };

export const STRINGS = {
  productName: 'SynRoute™',
  summary:
    'SynRoute™ is a search engine that helps chemists discover synthesis routes for target molecules using literature based reactions and reactions predicted by artificial intelligence.',
  companyName: 'SRI International',
  companyUrl: 'https://www.sri.com/',
  copyright: '©2022 SRI International',
  printButton: 'PRINT',
  printButtonHint: 'Print this page',
  flagButton: 'FLAG',
  flagButtonHint: 'Flag this route',
  saveButton: 'SAVE',
  saveButtonHint: 'Save this route',
  shareButton: 'SHARE',
  shareButtonHint: 'Share this via email',
  closeButton: 'CLOSE',
  closeButtonHint: 'Close this view',
  orderButton: 'ORDER',
  orderButtonHint: 'Order feedstocks for this route',
  routeDetails: 'Route Details',
  reactionDetails: 'Reaction Details',
  privacyPolicy: 'Privacy policy',
  privacyPolicyUrl: 'https://www.sri.com/privacy-policy/',
  cookies: 'Cookies',
  cookiesUrl: 'https://www.sri.com/privacy-policy/#cookie-usage',
  navSearch: 'New search',
  navProfile: 'Profile',
  navLogout: 'Logout',
  searchPlaceholder: 'Enter target compound',
  savedRoutesHeader: 'Recent Saved Routes',
  searchOptionsHeader: 'Search Options',
  molInstructions: 'Drag and drop MOL file here, or click to select a file',
 //  searchHints: 'Supports: Common name, SMILES, InChI™, InChI™ Key',
  searchHints: 'Supports: Common name, SMILES, InChI™',    
  maxRoutes: 'Max Routes',
  maxReagantCost: 'Max Reagent Cost (/mmol)',
  maxReactionSteps: 'Max Reaction Steps',
  autoSynCompatible: 'AutoSyn™ Compatible',
  literatureDerivedReactions: 'Literature Derived Reactions',
  computerGeneratedReactions: 'Computer Generated Reactions',
  targetCompound: 'Target Compound',
  searching: 'Searching...',
  savedRoutes: 'Saved Routes',
  flaggedCompounds: 'Flagged Compounds',
  flaggedReactions: 'Flagged Reactions',
  compoundDetails: 'Compound Details',
  flagEmailHeader: 'Provide feedback to the curator',
  shareEmailHeader: 'Share',
  saveRouteHeader: 'Save Route',
  saveRouteDescription:
    'Save this route to an existing project or new project.',
  userDataName: 'Full name',
  userDataNameError: 'Name missing',
  userDataEmail: 'Email address',
  userDataEmailError: 'Email address missing',
  userDataTelephone: 'Telephone number',
  userDataTelephoneError: 'Telephone number missing',
  userDataTitle: 'Job title',
  userDataTitleError: 'Job title missing',
  userDataTeam: 'Team name',
  userDataTeamError: 'Team name missing',
  removeHeader: 'Are you sure you want to remove?',
  localStorageToken: 'synRouteAuthToken',
  synfiniOktaStorageToken: 'synfiniOktaToken',
  smilesPasteHeader: 'Enter SMILES string to draw',
  savedRoutesCTA: 'No saved route'
};

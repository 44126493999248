import {
  UPDATE_CHOSEN_STRATEGY,
  UPDATE_CHOSEN_ROUTE_ID,
  UPDATE_CHOSEN_SEARCH_OPTION
} from '../constants/actionTypes';

export const updateChosenStrategy = (results, strategyId) => ({
  type: UPDATE_CHOSEN_STRATEGY,
  results,
  strategyId
});

export const updateChosenRouteId = id => ({
  type: UPDATE_CHOSEN_ROUTE_ID,
  id
});

export const updateChosenSearchOption = options => ({
  type: UPDATE_CHOSEN_SEARCH_OPTION,
  options
});

export function savedRouteClicked(results, strategyId, routeId) {
  return function(dispatch) {
    dispatch(updateChosenStrategy(results, strategyId));
    dispatch(updateChosenRouteId(routeId));
  };
}

export function updateSearchOption(options) {
  return function(dispatch) {
    dispatch(updateChosenSearchOption(options));
  };
}

/**
 * Styled save panel
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import Select from '../Select';
import InputBox from '../InputBox';
import Textarea from '../Textarea';
import { handleOptionChange } from '../../utils/handleSearchOptions';
import { Utils } from '../../utils/utils';
import PopupBox from '../PopupBox';
import { STRINGS } from '../../constants/strings';

const StyledSaveRouteBack = styled.div`
  width: 100%;
  height: ${(props) => 'calc(100vh - ' + props.theme.headerSize + ')'};
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center;
`;

const StyledSaveRoutePanel = styled.div`
  margin-top: 50px;
  min-width: 500px;
  width: 800px;
  height: ${(props) => props.height};
  background-color: ${(props) => props.theme.primaryBgColor};
  position: relative;
  padding: 20px;
  h2 {
    text-align: left;
    width: 100%;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: ${(props) => props.theme.largeBodyFontSize};
  }
  p {
    padding: 0;
    margin: 0;
  }
`;

const StyledSaveRouteTop = styled.div``;

const StyledErrorMsg = styled.div`
  color: ${(props) => props.color};
  min-height: 22px;
`;

const StyledPopup = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

class SaveRoutePanel extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
    this.formatProjectToDropdown = this.formatProjectToDropdown.bind(this);
    this.onSaveCallback = this.onSaveCallback.bind(this);
    this.onCreateProjectCallback = this.onCreateProjectCallback.bind(this);
    this.handleCloseCheck = this.handleCloseCheck.bind(this);
    this.toggleCloseConfirmation = this.toggleCloseConfirmation.bind(this);

    this.state = {
      name: '',
      project: undefined,
      projectName: '',
      projectDescription: '',
      chosenProject: {},
      inCreateNew: false,
      projectDropdown: [],
      newProjectMsg: '',
      savedRouteMsg: '',
      savedRouteNameMsg: '',
      inConfirmation: false,
      isError: false,
      inSavedRoute: true,
      showCloseConfirmation: false
    };
  }

  componentDidMount() {
    if (this.props.projects.length >= 0) {
      this.formatProjectToDropdown();
    }
  }

  componentDidUpdate(prevProps) {
    // compare projects to see if user added new one
    if (prevProps.projects.length !== this.props.projects.length) {
      console.log('project is updated.');
      // need to format the projects into readable {label: <>, value: <>}
      this.formatProjectToDropdown();
    }
  }

  sortProjects(projects) {
    projects.sort(function (a, b) {
      return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
    });
  }

  // format props.projects into react-select readable and update the local state
  formatProjectToDropdown() {
    let projects = Utils.SortByKey(this.props.projects, 'timestamp', 'down');
    let projectDropdown = projects.map((data, i) => {
      return {
        label: data.name,
        value: i // this should be id
      };
    });
      if (this.props.user.tokenOkta) {
	  this.setState({
	      projectDropdown: [
		      ...projectDropdown
	      ]
	  });
      } else {
	  this.setState({
	      projectDropdown: [
		  {
		      label: 'Create new project',
		      value: 'createNew',
		      fontStyle: 'italic'
		  },
		      ...projectDropdown
	      ]
	  });
      }
  }

  // meta is for react-select
  handleChange(event, meta) {
    if (event.value === 'createNew') {
      this.onCreateClick();
      this.setState({
        project: ''
      });
    } else {
      handleOptionChange(this, event, meta);
    }
  }

  onCreateClick() {
    let inCreateNew = !this.state.inCreateNew;
    this.setState({
      inCreateNew: inCreateNew,
      inSavedRoute: !inCreateNew
    });
  }

  onSaveClick() {
    if (this.state.project === undefined) {
      this.setState({
        savedRouteMsg: 'Please select a project from dropdown.',
        savedRouteNameMsg: '',
        isError: true
      });
    } else if (this.state.name === '') {
      this.setState({
        savedRouteMsg: '',
        savedRouteNameMsg: 'Please enter name.',
        isError: true
      });
    } else {
      const { routeId, strategyId, results, options, user } = this.props;

      this.setState({
        savedRouteMsg: 'Saving route....',
        isError: false
      });

      let routeObj = {
        project: this.state.project,
        routeName: this.state.name,
        routeDescription: this.state.description
      };

      Utils.SaveRoute(
        results,
        routeId,
        strategyId,
        options,
        routeObj,
        user,
        this.onSaveCallback
      );
    }
  }

    onSaveCallback(user, status, errmsg) {

    // check if this is duplicate
    if (status === -1) {
      this.setState({
        savedRouteMsg: 'duplicate saved route',
        isError: true
      });
    } else if (status === 200) {
      // success
      console.log('updated', user);
      // update reducer
      this.props.updateSavedRoutes(user.savedRoutes);
      // TODO below should open popup
      //this.props.onCloseClick();

      //clear
      this.setState({
        savedRouteMsg: '',
        savedRouteNameMsg: '',
        isError: false,
        inCreateNew: false,
        inConfirmation: true,
        inSavedRoute: false
      });
    } else {
	let msg = 'Error in saving';
	if (errmsg) 
	    msg += ": " + errmsg;
      this.setState({
          savedRouteMsg: msg,
          isError: true
      });
    }
  }

  onCreateProjectCallback(user, status, errmsg) {

    if (status === 200) {
      // TODO do error checking for blank fields
      // update reducer for local
      this.props.onCreateNewProjectClick(
        this.state.projectName,
        this.state.projectDescription,
        user
      );

      // set project
      this.setState({
        inCreateNew: false,
        inSavedRoute: true,
        isError: false,
        newProjectMsg: '',
        savedRouteMsg: '',
        project: {
          label: this.state.projectName,
          value: this.props.projects.length // NOTE this might change to id
        }
      });
    } else {
      this.setState({
        newProjectMsg: errmsg,
        isError: true
      });
    }
  }

  onCreateSubmit() {
    this.setState({
      newProjectMsg: 'Creating new project...',
      isError: false
    });

    // call utils to create new project for cache/server
    Utils.CreateProject(
      this.state.projectName,
      this.state.projectDescription,
      this.props.user,
      this.onCreateProjectCallback
    );
  }

  onProjectChosen() {
    console.log(this.state);
  }

  handleCloseCheck() {
    if (this.state.description !== '') {
      this.toggleCloseConfirmation();
    } else {
      this.props.onCloseClick();
    }
  }

  toggleCloseConfirmation() {
    let showCloseConfirmation = !this.state.showCloseConfirmation;
    this.setState({
      showCloseConfirmation: showCloseConfirmation
    });
  }

  render() {
    const { onCloseClick } = this.props;
    return (
      <StyledSaveRouteBack>
        {this.state.inConfirmation && (
          <PopupBox
            onYesClick={onCloseClick}
            mainText="Route successfully saved"
            yesText="Close"
          />
        )}
        {this.state.showCloseConfirmation && (
          <StyledPopup>
            <PopupBox
              onYesClick={onCloseClick}
              onNoClick={this.toggleCloseConfirmation}
              mainText="Are you sure you want to close?"
              yesText="Yes"
              noText="No"
            />
          </StyledPopup>
        )}
        {this.state.inSavedRoute && (
          <StyledSaveRoutePanel height="490px">
            <StyledSaveRouteTop>
              <h2>{STRINGS.saveRouteHeader}</h2>
              <p>{STRINGS.saveRouteDescription}</p>
            </StyledSaveRouteTop>
            <StyledErrorMsg color={this.state.isError ? 'red' : 'black'}>
              {this.state.savedRouteMsg}
            </StyledErrorMsg>
            <Select
              width="100%"
              name="project"
              placeholder="Project Name"
              onChange={this.handleChange}
              value={this.state.project}
              options={this.state.projectDropdown}
            />
            <StyledErrorMsg color={this.state.isError ? 'red' : 'black'}>
              {this.state.savedRouteNameMsg}
            </StyledErrorMsg>
            <InputBox
              type="text"
              name="name"
              placeholder="Route Name"
              width="100%"
              height="48px"
              align="left"
              onChange={this.handleChange}
            />
            <br />
            <Textarea
              type="text"
              name="description"
              placeholder="Route Description"
              width="100%"
              height="200px"
              align="left"
              onChange={this.handleChange}
            />
            <div className="modal-controls">
              <Button
                text="Cancel"
                className="left"
                onClick={this.handleCloseCheck}
              />
              <Button
                text="Save Route"
                isPrimary={true}
                onClick={this.onSaveClick}
              />
            </div>
          </StyledSaveRoutePanel>
        )}
        {this.state.inCreateNew && (
          <StyledSaveRoutePanel height="435px">
            <StyledSaveRouteTop>
              <h3 style={{ marginBottom: '5px' }}>Create a New Project</h3>
            </StyledSaveRouteTop>
            <StyledErrorMsg color={this.state.isError ? 'red' : 'black'}>
              {this.state.newProjectMsg}
            </StyledErrorMsg>
            <InputBox
              type="text"
              name="projectName"
              placeholder="Project Name"
              width="100%"
              height="48px"
              align="left"
              onChange={this.handleChange}
            />
            <br />
            <Textarea
              type="text"
              name="projectDescription"
              placeholder="Project Description"
              width="100%"
              height="200px"
              align="left"
              onChange={this.handleChange}
            />
            <div className="modal-controls">
              <Button
                text="Cancel"
                className="left"
                onClick={this.onCreateClick}
              />
              <Button
                isPrimary={true}
                text="Create"
                onClick={this.onCreateSubmit}
              />
            </div>
          </StyledSaveRoutePanel>
        )}
      </StyledSaveRouteBack>
    );
  }
}

SaveRoutePanel.propTypes = {
  onCreateNewClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onCreateNewProjectClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  inCreateNew: PropTypes.bool,
  projectList: PropTypes.object
};

export default SaveRoutePanel;

import { connect } from 'react-redux';
import UserPage from './UserPage';
import {
  savedRouteClicked,
  updateSearchOption,
  removeSavedRoute,
  removeProject,
  updateSavedRoutes,
  updateFlagged,
  updateProjects,
  updateUserProfile
} from '../../actions';

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => {
  return {
    savedRouteClicked: (results, strategyId, routeId) =>
      dispatch(savedRouteClicked(results, strategyId, routeId)),
    updateSearchOption: options => dispatch(updateSearchOption(options)),
    removeProject: index => dispatch(removeProject(index)),
    removeSavedRoute: index => dispatch(removeSavedRoute(index)),
    updateSavedRoutes: savedRoutes => dispatch(updateSavedRoutes(savedRoutes)),
    updateFlagged: flagged => dispatch(updateFlagged(flagged)),
    updateProjects: projects => dispatch(updateProjects(projects)),
    updateUserProfile: profile => dispatch(updateUserProfile(profile))
  };
};

const UserPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPage);

export default UserPageContainer;

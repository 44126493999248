/**
 * Checkbox with custom styling
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${props => props.theme.primaryBgColor};
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: ${props =>
    props.checked ? props.theme.spotColor : props.theme.primaryBgColor};
  border:  ${props =>
    props.checked
      ? `2px ${props.theme.spotColor} solid`
      : `2px ${props.theme.tertiaryColor} solid`}
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
    visibility: visible;
  }
  border: 1px solid ${props => props.theme.edgeColor};
  border-radius: ${props => props.theme.borderRadius};
  :hover {
    background-color: ${props =>
      props.checked ? props.theme.spotColor : props.theme.secondaryBgColor}
  }
`;

const Checkbox = ({ name, value, onChange }) => (
  <label>
    <HiddenCheckbox checked={value} name={name} onChange={onChange} />
    <StyledCheckbox checked={value}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </label>
);

Checkbox.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.bool
};

export default Checkbox;

/* eslint eqeqeq: 0 */
/**
 * Header which is shown everywhere except the login
 * Will handle redirect for unauthorized page navigation
 *
 * Two different styles used:
 * 1) For Search page, larger header design approach, centered to contentSize
 * 2) For interior pages, thinner design, smaller logo, full width
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import ArrowDownImg from '../../assets/images/arrow-down.png';
import ProductNameImg from '../../assets/images/logo-synroute@3x.png';
import DefaultAvatar from '../../assets/images/profile.jpg';
import { Okta } from '../../utils/okta';
import { STRINGS } from '../../constants/strings';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  padding: 0px 10px;
  ${props =>
    props.currentLocation === '/search' &&
    'max-width: ' +
      props.theme.contentSize +
/*      '; height: ' +
      props.theme.headerLargeSize +
*/
      ';'}
  ${props =>
    props.currentLocation !== '/search' &&
    'max-width: auto; height: ' +
      props.theme.headerSize +
      ';'}

  .header-buttons {
    width: 320px;
    font-size: ${props => props.theme.primaryBodyFontSize};
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
      color: ${props => props.theme.primaryColor};
      &:hover {
        color: ${props => props.theme.spotColor};
      }
    }
    .active {
      border-bottom: 2px solid ${props => props.theme.primaryColor};
    }
  }

  h1 {
    display: block;
    position: relative;
    overflow: hidden;
    ${props => props.currentLocation !== '/search' && 'padding: 10px 0 0 0;'}
    ${props =>
      props.currentLocation === '/search' &&
      'padding: 10px 0px;'}
    margin: 0;
    a {
      display: block;
      background-image: url(${props => props.img});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-indent: -9000px;
      padding: 0;
      margin: 0;
      ${props =>
        props.currentLocation !== '/search' && 'width: 200px; height: 51px;'}
      ${props =>
        props.currentLocation === '/search' && 'width: 300px; height: 76px;'}
    }
  }
  @media print {
    padding: 0;
    margin: 0;
    height: auto;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
    h1 {
      overflow: visible;
      display: block;
      padding: 0;
      margin: 0;
      a {
        background-image: none;
        text-indent: 0;
        padding: 0;
        margin: 0;
        color: ${props => props.theme.primaryColor};
        text-decoration: none;
        font-size: ${props => props.theme.largeBodyFontSize};
      }
      width: 100%;
    }
    nav {
      display: none !important;
    }
  }
`;

const StyledAvatar = styled.div`
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 50%;
  background-image: url(${props => (props.img ? props.img : DefaultAvatar)});
  border-radius: 32px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

const StyledLogout = styled.div`
  color: ${props => props.theme.tertiaryColor};
  cursor: pointer;

  :hover {
    color: ${props => props.theme.spotColor};
  }
`;

//https://www.w3schools.com/css/css_dropdowns.asp
const StyledDropdownMenu = styled.div`
  font-family: ${props => props.theme.primaryFont};
  font-size: ${props => props.theme.primaryBodyFontSize};

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    z-index: 3;
    cursor: pointer;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    right: 0;
    position: absolute;
    background-color: #f9f9f9;
    background-color: ${props => props.theme.secondaryBgColor};
    min-width: 120px;
    z-index: 3;
  }

  .dropdown-content * {
    text-align: center;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content *:hover {
    text-decoration: underline;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .header-user-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;

    p.header-username {
      margin-right: 10px;
    }
  }
`;

const StyledBreadcrumbs = styled.div`
  padding: 0px 10px;
  button {
	border: none;
	padding: 5px;
	color: #888;
	cursor: default;
    }
    .active {
	text-decoration:underline;
	color: #000;
	cursor: pointer;
    }
`;

class Header extends Component {
  constructor(props) {
    super(props);

    // For Debug:
    //this.props.user.photo = '/assets/images/profile.jpg';
    //this.props.user.username = 'Bob Smith';
    this.state = {
      isDropdownOpen: false,
      isSearch: false
    };

    this.logout = this.logout.bind(this);
    this.reloadSearchPage = this.reloadSearchPage.bind(this);
    this.OnBreadcrumb = this.OnBreadcrumb.bind(this);
    this.BreadcrumbUpdate = this.BreadcrumbUpdate.bind(this);
  }

    componentDidMount() {
	
      // check if user profile exists
      let tokenGW = this.props.user.tokenGW;
      let tokenOkta = this.props.user.tokenOkta;
      if (tokenGW || tokenOkta) {
          this.props.fetchUser(tokenGW, tokenOkta);
          this.props.history.push('/search');
      }

    this.props.user.breadcrumb = {
	Update: this.BreadcrumbUpdate,
	results: null,
	strategyId: -1,
	routeId: -1,
	btn: {}
    };
  }

  logout() {
    // clear local
   localStorage.removeItem(STRINGS.localStorageToken);
    localStorage.removeItem("okta");

      if (this.props.user.tokenOkta) {
	  Okta.Logout(this.props.user.tokenOkta);
      }
    this.props.onLogoutClick();
    this.props.history.push('/');
  }

  // does a window reload to go back to default
  reloadSearchPage(e) {
    e.preventDefault();
    window.location.reload(false);
  }

    BreadcrumbUpdate(results, strategyId, routeId)
    {
	var breadcrumb = this.props.user.breadcrumb;

	if (!breadcrumb.btn.search) 
	    breadcrumb.btn.search = document.querySelector("#crumbsearch");

	if (!breadcrumb.btn.strategies) 
	    breadcrumb.btn.strategies = document.querySelector("#crumbstrategies");
	if (!breadcrumb.btn.strategy)
	    breadcrumb.btn.strategy = document.querySelector("#crumbstrategy");
	
	if (!breadcrumb.btn.route) 
	    breadcrumb.btn.route = document.querySelector("#crumbroute");

	switch (this.props.location.pathname) {
	case "/search":
	    breadcrumb.results = null;
	    breadcrumb.strategyId = -1;
	    breadcrumb.routeId = -1;

	    breadcrumb.btn.search.innerHTML = "Search";
	    breadcrumb.btn.search.classList.add('hide');
	    breadcrumb.btn.search.nextElementSibling.classList.add('hide');
	    
	    breadcrumb.btn.strategies.innerHTML = "Strategies";
	    breadcrumb.btn.strategies.classList.add('hide');
	    breadcrumb.btn.strategies.nextElementSibling.classList.add('hide');

	    breadcrumb.btn.strategy.innerHTML = "Strategy";
	    breadcrumb.btn.strategy.classList.add('hide');
	    breadcrumb.btn.strategy.nextElementSibling.classList.add('hide');
	    
	    breadcrumb.btn.route.innerHTML = "Route";
	    breadcrumb.btn.route.classList.add('hide');	    

	    breadcrumb.btn.search.classList.remove("active");
	    breadcrumb.btn.strategies.classList.remove("active");
	    breadcrumb.btn.strategy.classList.remove("active");
	    breadcrumb.btn.route.classList.remove("active");
	    return;
	case "/strategies":
	    breadcrumb.btn.search.classList.remove('hide');
	    breadcrumb.btn.search.nextElementSibling.classList.remove('hide');

	    breadcrumb.btn.strategies.classList.remove('hide');
	    breadcrumb.btn.strategies.nextElementSibling.classList.remove('hide');
	    breadcrumb.btn.search.classList.add("active");
	    breadcrumb.btn.strategies.classList.remove("active");
	    breadcrumb.btn.strategy.classList.add("active");
	    breadcrumb.btn.route.classList.add("active");

	    break;
	    
	case "/strategy":
	    breadcrumb.btn.strategy.classList.remove('hide');
	    breadcrumb.btn.strategy.nextElementSibling.classList.remove('hide');
	    
	    breadcrumb.btn.search.classList.add("active");
	    breadcrumb.btn.strategies.classList.add("active");
	    breadcrumb.btn.strategy.classList.remove("active");
	    breadcrumb.btn.route.classList.add("active");
	    break;
	case "/route":	    
	    breadcrumb.btn.route.classList.remove('hide');
	    
	    breadcrumb.btn.search.classList.add("active");
	    breadcrumb.btn.strategies.classList.add("active");
	    breadcrumb.btn.strategy.classList.add("active");
	    breadcrumb.btn.route.classList.remove("active");

	    break;
	default:
	    console.log("Invalid path", this.props.location.pathname);
	    return;
	}
	
	if (results != null && results != breadcrumb.results) {
	    breadcrumb.results = results;
	    breadcrumb.strategyId = -1;
	    breadcrumb.routeId = -1;
	    breadcrumb.btn.strategy.classList.remove("active");	    
	    breadcrumb.btn.route.classList.remove("active");	    
	}	
	if (strategyId != -1 && strategyId != breadcrumb.strategyId) {
	    breadcrumb.strategyId = strategyId;
	    breadcrumb.routeId = -1;
	    breadcrumb.btn.route.classList.remove("active");	    
	}
	
	if (routeId != -1 && routeId != breadcrumb.routeId)
	    breadcrumb.routeId = routeId;

	if (breadcrumb.strategyId == -1)
	    breadcrumb.btn.strategy.innerHTML = "Strategy";
	else
	    breadcrumb.btn.strategy.innerHTML = "Strategy " + (breadcrumb.strategyId+1);	    
 
	if (breadcrumb.routeId == -1)
	    breadcrumb.btn.route.innerHTML = "Route";
	else
	    breadcrumb.btn.route.innerHTML = "Route " + (breadcrumb.routeId+1);	    

    }
    
    OnBreadcrumb(event)
    {
	var btn = event.target;

	if (!btn.classList.contains("active"))
	    return;

	switch(btn.id) {
	case 'crumbsearch':
	    this.props.history.push('/search');
	    break;
	case 'crumbstrategies':
	    this.props.history.push('/strategies');
	    break;
	case 'crumbstrategy':
	    this.props.history.push('/strategy');
	    break;
	case 'crumbroute':
	    this.props.history.push('/route');
	    break;
	default:
	    console.log("invalid page", this.props.location.pathname);
	}
    }
    
  render() {
    let atSearchPage = false;
    if (this.props.location.pathname === '/') {
      return null;
    }

    if (this.props.location.pathname === '/search') {
      atSearchPage = true;
    }

      //let logoutEnabled = localStorage.getItem("okta") ? false : true;
      let tokenOkta = this.props.user.tokenOkta;      
      
      return (
	  <div>
      <StyledHeader
        img={ProductNameImg}
        currentLocation={this.props.location.pathname}
      >
        <h1>
          <NavLink to="/search">{STRINGS.productName}</NavLink>
        </h1>
        <nav className="header-buttons">
          <NavLink
            onClick={atSearchPage ? e => this.reloadSearchPage(e) : undefined}
            className="router-link"
            to="/search"
            activeClassName="active"
            style={{ zIndex: 3 }}
          >
            {STRINGS.navSearch}
          </NavLink>
          <StyledDropdownMenu>
            <div className="dropdown">
              <div className="header-user-button">
                <StyledAvatar
                  img={this.props.user.photo}
                  alt={this.props.user.username}
                />
                <p className="header-username">{this.props.user.username}</p>
                <img width="10" alt="arrow-down" src={ArrowDownImg} />
              </div>
              <div className="dropdown-content">
                <NavLink className="router-link" to="/user">
                  {STRINGS.navProfile}
                </NavLink>

	      {!tokenOkta && (
                <StyledLogout onClick={this.logout}>
                  {STRINGS.navLogout}
                  </StyledLogout>
	      )}
              </div>
            </div>
          </StyledDropdownMenu>
        </nav>
      </StyledHeader>

      <StyledBreadcrumbs className="BreadCrumbs">
         <button className="crumb" id="crumbsearch" onClick={this.OnBreadcrumb}>Search</button><span> &gt;</span> 	       
        <button className="crumb" id="crumbstrategies" onClick={this.OnBreadcrumb}>Strategies</button><span> &gt;</span> 	      
         <button className="crumb" id="crumbstrategy" onClick={this.OnBreadcrumb}>Strategy</button><span> &gt;</span>
         <button className="crumb" id="crumbroute" onClick={this.OnBreadcrumb}>Route </button>
      </StyledBreadcrumbs>
      </div>
      );
  }
}

Header.propTypes = {
  user: PropTypes.object
};

export default withRouter(Header);
